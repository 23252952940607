import { IPouch } from '../../../../common/interfaces/pouch';
import { IUniverse } from '../../../../common/interfaces/universe';
import { All, PouchActionTypes } from '../actions/pouch.action';

export interface State {
  pouch: IPouch | null;
  // universes: IUniverse[] | null;
  pouches: IPouch[] | null;
  errorMessage: string | null;
}

export const initialState: State = {
  pouch: null,
  // universes : null,
  pouches: null,
  errorMessage: null
};

export function reducer(state = initialState, action: All): State {
  let payload: any = null;
  if (action.payload) {
    payload = action.payload;
  }

  switch (action.type) {
    case PouchActionTypes.ADDEDIT_SUCCESS:
      return {
        ...state,
        pouches: payload ? payload : payload.pouches,
        // universes : payload[1] ? payload[1] : payload.universes,
        pouch: payload.pouch ? payload.pouch : null
      };
    case PouchActionTypes.CREATE_ADDEDIT_SUCCESS:
      return {
        ...state,
        pouch: payload.pouch ? payload.pouch : null
      };
    case PouchActionTypes.ADDEDIT_FAILURE: {
      return {
        ...state,
        errorMessage: 'Error.'
      };
    }
    case PouchActionTypes.UPDATEEDIT_SUCCESS: {
      return {
        ...state,
        pouches: payload.pouches
      };
    }
    default: {
      return state;
    }
  }
}
