import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-digi-locker-info-success-popup',
  templateUrl: './digi-locker-info-success-popup.component.html',
  styleUrls: ['./digi-locker-info-success-popup.component.scss']
})
export class DigiLockerInfoSuccessPopupComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DigiLockerInfoSuccessPopupComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
