import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  stocks: string;
  suggested: string;
  pouch: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    stocks: 'Adani Ports and Special Economic Zone Pvt ltd',
    suggested: '18%',
    pouch: '18%'
  },
  {
    stocks: 'Sun Pharmaceutical Industries Ltd',
    suggested: '13%',
    pouch: '13%'
  },
  {
    stocks: 'Oil and Natural gas Corporation Ltd',
    suggested: '11%',
    pouch: '11%'
  },
  {
    stocks: 'Housing Development Finance Co',
    suggested: '11%',
    pouch: '10% overridden'
  },
  {
    stocks: 'Power Grid Corporation of India',
    suggested: '09%',
    pouch: '10% overridden'
  },
  { stocks: 'Grasim Industries Ltd', suggested: '09%', pouch: '09%' }
];

@Component({
  selector: 'app-how-created',
  templateUrl: './how-created.component.html',
  styleUrls: ['./how-created.component.scss']
})
export class HowCreatedComponent {
  displayedColumns: string[] = ['stocks', 'suggested', 'pouch'];
  dataSource = ELEMENT_DATA;
}

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
