<div class="agreement-wrapper mt-3">
  <div class="container-lg white-bg3 public-bg-wrapper mt-85 mb-60">
    <div class="container">
      <div class="c10 doc-content">
        <p class="c14"><span class="c11">Terms of Use</span></p>
        <p class="c0">
          <span class="c1"
            >We, Lamron Analysts Pvt Ltd., with our registered office at, 109/40B, Hazra Road,
            Kolkata &ndash; 700 026, India (hereinafter shall be referred to as &quot;</span
          ><span class="c1 c13">Lamron</span><span class="c1">&quot; or &quot;</span
          ><span class="c1 c13">Us</span><span class="c1">&quot; or &quot;</span
          ><span class="c1 c13">We</span><span class="c1">&quot; or &ldquo;</span
          ><span class="c1 c13">Our</span
          ><span class="c1 c2"
            >&rdquo;) are the owner and operator of the Smartvalues Platform
            (smartvalues.co.in).</span
          >
        </p>
        <p class="c0">
          <span class="c2 c1"
            >THIS POLICY SUMMARIES OUR TERMS AND CONDITIONS FOR AVAILING OUR SERVICES, THE REFUND
            POLICY, THE TYPE OF INFORMATION COLLECTED BY US AS PROVIDED BY YOU AS A USER
            (&ldquo;YOU/R&rdquo;) WHILE USING THE PLATFORM, THE POLICY REGARDING THE USE OF THAT
            INFORMATION AND MEASURES TAKEN TO SAFEGUARD THAT INFORMATION.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c1"
            >USER SHALL MEAN ANY PERSON/ PERSONS, WHO VISITS, USES, DEALS WITH AND/ OR TRANSACTS
            THROUGH THE WEBSITE / APP OR AVAILS OUR SERVICES.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c1"
            >BY VISITNG/ ACCESSING THE PLATFORM, THROUGH THE WEBSITE OR THE APP AND VOLUNTARILY
            PROVIDING US WITH INFORMATION (PERSONAL AND / OR NON- PERSONAL), YOU ARE CONSENTING TO
            OUR USE OF IT IN ACCORDANCE WITH THIS POLICY.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c1"
            >THIS POLICY DOES NOT APPLY TO THIRD-PARTY WEBSITES / APPLICATIONS THAT ARE CONNECTED
            VIA LINKS TO THE PLATFORM. IF YOU DO NOT AGREE WITH THIS POLICY, PLEASE DO NOT PROCEED
            FURTHER TO USE / ACCESS THIS PLATFORM.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c1"
            >THIS POLICY IS A PART OF THE PLATFORM&nbsp;AND ALL CAPITALIZED TERMS UNDER THIS POLICY
            THAT HAVE NOT BEEN SPECIFICALLY DEFINED HEREIN SHALL HAVE THE MEANING AS ASCRIBED TO IT
            UNDER THE PLATFORM TERMS &amp; CONDITIONS.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c1"
            >WE MAY UPDATE THIS POLICY FROM TIME TO TIME. WHILE WE WILL ALWAYS ENDEVOUR TO NOTIFY
            YOU, YOU MUST PERIODICALLY REVIEW THE POLICY FOR THE LATEST INFORMATION ON OUR PRIVACY
            PRACTICES.</span
          >
        </p>
        <ol class="c3 lst-kix_list_13-0 start" start="1">
          <li class="c0 c7 li-bullet-0">
            <span class="c5">Terms for Use of our Platform and/or Services</span>
          </li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >As a user of our Platform and / or Services, you shall be subject to below mentioned
            restrictions, and Lamron reserves the right to take any action against you as may be
            deemed necessary in the event of any breach or failure by you to adhere to any such
            restrictions:</span
          >
        </p>
        <ul class="c3 lst-kix_list_14-0 start">
          <li class="c4 li-bullet-1">
            <span class="c2 c1"
              >You are not allowed to attempt to reverse-engineer, disseminate, copy, or exploit the
              Platform and the Services, or any part thereof;</span
            >
          </li>
          <li class="c4 li-bullet-1">
            <span class="c2 c1"
              >You will not represent yourself as an agent or reseller of the Platform and the
              Services, or any part thereof;</span
            >
          </li>
          <li class="c4 li-bullet-2">
            <span class="c2 c1"
              >You will not impersonate any other person or misrepresent yourself, or otherwise
              cause harm to any person, or host, display, upload, modify, publish, transmit, store,
              update or share any information that</span
            >
          </li>
        </ul>
        <ul class="c3 lst-kix_list_14-1 start">
          <li class="c9 li-bullet-1">
            <span class="c2 c1">belongs to another person,</span>
          </li>
          <li class="c9 li-bullet-2">
            <span class="c2 c1">infringes any intellectual property rights,</span>
          </li>
          <li class="c9 li-bullet-2">
            <span class="c2 c1"
              >knowingly and intentionally communicates false and misleading information but may
              reasonably be perceived as a fact,
            </span>
          </li>
          <li class="c9 li-bullet-1">
            <span class="c2 c1"
              >affects or threatens India&rsquo;s unity, integrity, defence, security, sovereignty,
              or hurts its friendly relations with foreign States or is insulting other nation,
              or,</span
            >
          </li>
        </ul>
        <p class="c8 c16"><span class="c2 c1"></span></p>
        <ul class="c3 lst-kix_list_14-0">
          <li class="c4 li-bullet-2">
            <span class="c2 c1"
              >Further You are not supposed to upload or transmit any vulgar, obscene, abusive,
              defamatory, or unlawful content, including content which is harmful to children,
              objectionable or results in a breach of privacy of any other person;</span
            >
          </li>
          <li class="c4 li-bullet-2">
            <span class="c2 c1"
              >You also understand and agree that you cannot use or abuse the Platform or Services
              for or in relation to any illegal or unlawful purposes including fraud, embezzlement,
              money laundering, etc.;</span
            >
          </li>
          <li class="c4 li-bullet-1">
            <span class="c2 c1"
              >You cannot carry out or facilitate any actions which result in a detrimental effect
              on the Platform or the Services or any related server / network resource, including
              DDoS attacks, or data security breaches;</span
            >
          </li>
          <li class="c4 li-bullet-1">
            <span class="c2 c1"
              >You agree that Lamron will be the sole and exclusive owner of the intellectual
              property and any proprietary information made available or accessible through the
              Platform or the Services;</span
            >
          </li>
          <li class="c4 li-bullet-1">
            <span class="c2 c1"
              >You will not intentionally or unintentionally violate any applicable laws;</span
            >
          </li>
          <li class="c4 li-bullet-2">
            <span class="c2 c1"
              >You represent and warrant that you possess the legal right and ability to enter into
              these terms and to use the Platform and the Services in accordance with these
              terms.</span
            >
          </li>
          <li class="c4 li-bullet-2">
            <span class="c2 c1"
              >You represent and warrant that you possess the legal right and ability to enter into
              these terms and to use the Platform and the Services in accordance with these
              terms.</span
            >
          </li>
          <li class="c4 li-bullet-2">
            <span class="c2 c1"
              >You hereby consent to the non-exclusive jurisdiction of the courts of Kolkata to
              resolve any dispute arising out of these terms.</span
            >
          </li>
        </ul>
        <p class="c6"><span class="c2 c1"></span></p>
        <p class="c8 c12"><span class="c5"></span></p>
        <ol class="c3 lst-kix_list_13-0" start="2">
          <li class="c0 c7 li-bullet-0">
            <span class="c5">Subscription Cancellation and Refund Policy </span>
          </li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >You can select any pouch and see various plans available for the selected pouch such as
            Monthly, Quarterly and / or Half Yearly. You can pay using any of the enabled payment
            methods for the same.</span
          >
        </p>
        <p class="c0">
          <span class="c2 c1"
            >Please note that any payments made once by you will not be refunded back to your
            account.</span
          >
        </p>
        <ol class="c3 lst-kix_list_13-0" start="3">
          <li class="c0 c7 li-bullet-0">
            <span class="c5">User Consent and Agreement</span>
          </li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >By registering and submitting your Personal Information through our Platform or
            Services, you agree to the terms of this Policy as well as our Privacy Policy (also
            available on our website / Platform) and you expressly give your voluntary consent to
            the collection, use and disclosure of the Personal Information in accordance with these
            Policies.</span
          >
        </p>
        <ol class="c3 lst-kix_list_13-0" start="4">
          <li class="c0 c7 li-bullet-0">
            <span class="c5">Your Personal Information and its Usage</span>
          </li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >For details on the personal information collected by us and its usage, please refer to
            our Privacy Policy (also available on our website / Platform). By agreeing to the terms
            of this Policy also implies that you have provided your consent to our Privacy
            Policy.</span
          >
        </p>
        <ol class="c3 lst-kix_list_13-0" start="5">
          <li class="c0 c7 li-bullet-0"><span class="c5">Cookies</span></li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >Cookies are small pieces of text sent to your browser by a website you visit. They help
            that website remember information about your visit, which can both make it easier to
            visit the site again and make the site more useful to you. We use cookies to store your
            preferences which in turn will make your browsing experience better on our Platform. In
            case you turn off the option to have cookies stored on your browser, it will affect your
            experience on our Platform.</span
          >
        </p>
        <ol class="c3 lst-kix_list_13-0" start="6">
          <li class="c0 c7 li-bullet-0">
            <span class="c5">Retention of your Data / Information</span>
          </li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >We will retain your data as may be provided to us till such time as required by law and
            required to efficiently provide service to you.</span
          >
        </p>
        <ol class="c3 lst-kix_list_13-0" start="7">
          <li class="c0 c7 li-bullet-0">
            <span class="c5">Security of your information</span>
          </li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >We store and process Your personal information on secure AWS cloud servers and other
            cloud service providers within India. Some of the safeguards We use are firewalls and
            encryption using SSL, and information access authorization controls. We use reasonable
            safeguards to preserve the integrity and security of Your information against loss,
            theft, unauthorized access, disclosure, reproduction, use or amendment. To achieve the
            same, We use reasonable security practices and procedures as mandated under applicable
            laws for the protection of Your information. Information You provide to Us may be stored
            on Our secure servers located within India.&nbsp;However, You understand and accept that
            there&rsquo;s no guarantee that data transmission over the Internet will be completely
            secure and that any information that You transmit to Us is at Your own risk. We assume
            no liability for any disclosure of information due to errors in transmission,
            unauthorized third party access to our Platform and data bases or other acts of third
            parties, or acts or omissions beyond Our reasonable control and You shall not be
            entitled to hold Lamron responsible for any breach of security.</span
          >
        </p>
        <ol class="c3 lst-kix_list_13-0" start="8">
          <li class="c0 c7 li-bullet-0">
            <span class="c5">Contact Information</span>
          </li>
        </ol>
        <p class="c0">
          <span class="c2 c1"
            >Lamron is committed to provide your best of the service and welcomes your grievances,
            comments or questions regarding the term of this Policy as well as our Privacy Policy,
            our Platform, or our Services. Please e-mail us at compliance&#64;lamronanalysts.com or
            contact our grievance officer at the following address:</span
          >
        </p>
        <p class="c0"><span class="c2 c1">Lamron Analysts Pvt Ltd., </span></p>
        <p class="c0"><span class="c2 c1">109/40B, Hazra Road, </span></p>
        <p class="c0"><span class="c2 c1">Kolkata &ndash; 700 026</span></p>
        <p class="c0">
          <span class="c2 c1">Contact No. &ndash; 81007 23140</span>
        </p>
        <p class="c0 c8"><span class="c2 c1"></span></p>
        <p class="c0 c8"><span class="c2 c1"></span></p>
        <p class="c0">
          <span class="c2 c1">LAST UPDATED ON 15th February 2023</span>
        </p>
        <p class="c8 c17"><span class="c2 c15"></span></p>
      </div>
    </div>
  </div>
</div>
