import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  constructor(private http: HttpClient) {}
  public isPublic: boolean = true;
  public userSubscribed: boolean = false;

  mapSubscriberData: any = {
    returnToComposition: false,
    data: null,
    agreementDetails: null
  };
  public stock_upload_date: any;
  public pouchList: any;
  public pouchCompostion: any;

  public fromCreatingPouch: boolean = false;
  private _CONTACT_US = 'smartvalues/func/insert_contact_us';
  fetchPublicBenchMarkReturns(body: any = {}) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/route/get_public_pouch_benchmark_returns/`,
      body
    );
  }
  fetchPouchComparison(data: any) {
    return this.http.post(
      environment.baseUrl +
        `store/smartvalues/myquery/execute/compare_public_pouch_universe_performance`,
      data
    );
  }
  fetchUniverseList(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_universe_list`,
      body
    );
  }

  fetchUploadDate() {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_upload_date`,
      {}
    );
  }
  fetchPublicPouches(date: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/route/fetch_public_pouches/`,
      date
    );
  }

  fetchIndexAnnualizedReturns(date: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/indexannualizedreturns`,
      date
    );
  }
  verifyPromoCodes(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/verify_promo_code`,
      body
    );
  }
  verifyPromoCodessvf(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/verify_promo_code_svf`,
      body
    );
  }
  editPouchSubscriber(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/edit_pouch_subscriber`,
      body
    );
  }
  intiatePaymentGateway(body: any) {
    /*   return this.http.post(
      environment.baseUrl_8083 + `smartvalues/route/initiatetransaction/`,
      body
    ); */
    return this.http.post(environment.baseUrl_8083 + `smartvalues/func/initiatepaymenttxn`, body);
  }
  intiatePaymentGatewaysvf(body: any) {
    /*   return this.http.post(
      environment.baseUrl_8083 + `smartvalues/route/initiatetransaction/`,
      body
    ); */
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/initiatepaymenttxnsvf/`,
      body
    );
  }
  checkTransactionStatus(payload: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/check_payment_status`,
      payload
    );
  }
  getPouchListByReturnOrRiskProfile(payload: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_pouchlist`,
      payload
    );
  }
  getMyPouchList(payload: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_my_pouchlist`,
      payload
    );
  }
  getSubscriptionList() {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_create_pouch_subscriptions`,
      {}
    );
  }
  saveSubscriptionPouch(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/save_createpouch_subscription/`,
      payload
    );
  }
  agreementFrom: any = '';
  agreementDetailsNew: any;
  saveAgreement(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/save_sub_investment/`,
      payload
    );
  }
  intiateesign(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/esigninitiate', payload);
  }
  check_agreement_status(id: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/func/check_agreement_status',
      {
        aid: id
      }
    );
  }
  postViewAgreements(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/view_agreements`,
      body
    );
  }
  postStockRun(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/check_stock_runs`,
      body
    );
  }
  intiatePaymentGatewaysvfTopUp(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/initiatetopuppaymenttxn`,
      body
    );
  }
  getReferrerLink(data: any) {
    return this.http.post(environment.baseUrl_8083 + `smartvalues/func/get_referrer_link/`, data);
  }
  get_referrer_subdomain(data: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_referrer_subdomain`,
      data
    );
  }
  contactUs(data: any) {
    return this.http.post(`${environment.baseUrl_8083}${this._CONTACT_US}`, data);
  }
  pouchSubcriberTradeStatue(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_pouch_subscriber_trade_status`,
      body
    );
  }
  getOtherTransaction(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_subscriber_other_stocks`,
      body
    );
  }
  OtherTransactionbuy(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/fetch_valid_stock_price_other`,
      body
    );
  }
  OtherTransactionSell(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/fetch_valid_stock_price_other_sell`,
      body
    );
  }
  portfollioPerformaceUniverse(body: any) {
    return this.http.post(
      environment.baseUrl +
        `store/smartvalues/myquery/execute/compare_portfolio_subscriber_universe_performance`,
      body
    );
  }
  portfoliosubscriberstockreturns(body: any) {
    return this.http.post(
      environment.baseUrl +
        `store/smartvalues/myquery/execute/get_portfolio_subscriber_stocks_returns`,
      body
    );
  }
  portfoliosubscribersreturns(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_portfolio_subscriber_returns`,
      body
    );
  }
  getWealthySsoUrl() {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/sso_url_wealthy?state=web`,
      {}
    );
  }
  getBajajSsoUrl() {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/sso_url_bajaj?state=web`,
      {}
    );
  }
}
