import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-timeframe-dialog',
  templateUrl: './timeframe-dialog.component.html',
  styleUrls: ['./timeframe-dialog.component.scss']
})
export class TimeFrameDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TimeFrameDialogComponent>) {}

  ngOnInit(): void {}
}
