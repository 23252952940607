import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';

import * as _moment from 'moment';

import { PublicService } from '../../services/public.service';
import { PublicFacadeService } from '../../state/public.facade.services';

@Component({
  selector: 'app-bajajfinservsecurities-landing-page',
  templateUrl: './bajajfinservsecurities-landing-page.component.html',
  styleUrls: ['./bajajfinservsecurities-landing-page.component.scss']
})
export class BajajfinservsecuritiesLandingPageComponent implements OnInit {
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: { timing: 1500, initialDelay: 1500 },
    loop: true,
    touch: true,
    velocity: 0.9
  };
  link = 'landing';
  isLeftClick = false;
  isRightClick = false;
  uploadDate: any;
  screenWidth: any;
  isMobile: boolean = false;
  isPouch: boolean = false;
  publicPouchList: any;
  annualizedReturns: any;
  benchMarkValues: any;
  constructor(
    private router: Router,
    private publicService: PublicService,
    private publicFacadeService: PublicFacadeService
  ) {
    this.onWindowResize();
    if (this.router.url === '/public/terms-and-conditions') {
      this.link = 'terms';
    } else if (this.router.url === '/public/privacy-policy') {
      this.link = 'policy';
    } else {
      this.link = 'landing';
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }
  fetchBenchmarkIndexes() {
    this.publicService
      .fetchPublicBenchMarkReturns({
        referrer_id: 'bajajfinservsecurities',
        current_date: _moment(localStorage.getItem('stock_price_uploaded_date')).format(
          'YYYY-MM-DD'
        )
      })
      .subscribe({
        next: (response: any) => {
          this.benchMarkValues = response.Results;
          
        }
      });
  }
  ngOnInit(): void {
    //call referer api
    if (this.link === 'landing') {
      localStorage.clear();
    }
    // this.publicFacadeService.setUploadDateToStore();
    // this.publicFacadeService.getUploadDateState$.subscribe((date: any) => {
    //   localStorage.setItem('stock_price_uploaded_date', date);
    //   this.uploadDate = localStorage
    //     .getItem('stock_price_uploaded_date')
    //     ?.split('T')[0];
    //   if (date) {
    //     this.loadPouchDetails();
    //     this.fetchBenchmarkIndexes();
    //   }

    // });
    // 
    this.publicFacadeService.setUploadDateToStore();
    this.publicFacadeService.getUploadDateState$.subscribe((date: any) => {
      localStorage.setItem('stock_price_uploaded_date', date);
      this.uploadDate = localStorage.getItem('stock_price_uploaded_date')?.split('T')[0];
      if (date) {
        this.loadPouchDetails();
        this.fetchBenchmarkIndexes();
      }
    });
  }
  ngDoCheck(): void {
    if (this.screenWidth <= 769) {
      this.isMobile = true;
      this.isPouch = true;
    } else {
      this.isMobile = false;
    }
  }

  loadPouchDetails() {
    // this.publicFacadeService.getAnnualizedfromStore({
    //   current_date: this.uploadDate,
    // });
    this.publicFacadeService.getPouchListfromStore({
      referrer_id: 'bajajfinservsecurities',
      current_date: _moment(localStorage.getItem('stock_price_uploaded_date')).format('YYYY-MM-DD')
    });

    this.publicFacadeService.LoadPouchListfromState$.subscribe((list: any) => {
      this.publicPouchList = list ? [...list] : [];

      
    });
    this.publicFacadeService.LoadAnnualizedfromState$.subscribe((returns: any) => {
      this.annualizedReturns = returns;
    });
  }

  getAnnualizedValue(element: any) {
    let returns = this.benchMarkValues?.find((ele: any) => {
      return ele.pouch_id == element.id;
    });
    return (Number(returns?.index_returns || 0) * 100).toFixed(2);
  }
  viewPouchListAll() {
    this.router.navigateByUrl('/public/pouchlist');
    // this.getReferrerLink()
  }

  slide() {
    this.isLeftClick = true;
  }

  slide1() {
    this.isLeftClick = false;
  }
  navigateToPerformance(id: any) {
    // this.router.navigateByUrl('/public/pouchlist');
    this.getReferrerLink();
  }
  clickOnRegister() {
    this.getReferrerLink();
  }
  transformTwoDigit(val: any) {
    return (Number(val) * 100).toFixed(2);
  }

  getRound(value: any) {
    return Math.round(value) || 0;
  }
  isNegative(value: any) {
    if (value < 0) {
      return true;
    } else {
      return false;
    }
  }
  convertPositive(val: any) {
    return Math.abs(val);
  }

  getReferrerLink() {
    this.openSSO();
    // let data = {
    //   "sv_link": "https://smartvalues.co.in/register?referrer_id=MGFmN2I1ZDAtMDdmMy00OWNiLThmNjQtMGUzMjQ4MTJlMzcw"
    // }
    // let redirectedLink =data.sv_link;
    // window.location.href = redirectedLink;
    // this.publicService.getReferrerLink({"referrer_id":"bajajfinservsecurities"}).subscribe((res:any)=>{
    //   if(res){
    //     let redirectedLink =res.sv_link;
    //     window.location.href = redirectedLink;
    //   }
    // })
    // this.router.navigateByUrl('/auth/register')
  }
  onLogin() {
    // this.router.navigateByUrl('/login')
    this.openSSO();
  }
  openSSO() {
    this.publicService.getBajajSsoUrl().subscribe(({ url }: any) => {
      window.location.href = url;
    });
  }
}
