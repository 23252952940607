import { Action } from '@ngrx/store';

export enum PouchActionTypes {
  LOAD = '[Pouch] Load',
  EMPTYSTATELOAD = '[Pouch] EmptyStateLoad',
  ADDEDIT = '[Pouch] AddEdit',
  CREATE_ADDEDIT_SUCCESS = '[Pouch] Create AddEdit Success',
  ADDEDIT_SUCCESS = '[Pouch] AddEdit Success',
  ADDEDIT_FAILURE = '[Pouch] AddEdit Failure',
  UPDATEEDIT_SUCCESS = '[Pouch] Collection UpdateEdit Success'
}
export class Load implements Action {
  readonly type = PouchActionTypes.LOAD;
  constructor(public payload: any) {}
}

export class EmptyStateLoad implements Action {
  readonly type = PouchActionTypes.EMPTYSTATELOAD;
  constructor(public payload: any) {}
}

export class AddEdit implements Action {
  readonly type = PouchActionTypes.ADDEDIT;
  constructor(public payload: any) {}
}

export class CreateAddEditSuccess implements Action {
  readonly type = PouchActionTypes.CREATE_ADDEDIT_SUCCESS;
  constructor(public payload: any) {}
}

export class AddEditSuccess implements Action {
  readonly type = PouchActionTypes.ADDEDIT_SUCCESS;
  constructor(public payload: any) {}
}

export class AddEditFailure implements Action {
  readonly type = PouchActionTypes.ADDEDIT_FAILURE;
  constructor(public payload: any) {}
}
export class UpdateEditSuccess implements Action {
  readonly type = PouchActionTypes.UPDATEEDIT_SUCCESS;
  constructor(public payload: any) {}
}

export type All =
  | AddEdit
  | AddEditSuccess
  | CreateAddEditSuccess
  | AddEditFailure
  | UpdateEditSuccess;
