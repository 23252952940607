import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent {
  title: string;
  button:string;
  constructor(
    public dialogRef: MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
   switch ( data.type){
     case 'liquidate':
       this.title='';
       this.button='Save'

       break;
     case 'unsubscribe':
       this.title='';
       this.button='Unsubscribe'
       break;
     default:
       this.title='Delete';
       this.button= 'Yes'
   }
  }
  apiLoading=false

  onNoClick(): void {
    this.dialogRef.close({ type: 'cancel' });
  }
  onYesClick(): void {
   if(!this.apiLoading){
     this.apiLoading=true;
     this.dialogRef.close({ type: 'yes' });
   }
  }
}
