import { IUser } from '../../../../common/interfaces/user';
import { All, AuthActionTypes } from '../actions/auth.action';

export interface State {
  isAuthenticated: boolean;
  user: IUser | null;
  errorMessage: string | null;
}

export const initialState: State = {
  isAuthenticated: false,
  user: null,
  errorMessage: null
};

export function reducer(state = initialState, action: All): State {
  const payload: any = action.payload;
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      };
    case AuthActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: payload.error
      };
    }
    default: {
      return state;
    }
  }
}
