import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGINWEALTHY = '[Auth] Login Wealthy',
  LOGINBAJAJ = '[Auth] Login Bajaj',
  SET_PASSWORD = '[Auth] Set Password'
}

export class Login implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) {}
}

export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class SetPassword implements Action {
  readonly type = AuthActionTypes.SET_PASSWORD;
  constructor(public payload: any) {}
}
export class LoginWealthy implements Action {
  readonly type = AuthActionTypes.LOGINWEALTHY;
  constructor(public payload: any) {}
}
export class LoginBajaj implements Action {
  readonly type = AuthActionTypes.LOGINBAJAJ;
  constructor(public payload: any) {}
}

export type All = Login | LoginSuccess | LogInFailure | SetPassword | LoginWealthy;
