<div class="sucess-popup fail">
  <div class="text-center mt-20">
    <img style="width: 50px" src="../../../../../assets/images/icons/warning.svg" />
  </div>
  <div class="f-16 fw-600 text-darkgrey text-center mt-20">
    Are you sure you want to delete this transaction?
  </div>
  <div class="row justify-center mt-30">
    <div class="col-7 d-flex">
      <button mat-dialog-close="" type="button" class="theme-btn border-btn mb-20 f-12 w-100">
        No
      </button>
      <button
        (click)="closeDialog('yes')"
        class="theme-btn primary-btn mb-20 f-12 w-100"
        style="margin-left: 10px">
        Yes
      </button>
    </div>
  </div>
</div>
