import { createReducer, on } from '@ngrx/store';

import * as PublicActions from './public.action';

const intialValues = {
  date: '',
  pouchList: [],
  annualizedReturns: [],
  errorFetchingData: 0
};
export const intialPublicState: any = {
  ...intialValues
};

export const publicReducer = createReducer(
  intialPublicState,
  on(PublicActions.setUploadDate, (state: any, action: any) => {
    return {
      ...state
    };
  }),
  on(PublicActions.setUploadDateSuccess, (state: any, action: any) => {
    localStorage.setItem('stock_price_uploaded_date', action.date);
    return {
      ...state,
      date: action.date
    };
  }),
  on(PublicActions.setUploadDateFailure, (state: any, action: any) => {
    return {
      ...state,
      date: '',
      errorFetchingData: action.statusCode
    };
  }),

  // fetch pouch list

  on(PublicActions.loadPouches, (state: any, action: any) => {
    return {
      ...state
    };
  }),
  on(PublicActions.loadPouchesSuccess, (state: any, action: any) => {
    let arrangedPouchLists = action.pouchList?.map((element: any) => {
      return {
        is_public: element['is_public'],
        //   annualizedReturns: this.getAnnualizedValue(element['benchmark_index']),
        id: element['id'],
        name: element['name'],
        min_amt: element['min_amt'],
        benchmark_index: element['benchmark_index'],
        src: '/assets/images/icons/electric_mobility.png',
        heading_text: element['name'],
        description: element['description'],
        total_stocks: element['stock_count']?.toLocaleString('en-IN', {
          useGrouping: true
        }),
        total_subscribers: element['subscriber_count']?.toLocaleString('en-IN', {
          useGrouping: true
        }),
        min_investment: element['minimum_investment']?.toLocaleString('en-IN', {
          useGrouping: true
        }),
        min_investment_number: element['minimum_investment'],
        risk_profile: element['risk_profile'],
        public___pouch_subscriptions: element['public___pouch_subscriptions'],
        cagr: element['cagr'],
        investment_strategy: element['investment_strategy'],
        last_review_date: element['last_review_date'],
        next_review_date: element['next_review_date'],
        return_perc: element['return_perc'],
        review_freq: element['review_freq'],
        review_freq_day: element['review_freq_day'],
        start_date: element['start_date'],
        created_by: element['created_by'],
        stock_count: element['stock_count']?.toLocaleString('en-IN', {
          useGrouping: true
        }),
        subscriber_count: element['subscriber_count']?.toLocaleString('en-IN', {
          useGrouping: true
        }),
        lookback_periods: element['lookback_periods']
      };
    });
    return {
      ...state,
      pouchList: arrangedPouchLists
    };
  }),
  on(PublicActions.loadPouchesFailure, (state: any, action: any) => {
    return {
      ...state,
      pouchList: [],
      errorFetchingData: action.statusCode
    };
  }),

  //fetch annualized returns
  on(PublicActions.loadAnnualizedReturns, (state: any, action: any) => {
    return {
      ...state
    };
  }),
  on(PublicActions.loadAnnualizedReturnsSuccess, (state: any, action: any) => {
    return {
      ...state,
      annualizedReturns: action.annualizedReturns
    };
  }),
  on(PublicActions.loadAnnualizedReturnsFailure, (state: any, action: any) => {
    return {
      ...state,
      annualizedReturns: [],
      errorFetchingData: action.statusCode
    };
  }),
  on(PublicActions.addNewPouchToState, (state: any, action: any) => {
    let data: any = [...state.pouchList, action.data];
    return {
      ...state,
      pouchList: data
    };
  })
);
