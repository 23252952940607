import { IStock } from 'src/app/common/interfaces/stock';

import { IUniverse, ISector } from '../../../../common/interfaces/universe';
import { All, UniverseActionTypes } from '../actions/universe.action';

export interface State {
  universes: IUniverse[] | null;
  errorMessage: string | null;
  sectors: ISector[] | null;
  stocks: IStock[] | null;
  selectedStocks: IStock[] | null;
  searchResults: IStock[] | null;
  selectedUniverse: string | null;
}

export const initialState: State = {
  universes: null,
  errorMessage: null,
  sectors: null,
  stocks: [],
  selectedStocks: [],
  searchResults: [],
  selectedUniverse: null
};

export function reducer(state = initialState, action: All): State {
  let payload: any = null;
  if (action.payload) {
    payload = action.payload;
  }
  switch (action.type) {
    case UniverseActionTypes.ADDEDIT_SUCCESS:
      return {
        ...state,
        universes: payload[0],
        sectors: payload[1]
      };
    case UniverseActionTypes.ADDEDIT_FAILURE: {
      return {
        ...state,
        errorMessage: 'Error.'
      };
    }
    case UniverseActionTypes.LOAD_STOCK_SUCCESS: {
      return {
        ...state,
        stocks: action.payload.appendResult
          ? action.payload.result.concat(state.stocks)
          : action.payload.result
      };
    }
    case UniverseActionTypes.LOAD_STOCK_FAILURE: {
      return {
        ...state,
        errorMessage: 'Error.'
      };
    }
    case UniverseActionTypes.LOAD_SEARCH_RESULTS_SUCCESS: {
      return {
        ...state,
        searchResults: action.payload
      };
    }
    case UniverseActionTypes.LOAD_SEARCH_RESULTS_FAILURE: {
      return {
        ...state,
        errorMessage: 'Error.'
      };
    }
    case UniverseActionTypes.ADD_SELECTED_STOCKS: {
      return {
        ...state,
        selectedStocks: action.payload.isAppend
          ? action.payload.result.concat(state.selectedStocks)
          : action.payload.result
      };
    }
    case UniverseActionTypes.NAVIGATED_UNIVERSE_SUCCESS: {
      return {
        ...state,
        selectedUniverse: action.payload.result
      };
    }
    case UniverseActionTypes.RESET: {
      return Object.assign({}, initialState);
    }
    default: {
      return state;
    }
  }
}
