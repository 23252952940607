import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reviewdate',
  templateUrl: './reviewdate.component.html',
  styleUrls: ['./reviewdate.component.scss']
})
export class ReviewdateComponent implements OnInit, AfterViewInit {
  weekdays = [
    { name: 'Monday', value: 1 },
    { name: 'Tuesday', value: 2 },
    { name: 'Wednesday', value: 3 },
    { name: 'Thursday', value: 4 },
    { name: 'Friday', value: 5 },
    { name: 'Saturday', value: 6 },
    { name: 'Sunday', value: 7 }
  ];
  getDateForm: FormGroup;
  formSubmit = false;
  myValue: string;
  isWeekly: boolean;

  constructor(
    private mdDialogRef: MatDialogRef<ReviewdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reviewFeqName: string }
  ) {
    this.isWeekly = data.reviewFeqName == 'WEEKLY';
  }

  ngOnInit(): void {
    this.getDateForm = new FormGroup({
      select_date: new FormControl(''),
      weekday: new FormControl(0)
    });
  }

  ngAfterViewInit(): void {
    let cells = document.getElementsByTagName('td');
    let _owner: any = this;
    for (var i = 0; i <= cells.length; i++) {
      if (cells[i]) {
        cells[i].addEventListener('click', clickHandler);
      }
    }

    function clickHandler(this: any) {
      _owner.myValue = this.textContent;

      _owner.mdDialogRef.close({ date: _owner.myValue });
    }
  }

  onWeekdayChange(event: any) {
    this.mdDialogRef.close({
      date: this.getDateForm.controls['weekday'].value
    });
  }

  onSubmit() {
    this.mdDialogRef.close({ date: this.myValue });
  }
}
