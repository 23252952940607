<h1 mat-dialog-title>
  <div class="icon cursor" (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </div>
</h1>
<div mat-dialog-content>
  <p>
    <span style="padding-left: 10%"></span> I hereby declare that the details furnished above are
    true and correct to the best of my knowledge and belief and I understand to inform you of any
    changes therein, immediately. In case any of the above information is found to be false or
    untrue or misleading or misrepresenting, I am aware that Lamron Analysts Pvt.Ltd. cannot be held
    liable for any losses caused due to wrong information provide by me above.
  </p>
</div>
<!-- <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>  
</div> -->
