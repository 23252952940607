import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { cloneDeep } from 'lodash';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../auth/services/auth.service';

interface questionAnswers {
  rp_question: string;
  rp_answer: string;
  rp_question_no: any;
  rp_answer_no: any;
}
interface product {
  product_name: string;
}
interface subscriberData {
  docs: [
    {
      subscriber_first_name: string;
      subscriber_last_name: string;
      password: string;
      subscriber_email: string;
      subscriber_mobile: string;
      joining_date: string;
      lead_id?: any;
      subscriber_pan: any;
      referrer_id?: any;
      risk_profile: string;
      'public.subscriber_products': Array<product>;
    }
  ];
}

@Injectable({
  providedIn: 'root'
})
export class OnBoardingService {
  constructor(private http: HttpClient,private AuthService:AuthService) {}
  private FETCH_RISK_PROFILE_QUESTIONS = `store/smartvalues/myquery/execute/fetch_riskprofile_questions`;
  public _questions: any[];
  registerationStart: boolean = true;
  kycDocs: any = {
    docs: [
      {
        doc_type: 'PAN',
        doc_id: '',
        filename: '',
        folderpath: ''
      },
      {
        doc_type: 'AADHAR',
        doc_id: '',
        filename: '',
        folderpath: ''
      },
      {
        doc_type: 'AADHAR_B',
        doc_id: '',
        filename: '',
        folderpath: ''
      }
    ]
  };
  digilockerIframePopup: any = null;
  async getQuestions(): Promise<any> {
    const data = {};

    return await this.http
      .post(environment.baseUrl + this.FETCH_RISK_PROFILE_QUESTIONS, data)
      .toPromise();
  }

  generateOTP(mobile: any) {
    return this.http.get(
      environment.baseUrl_8085 +
        `smartvalues/generateotp/API/SMS/OTP?to=${mobile}${
          environment.type == 'PRODUCTION' ? '' : '&silent=true'
        }`
    );
  }
  verifyOTP(body: any,access_token:any) {
    const headers = new HttpHeaders({ Authorization: access_token});
    return this.http.post(
      environment.baseUrl_8085 + 'smartvalues/eru/checkverifycode',
      body,{
        headers: headers
      }
    );
  }
  generateMobileOTP(payload: any,access_token:any) {  
    // return this.http.post(
    //   environment.baseUrl_8085 +
    //     `smartvalues/eru/getverifycode`+environment.type == 'PRODUCTION' ? '' : '&silent=true',payload
    // );
    const headers = new HttpHeaders({ Authorization: access_token });
    return this.http.post(`${environment.baseUrl_8085}smartvalues/eru/getverifycode${environment.type == 'PRODUCTION' ? '' : '?&silent=true'}`,payload, {
      headers: headers
    })
  }
  generateEmailOTP(payload: any,access_token:any) {
    // return this.http.post(
    //   environment.baseUrl_8085 +
    //     `smartvalues/eru/getverifycode`+ environment.type == 'PRODUCTION' ? '' : '&silent=true',payload
    // );
    const headers = new HttpHeaders({ Authorization: access_token });
    return this.http.post(`${environment.baseUrl_8085}smartvalues/eru/getverifycode${environment.type == 'PRODUCTION' ? '' : '?&silent=true'}`,payload, {
      headers: headers
    })

  }
  
  saveKyc(body: any) {
    const payload = cloneDeep(body);
    payload.docs = payload.docs.filter((e: any) => {
      return e.filename !== '';
    });
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/save_kycdoc/', payload);
  }

  public set questionsData(value: any) {
    this._questions = value;
    this._questions
      .push
      // {
      //   attempted: false,
      //   question_type: 'upload_documents',
      //   rowIndex: 8,
      //   question: 'Final step, upload PAN and Aadhar',
      // },
      // {
      //   attempted: false,
      //   question_type: 'declaration',
      //   rowIndex: 8,
      //   content: {
      //     question: 'Please accept the below declaration',
      //     description:
      //       'I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief and I understand to inform you of any changes therein, immediately. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that Lamron Analysts Pvt.Ltd. cannot be held liable for any losses caused due to wrong information provide by me above.',
      //   },
      // },
      // {
      //   rowIndex: 10,
      // }
      ();
  }
  public get questionsData() {
    return this._questions;
  }

  public getTemplateType(type: string) {
    let template: string = '';
    switch (type) {
      case 'radio_select':
        template = 'multiplechoice';
        break;
      case 'multi_select':
        template = 'multiplechoice';
        break;
      case 'text_input':
        template = 'questionnaire';
        break;
    }
    return template;
  }
  public createSubscriberData: subscriberData = {
    docs: [
      {
        subscriber_first_name: '',
        subscriber_last_name: '',
        password: '',
        subscriber_email: '',
        subscriber_mobile: '',
        joining_date: '',
        lead_id: 0,
        referrer_id: '',
        risk_profile: '',
        subscriber_pan: '',
        'public.subscriber_products': [
          {
            product_name: ''
          }
        ]
      }
    ]
  };
  public questionAnswer: any = [];
  betaInvestment: any = null;
  checkSubscriber(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/check_subscriber',
      payload
    );
  }
  registerLead(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/register_lead',
      payload
    );
  }

  calculateRiskProfile(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/save_riskprofile/', payload);
  }
  registerSubscriber(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/func/register_subscriber/',
      payload
    );
  }
  saveAnswer(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/save_subscriber_rp',
      payload
    );
  }

  verifyOtpSmartBeta(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/verify_otp_login/', payload);
  }
  get_smartbeta_subscriber(id: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_smartbeta_subscriber',
      { id: id }
    );
  }
  save_subscriber_pan(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/func/save_subscriber_pan/',
      payload
    );
  }
  validatePan(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/validate_pan', payload);
  }
  intiateDigi() {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/digilocker_session', {});
  }
  intiateDigiFlow(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/digilocker_flow', payload);
  }

  closeDialog = new Subject<any>();
}
