import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numFormat",
  pure:false
})
export class NumberSeperatorPipe implements PipeTransform {

  transform(value: any, type: string, decimals: number | string): string {
    try {
      if (value === null || value === undefined || isNaN(value)) {
        return value;
      }

      let formattedNumber = Number(value);
      decimals = Number(decimals);
      let separator = "en-IN";

      if (type === "millions") {
        separator = "en-US";
      } else if (type === "none") {
        return `${formattedNumber}`;
      }

      return Number(formattedNumber.toFixed(decimals)).toLocaleString(
        separator,
        {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        },
      );
    } catch (e) {
      console.error(e);
      return value.toString();
    }
  }

}
