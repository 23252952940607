<div class="popup-section">
  <div class="popup-header">
    <h6>Select Review {{ !isWeekly ? 'Date' : 'Day' }}</h6>
  </div>
  <div class="popup-body">
    <ng-container *ngIf="!isWeekly; else weekly">
      <table class="calender-table text-center" cellspacing="21" cellpadding="21">
        <tbody>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
          </tr>
          <tr></tr>
          <tr>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
          </tr>
          <tr>
            <td>13</td>
            <td>14</td>
            <td>15</td>
            <td>16</td>
            <td>17</td>
            <td>18</td>
          </tr>
          <tr>
            <td>19</td>
            <td>20</td>
            <td>21</td>
            <td>22</td>
            <td>23</td>
            <td>24</td>
          </tr>
          <tr>
            <td>25</td>
            <td>26</td>
            <td>27</td>
            <td>28</td>
            <td>29</td>
            <td>30</td>
          </tr>
          <tr>
            <td>31</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-template #weekly>
      <div class="addExtra">
        <form [formGroup]="getDateForm">
          <!-- <div class="label f-12 selectDay">Select Day</div> -->
          <!-- <select class="selectOpn" formControlName="weekday" (change)="onWeekdayChange($event);">
            <option [value]="0">--Select--</option>
            <option [value]="l.value" *ngFor='let l of weekdays'>{{l.name}}</option>
          </select> -->
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-select
              formControlName="weekday"
              (selectionChange)="onWeekdayChange($event)"
              placeholder="--Select--">
              <mat-option [value]="l.value" *ngFor="let l of weekdays">{{ l.name }}</mat-option>
              <mat-divider></mat-divider>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </ng-template>
  </div>
</div>
