<div class="popup-section" [ngClass]="data.status === 'Success' ? 'sucess-popup' : 'fail-popup'">
  <div class="popup-header">
    <span mat-dialog-title>{{ data.title }}</span>
  </div>
  <div class="popup-body">
    <div mat-dialog-content class="message">
      <p class="dialog-message">{{ data.message }}</p>
    </div>
  </div>
</div>
