import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { SubsciberService } from 'src/app/services/subscriber.service';

@Component({
  selector: 'app-change-investment-popup',
  templateUrl: './change-investment-popup.component.html',
  styleUrls: ['./change-investment-popup.component.scss']
})
export class ChangeInvestmentPopupComponent implements OnInit {
  displayedColumns: string[] = ['investment_amount', 'effective_from', 'effective_to'];
  dataSource: any = [];
  investmentList: any = [];
  changeInvestmentForm: FormGroup;
  errorMessage: any = '';
  isDisabled: boolean;
  uploadedDate: any;
  isDateChanged: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeInvestmentPopupComponent>,
    private subscriberService: SubsciberService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isDateChanged = false;
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.createForm();
    let data = {
      subscriber_id: this.data.id,
      pouch_id: this.data.pouch_id
    };
    this.subscriberService.getPouchSubscriberInvestment(data).subscribe((res: any) => {
      this.investmentList = res[0].subscribers;
      this.dataSource = this.investmentList;
    });
  }

  createForm() {
    this.changeInvestmentForm = new FormGroup({
      change_date: new FormControl(new Date(), {
        validators: [Validators.required]
      }),
      investment_amount: new FormControl('', {
        validators: [Validators.required]
      })
    });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  dateChangeHandler(event: any) {
    this.isDateChanged = true;
  }

  changeInvestment(event: any) {
    this.errorMessage = '';
    if (this.changeInvestmentForm.valid) {
      const formValue = this.changeInvestmentForm.value;

      const item = this.investmentList.find(
        (x: any) =>
          new Date(formValue.change_date) >= new Date(x.effective_from) &&
          new Date(formValue.change_date) <= new Date(x.effective_to)
      );
      let diffInvestment: any = 0;
      if (item) {
        diffInvestment = formValue.investment_amount - item.investment_amount;
      }
      if (this.isDateChanged) {
        formValue.change_date.setDate(formValue.change_date.getDate() + 1);
      }
      const changeDate = new Date(formValue.change_date).toISOString().split('T')[0];
      const data = {
        docs: {
          investment_amount: formValue.investment_amount
        },
        docsi: [
          {
            pouch_id: this.data.pouch_id,
            subscriber_id: this.data.id,
            investment_amount: formValue.investment_amount,
            change_amount: diffInvestment,
            from_date: changeDate
          }
        ],
        pouch_id: this.data.pouch_id,
        subscriber_id: this.data.id,
        change_date: changeDate,
        review_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0]
      };

      this.subscriberService.changeInvestment(data).subscribe({
        next: (res: any) => {
          this.dialogRef.close();
          if (
            res[0].del_pouch_subscriber_stocks &&
            res[0].del_pouch_subscriber_stocks.err == '-' &&
            res[1].del_pouch_subscriber_investments &&
            res[1].del_pouch_subscriber_investments.err == '-' &&
            res[2].pouch_subscribers &&
            res[2].pouch_subscribers.err == '-' &&
            res[3].updatepouchsubscriberinvestmenttodate &&
            res[3].updatepouchsubscriberinvestmenttodate.err == '-' &&
            res[4].pouch_subscriber_investments &&
            res[4].pouch_subscriber_investments.err == '-' &&
            res[5].cisubscriberpouchrebalance &&
            res[5].cisubscriberpouchrebalance.err == '-'
          ) {
            const data: any = {
              start_date: changeDate,
              stock_upload_date: this.uploadedDate.split('T')[0],
              pouch_id: Number(this.data.pouch_id),
              subscriber_id: this.data.id
            };
            this.subscriberService.mapSubscriberToPouchRebalance(data).subscribe((res: any) => {
              const dialog: any = this.dialog.open(CommonDialogComponent, {
                data: {
                  message: 'Investment changed successfully.',
                  title: 'Success',
                  status: 'Success'
                }
              });
              setTimeout(() => {
                dialog.close({ success: true });
              }, 5000);
            });
          } else {
          }
        },
        error: () => {
          const dialog: any = this.dialog.open(CommonDialogComponent, {
            data: {
              message: 'Error on chnage investment.',
              title: 'Error',
              status: 'Fail'
            }
          });
          setTimeout(() => {
            dialog.close({ success: false });
          }, 1000);
        }
      });
    }
  }
}
