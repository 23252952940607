<div class="popup1">
  <div class="d-flex pd-15 relative">
    <div class="f-16 dtext-grey">How was this created</div>
    <div class="icon">
      <mat-icon [mat-dialog-close]="true">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="container">
    <div class="d-flex row">
      <div class="col-md-3 display-grid">
        <span class="f-14">Stock Universe</span>
        <span class="f-16 text-darkgrey">NIFTY 50</span>
      </div>
      <div class="col-md-2 display-grid">
        <span class="f-14">Start Date</span>
        <span class="f-16 text-darkgrey">1 Jan 2021</span>
      </div>
      <div class="col-md-2 display-grid">
        <span class="f-14">End Date</span>
        <span class="f-16 text-darkgrey">31 Dec 2022</span>
      </div>
      <div class="col-md-2 display-grid">
        <span class="f-14">Portfolio Size</span>
        <span class="f-16 text-darkgrey">10 Stocks</span>
      </div>
      <div class="col-md-3 display-grid">
        <span class="f-14">Preview Frequency</span>
        <span class="f-16 text-darkgrey">Quarterly</span>
      </div>
      <!-- <p class="upper">Stock Universe<br />NIFTY 50</p>
            <p class="upper2">Start Date<br />1 Jan 2021</p>
            <p class="upper2">End Date<br />31 Dec 2022</p>
            <p class="upper2">Portfolio Size<br />10 Stocks</p>
            <p class="upper2">PReview Frequency<br />Quarterly</p> -->
    </div>
    <div class="look f-14">Lookback period</div>
    <div class="d-flex">
      <div class="display-grid w17 f-16 text-darkgrey">
        <span>3 mnts</span>
      </div>
      <div class="display-grid w17 w13 f-16 text-darkgrey">
        <span>9 mnts</span>
      </div>
      <div class="display-grid w17 w13 f-16 text-darkgrey">
        <span>18 mnts</span>
      </div>
      <div class="display-grid w17 w13 f-16 text-darkgrey">
        <span>30 mnts</span>
      </div>
    </div>
  </div>
  <div class="pd-10">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="stocks">
        <th mat-header-cell *matHeaderCellDef>Stocks Recommeded</th>
        <td mat-cell *matCellDef="let element">{{ element.stocks }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="suggested">
        <th mat-header-cell *matHeaderCellDef>System suggested<br />Weightage</th>
        <td mat-cell *matCellDef="let element">{{ element.suggested }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="pouch">
        <th mat-header-cell *matHeaderCellDef>Final Pouch<br />Weightage</th>
        <td mat-cell *matCellDef="let element">{{ element.pouch }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
