import { createFeatureSelector } from '@ngrx/store';

import * as pouch from './reducers/pouch.reducer';

export interface PouchState {
  entityState: pouch.State;
}

export const reducers = {
  pouch: pouch.reducer
};

export const selectPouchState = createFeatureSelector<PouchState>('pouch');
