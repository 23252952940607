import { IDashboard } from '../../../../common/interfaces/dashboard';
import { All, DashboardActionTypes } from '../actions/dashboard.action';

export interface State {
  dashboard: IDashboard | null;
  errorMessage: string | null;
}

export const initialState: State = {
  dashboard: null,
  errorMessage: null
};

export function reducer(state = initialState, action: All): State {
  const payload: any = action.payload;
  switch (action.type) {
    case DashboardActionTypes.ADDEDIT_SUCCESS:
      return {
        ...state,
        dashboard: payload
      };
    case DashboardActionTypes.ADDEDIT_FAILURE: {
      return {
        ...state,
        errorMessage: 'Error.'
      };
    }
    default: {
      return state;
    }
  }
}
