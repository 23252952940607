import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { OnBoardingService } from 'src/app/modules/onbording/services/onboarding.service';

@Component({
  selector: 'app-digi-locker-info-popup',
  templateUrl: './digi-locker-info-popup.component.html',
  styleUrls: ['./digi-locker-info-popup.component.scss']
})
export class DigiLockerInfoPopupComponent implements OnInit {
  viewType: any = 'default';
  Form!: FormGroup;
  manualUploadType: any = 'NO';
  constructor(
    public dialogRef: MatDialogRef<DigiLockerInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private onboardingService: OnBoardingService
  ) {}

  ngOnInit(): void {
    // 
    // if(this.data.res.code==='1'||this.data.res.code==='2'||this.data.res.code==='3'||this.data.res.code==='4'){
    //   this.viewType='unableFetchDocuments';
    // }
    // if(this.viewType=== 'default'){
    //   this.Form = new FormGroup({
    //     accessCode: new FormControl('',[Validators.required]),
    //   })
    // }
    this.submitAccess();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  submitAccess() {
    this.viewType = 'fetchingDocuments';
    let userDetails = JSON.parse(localStorage.getItem('user') || '{}');
    
    let payload = {
      access_code: this.data.res.code,
      txn_id: this.data.txn_id,
      folder_path: `${userDetails?.attributes.name.first} ${userDetails?.attributes.name.last}`
    };
    // if(this.Form.valid){
    this.onboardingService.intiateDigiFlow(payload).subscribe({
      next: (res: any) => {
        

        let errorType: any = this.determineErrorType(res) || 'NO';
        this.manualUploadType = errorType;

        if (this.manualUploadType == 'NO') {
          this.dialogRef.close('NO');
        } else {
          this.viewType = 'unableFetchDocuments';
        }
      }
    });
    // }
    // this.onboardingService.intiateDigiFlow()
  }
  manualUpload() {
    this.dialogRef.close(this.manualUploadType);
  }
  determineErrorType(response: any) {
    if (Array.isArray(response)) {
      const [nestedArray, object] = response;
      if (
        Object.keys(object).length === 1 &&
        object.PAN === 'error' &&
        nestedArray.length === 2 &&
        Object.keys(nestedArray[0]).length === 1 &&
        nestedArray[0].ADHAR === 'error'
      ) {
        return 'BOTH';
      }
      if (Object.keys(object).length === 1 && object.PAN === 'error') {
        return 'PAN';
      }

      if (
        nestedArray.length === 2 &&
        Object.keys(nestedArray[0]).length === 1 &&
        nestedArray[0].ADHAR === 'error'
      ) {
        return 'AADHAR';
      }
    } else if (typeof response === 'object' && response.error === 'some error') {
      return 'OTHER';
    }

    return 'NO';
  }
  getText() {
    switch (this.manualUploadType) {
      case 'NO':
        return '';
      case 'PAN':
        return 'PAN';
      case 'AADHAR':
        return 'AADHAR';
      case 'BOTH':
        return 'PAN and AADHAR';
      case 'OTHER':
        return 'PAN and AADHAR';
    }
    return '';
  }
}
