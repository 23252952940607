<div class="sucess-popups">
  <div class="row" style="padding-right: 10px">
    <mat-icon style="margin-left: auto" mat-dialog-close>close</mat-icon>
  </div>
  <div class="text-center">
    <img src="../../../../../assets/images/icons/sucess.png" />
  </div>
  <div class="f-16 fw-600 text-darkgrey text-center mt-30 text-wrap">
    Thank you for contacting us.
  </div>
  <div class="text-center" style="margin-bottom: 10px">We will get in touch with you soon.</div>
</div>
