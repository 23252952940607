import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Subscription, fromEvent } from 'rxjs';

import { UserIdleService } from 'angular-user-idle';
import { TimePopupComponent } from 'src/app/common/components/time-popup/time-popup.component';
import { PublicFacadeService } from 'src/app/modules/public/state/public.facade.services';
import { environment } from 'src/environments/environment';

import { SharedService } from './common/services/shared.service';
import * as LoginActions from './modules/auth/store/actions/auth.action';
import { ThankyouComponent } from './modules/public/components/thankyou/thankyou.component';
import { PublicService } from './modules/public/services/public.service';

declare var gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'smartValue';
  shouldLoadUi: boolean = false;
  loading = false;
  isLogin: boolean;
  isPublic: boolean;
  private dialogRef: any;
  isPublicUI: boolean = false;
  isLoginTrue: boolean = false;
  isRegister: boolean = false;
  landingPage: boolean = false;
  isIdleTimerRequired = false;
  subscription: Subscription;
  idleTimerStartSubscription: Subscription;
  idleTimerStopSubscription: Subscription;

  constructor(
    public router: Router,
    public _router: ActivatedRoute,
    private userIdle: UserIdleService,
    public facadeService: PublicFacadeService,
    public sharedService: SharedService,
    public dialog: MatDialog,
    private store: Store,
    private publicService:PublicService
  ) {
    this.router.events.subscribe((event) => {
      if (environment.enableAnalytics) {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.gtag, {
            page_path: event.urlAfterRedirects
          });
        }
      }

      if (event instanceof NavigationEnd) {
        let url = decodeURIComponent(event.url);
        let queryParams = this.router.parseUrl(url).queryParams;
        let authorization_token = queryParams['authorization_token'];
        let code = queryParams['code'];

        if (authorization_token) {
          this.store.dispatch(
            new LoginActions.LoginWealthy({
              code: authorization_token,
              action: 'LOGINWEALTHY'
            })
          );
        } else if (code) {
          this.store.dispatch(
            new LoginActions.LoginBajaj({
              code: code,
              action: 'LOGINBAJAJ'
            })
          );
        } else {
          this.shouldLoadUi = true;
        }
        this.isLogin = this.isIdleTimerRequired =
          event.url == '/auth/login' ||
          event.url == '/auth/new-password' ||
          event.url == '/auth/register' ||
          event.url == '/auth/otp' ||
          event.url == '/auth/register';

        if (
          event.url == '/onboarding/questionnaire' ||
          event.url == '/onboarding/multiplechoice' ||
          event.url == '/onboarding/declaration' ||
          event.url == '/onboarding' ||
          event.url == '/onboarding/kyc_upload' ||
          event.url == '/dashboard' ||
          event.url == '/investment' ||
          event.url == '/more' ||
          event.url == '/more/stock' ||
          event.url == '/reports' ||
          event.url == '/more/universe' ||
          event.url == '/more/subscriber' ||
          event.url == '/more/subscriber/kyc_docs' ||
          event.url == '/more/holiday' ||
          event.url == '/more/promocode' ||
          event.url == '/investment/create-universe/universe' ||
          event.url == '/more/holiday/add-holiday' ||
          event.url == '/investment/create-pouch' ||
          event.url == '/investment/create-universe/pouch' ||
          event.url !== '/investment/create-universe/pouch'
        ) {
          this.isPublicUI = true;
        } else {
          this.isPublicUI = false;
        }
        if (
          event.url == '/public/landing-page' ||
          event.url == '/public/terms-and-conditions' ||
          event.url == '/public/privacy-policy' ||
          event.url == '/public' ||
          event.url == '/'
        ) {
          this.isPublicUI = false;
          this.landingPage = this.isIdleTimerRequired = true;
        }
        if (event.url == '/auth/login') {
          this.isLoginTrue = true;
        } else {
          this.isLoginTrue = false;
        }
        if (event.url == '/isRegister') {
          this.isRegister = true;
        }
        if (event.url.includes('/auth/register')) {
          this.nohide = false;
        }

        if (!this.isIdleTimerRequired) {
          this.clearSubscription();
          const down$ = fromEvent(document, 'mousedown');
          this.subscription = down$.subscribe((e) => {
            this.restart();
          });
          this.restart();
          this.startWatching();
        } else {
          this.stop();
          this.stopWatching();
          this.clearSubscription();
        }
      }
    });
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('refreshToken');
        if (token == null || token == undefined) {
          sessionStorage.clear();
             const host = window.location.host;
    const subdomain = host.split('.')[0];
    const Component: any = {
      richmee: '/auth/login',
      kandkfinserv:'/auth/login',
      dhan:'/auth/login',
      bajajfinservsecurities: '/',
      wealthy: '/',

    };
if(subdomain&&Component[subdomain]){
  this.router.navigateByUrl(Component[subdomain]);
}else{
  this.router.navigateByUrl('/auth/login');
}
        }
      }
    });
  }

  nohide = true;
  loadingDialog: any = [];

  ngOnInit() {





    if (environment.enableAnalytics) {
      this.loadGoogleAnalytics();
    }

    this.idleTimerStartSubscription = this.userIdle.onTimerStart().subscribe((count: any) => {

      this.showTimerPopup(count);
    });
    this.idleTimerStopSubscription = this.userIdle.onTimeout().subscribe(() => {

      this.stop();
      this.stopWatching();
      this.showAlertPopup();
    });



    // check and set referrer

  }
  isSubDomain() {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    if (subdomain === 'richmee'||subdomain === 'kandkfinserv' || subdomain=='nirjainvestments') {
      return true;
    } else {
      return false;
    }
  }
  loadGoogleAnalytics() {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtag}`;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.gtag}');
    `;
    document.head.appendChild(script2);
  }
  showTimerPopup(count: any) {
    if (count != null && (this.dialogRef === undefined || this.dialogRef == null)) {
      this.dialogRef = this.dialog.open(TimePopupComponent, {
        width: '450px',
        data: {
          title: 'Timeout',
          message: '',
          type: 'popup',
          disableCancelBtn: false,
          disableOkBtn: false
        },
        disableClose: true,
        backdropClass: 'loader-backfrop',
        panelClass: 'custom_popup'
      });
    }
    if (
      count != null &&
      (this.dialogRef !== undefined || this.dialogRef !== null) &&
      this.dialogRef.componentInstance
    ) {
      let configValue: any = this.userIdle.getConfigValue();
      if (configValue.timeout == count) {
        this.stop();
        this.stopWatching();
        this.clearSubscription();
      }
      this.dialogRef.componentInstance.count = configValue.timeout - count;
      this.dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.clickedCancel) {
          this.dialogRef = null;
        }
      });
    }
  }

  showAlertPopup() {

    if (this.dialogRef === undefined || this.dialogRef == null) {
      this.dialog.closeAll();
      localStorage.clear();
const host = window.location.host;
    const subdomain = host.split('.')[0];
    const Component: any = {
      richmee: '/auth/login',
      'kandkfinserv': 'auth/login',
      'nirjainvestments': 'auth/login',
      'dhan':'auth/login',
      bajajfinservsecurities: '/',
      wealthy: '/',

    };
if(subdomain&&Component[subdomain]){
  this.router.navigateByUrl(Component[subdomain]);
}else{
  this.router.navigateByUrl('/auth/login');
}
    }

    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe((result: any) => {
      localStorage.clear();
const host = window.location.host;
    const subdomain = host.split('.')[0];
    const Component: any = {
      richmee: '/auth/login',
      bajajfinservsecurities: '/',
      wealthy: '/',

    };
if(subdomain&&Component[subdomain]){
  this.router.navigateByUrl(Component[subdomain]);
}else{
  this.router.navigateByUrl('/auth/login');
}
      this.dialogRef = null;
    });
  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  ngOnDestroy() {
    this.nohide = true;
    this.idleTimerStartSubscription.unsubscribe();
    this.idleTimerStopSubscription.unsubscribe();
  }

  clearSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  domain = () => {
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    if (subdomain === 'richmee') {
      return 'richmee';
    }
    else if (subdomain=='kandkfinserv') {
        return 'kandkfinserv'
    }
    else if (subdomain=='nirjainvestments') {
        return 'nirjainvestments'
    }
    else if (subdomain === 'bajajfinservsecurities') {
      return 'bajajfinservsecurities';
    } else if (subdomain === 'wealthy') {
      return 'wealthy';
    } else {
      return '';

    }
  };
}
