import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/internal/operators/tap';
import { finalize } from 'rxjs/operators';

import { AuthService } from 'src/app/modules/auth/services/auth.service';

import { SharedService } from '../common/services/shared.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // timerId : any;
  pending: any = {};
  constructor(
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.requestToExclude(request.url)) {
      /*if(!this.authService.token){
      const refreshToken: any = localStorage.getItem('refreshToken');
      this.authService.getTokens(refreshToken).subscribe(response => {
        this.authService.setTokens(response);	
        });
      }*/

      const token = this.authService.token;

      if (token) {
        // let timerId: any;
        // timerId = setTimeout(() => {
        //   delete this.pending[timerId];
        //   this.sharedService.showLoader();
        // }, 2000);
        // this.pending[timerId] = 1;

        // If we have a token, we set it to the header
        request = request.clone({
          setHeaders: { Authorization: `${token}` }
        });
      }

      return next.handle(request).pipe(
        tap(
          // (event: HttpEvent<any>) => {
          //   if (event instanceof HttpResponse) {
          //     for (var t in this.pending)
          //       if (this.pending.hasOwnProperty(t)) {
          //         clearTimeout(t);
          //         delete this.pending[t];
          //       }
          //     this.sharedService.hideLoader();
          //   }
          // },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              //this.showAlertPopup();
            }
          }
        ),
        finalize(() => {
          //this.sharedService.hideLoader();
        })
      );
    } else if (
      request.url.indexOf('verifyrecoverycode') > 0 ||
      request.url.indexOf('completerecovery') > 0
    ) {
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request);
  }

  requestToExclude(resquestedUrl: any) {
    if (
      resquestedUrl.indexOf('auth') > 0 ||
      resquestedUrl.indexOf('login') > 0 ||
      resquestedUrl.indexOf('fetchtokens') > 0 ||
      resquestedUrl.indexOf('get_upload_date') > 0 ||
      resquestedUrl.indexOf('get_public_pouchlist') > 0 ||
      resquestedUrl.indexOf('indexannualizedreturns') > 0 ||
      resquestedUrl.indexOf('completerecovery') > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  // showAlertPopup() {
  //   if (this.dialogRef === undefined) {
  //     this.dialogRef = this.dialogService.showInfoPopup(Messages.ERROR_TITLE,Messages.SESSION_TIMEOUT);

  //     this.dialogRef.afterClosed().subscribe(result => {
  //       if (result && result.clickedOkay) {
  //         this.localStorageService.clearAll();
  //         this.router.navigateByUrl('login');
  //         this.dialogRef = undefined;

  //       }
  //     });
  //   }
  //}
}
