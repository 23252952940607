import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { map, tap } from 'rxjs/operators';

import { jwtDecode } from 'jwt-decode';

import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { OtpComponent } from '../../onbording/components/otp/otp.component';

export interface loginInterface {
  USER_ID_FOR_SRP: string;
  requiredAttributes: string;
  userAttributes: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private AUTH = 'auth';
  private USERMGMT = 'usermgmt';
  private LOGIN = 'smartvalues/eru/login';
  private USER_INFO = 'smartvalues/eru/userinfo';
  private FETCH_TOKEN = 'smartvalues/eru/fetchtokens';
  private LOGOUT = 'smartvalues/eru/logout';
  private CHANGE_PASSWORD_URL = 'smartvalues/eru/changepassword';
  private USER_FETCH = 'USER_FETCH';
  private FETCH_ME = 'FETCH_ME';
  private CHANGE_PASSWORD = 'CHANGE_PASSWORD';
  private SET_NEW_USER_PASSWORD = 'SET_NEW_USER_PASSWORD';
  private GET_RECOVERY_CODE = 'smartvalues/eru/getrecoverycode';
  private NEW_GET_RECOVERY_CODE = 'smartvalues/eru/getrecoverycode'
  private NEW_VERIFY_RECOVERY_CODE = 'smartvalues/eru/verifyrecoverycode'
  private VERIFY_RECOVERY_CODE = 'smartvalues/eru/verifyrecoverycode';
  private COMPLETE_RECOVERY = 'smartvalues/eru/completerecovery';
  private Token: string;
  private Access_Token: string;
  private readonly REFRESH_TOKEN: string = 'refreshToken';
  private User: any;
  onUserUpdate: Subject<any> = new Subject();
  currentURL:any
  constructor(
    private http: HttpClient,
    public router: Router,
    public dialog: MatDialog,
  ) {
    this.router.events.subscribe((res:any)=>{
      this.currentURL = window.location.href;
         
       })
  }

  validateUser(email: string, password: string, action: string) {
    const data = {
      action: action,
      username: email,
      password: password
    };
    return this.http.post(environment.url + this.AUTH, data);
  }

  loginUser(email: string, password: string) {
    const data = {
      username: email,
      password: password
    };
    return this.http.post(environment.login_base_url + this.LOGIN, data).pipe(
      tap((res: any) => {
        let { id_token, refresh_token } = res;
        let decodedToken: any = jwtDecode(id_token);
        localStorage.setItem('id', decodedToken?.identity.id || '');
        // localStorage.setItem('refreshToken',RefreshToken||'')
      })
    );
  }

  getParsedToken(){
    let decodedToken: any = jwtDecode(this.Token);
    return decodedToken
  }
  getIdToken(){
    return this.Token
  }
  fetchUser(access_token: string, idToken: any) {
    const headers = { Authorization: idToken };
    const data = {
      // action: this.FETCH_ME,
      // username: username
      access_token: access_token
    };
    return this.http.post(`${environment.login_base_url}${this.USER_INFO}`, data, { headers }).pipe(
      map((res: any) => {
        if (!res.attributes.name) {
          res.attributes['name'] = {
            first: res.attributes.first_name,
            last: res.attributes.last_name
          };
        }
        return res;
      })
    );
  }

  public get user() {
    return this.User;
  }
openPopup = true
  public setUser(user: any) {
    this.User = user;
    this.onUserUpdate.next(user);
    let userattributes = user.attributes


    if(userattributes.email_verified && userattributes.mobile_verified){
    this.router.navigateByUrl('/dashboard');
    }else{
      if(!(this.currentURL.includes('register')) && this.openPopup){
      this.openOtpDialog('new',userattributes);
      

      }
    }
  }
  openOtpDialog(type: any,userattributes:any) {
    let data = {
      type: type,
      from:'login',
      access_token:this.Access_Token,
      userattributes:userattributes
    }
    const dialogRef = this.dialog.open(OtpComponent, {
      data: data
    });
    this.openPopup = false 
    dialogRef.afterClosed().subscribe((res) => {
      this.openPopup = true
    });
  }
  
  public get token() {
    return this.Token;
  }

  public setTokens(data: any) {
    //
    //const refreshToken = data.RefreshToken;
    // localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    //
    this.Token = data.id_token;
    this.Access_Token = data.access_token;
    localStorage.setItem('refreshToken', data.refresh_token);
    //
  }

  getTokens(refresh_token: string, id: string): Observable<any> {
    const data = {
      refresh_token: refresh_token,
      id: id
    };
    return this.http.post(environment.login_base_url + this.FETCH_TOKEN, data).pipe(
      tap((response) => {
        this.setTokens(response);
      })
    );
  }

  public refreshToken(minutes: number) {
    return new Observable<any>((subscriber) => {
      interval(1000 * 60 * minutes).subscribe((x) => {
        const refreshToken: any = localStorage.getItem(this.REFRESH_TOKEN);
        const id: any = localStorage.getItem('id');
        //
        if (refreshToken) {
          this.getTokens(refreshToken, id).subscribe((response) => {
            subscriber.next(response);
          });
        }
      });
    });
  }

  public startTokenUpdateTimer(time: number) {
    this.refreshToken(time).subscribe((data) => {
      // const token = {
      //           IdToken: data.id_token,
      //           RefreshToken: data.RefreshToken
      //       }
      //
      this.setTokens(data);
    });
  }

  public chnagePassword(oldPassword: any, newPassword: any) {
    const data = {
      // action: this.CHANGE_PASSWORD,
      old_password: oldPassword,
      new_password: newPassword
      // access_token: this.Access_Token,
    };
    const headers = { Authorization: this.Token };
    return this.http.post(environment.login_base_url + this.CHANGE_PASSWORD_URL, data, {
      headers: headers
    });
  }

  public setNewUserPassword(username: any, newpassword: any, newusersession: any) {
    const data = {
      action: this.SET_NEW_USER_PASSWORD,
      username: username,
      password: newpassword,
      session: newusersession
    };
    return this.http.post(environment.url + this.AUTH, data);
  }

  logoutUser() {
    const refreshToken: any = localStorage.getItem('refreshToken');
    const data = {
      refresh_token: refreshToken
    };
    const headers = { authorization: this.Token };
    return this.http.delete(`${environment.login_base_url}${this.LOGOUT}`, {
      headers: headers,
      body: data
    });
  }
  getrecoverycode(userEmail: any) {
    return this.http.post(`${environment.login_base_url}${this.GET_RECOVERY_CODE}`, {
      username: userEmail
    });
  }
  newGetrecoverycode(userEmail: any) {
    return this.http.post(`${environment.login_base_url}${this.NEW_GET_RECOVERY_CODE}`, {
      username: userEmail
    });
  }
  newverifyrecoverycode(id: any, code: any, password:any) {
    return this.http.post(`${environment.login_base_url}${this.NEW_VERIFY_RECOVERY_CODE}`, {
      id: id,
      code: code,
      password: password
    });
  }
  verifyrecoverycode(id: any, code: any) {
    return this.http.post(`${environment.login_base_url}${this.VERIFY_RECOVERY_CODE}`, {
      id: id,
      code: code
    });
  }
  completeRecovery(id: any, password: any) {
    return this.http.post(`${environment.login_base_url}${this.COMPLETE_RECOVERY}`, {
      id: id,
      password: password
    });
  }
  loginWealthy(body: any) {
    return this.http.post(environment.baseUrl_8085 + `smartvalues/wealthy/login`, body).pipe(
      tap((res: any) => {
        let { id_token, refresh_token } = res;
        let decodedToken: any = jwtDecode(id_token);
        localStorage.setItem('id', decodedToken?.identity.id || '');
      })
    );
  }
  loginBajaj(body: any) {
    return this.http.post(environment.baseUrl_8085 + `smartvalues/bajaj/login`, body).pipe(
      tap((res: any) => {
        let { id_token, refresh_token } = res;
        let decodedToken: any = jwtDecode(id_token);
        localStorage.setItem('id', decodedToken?.identity.id || '');
      })
    );
  }
  generateMobileOTP(payload: any) {
    const headers = { authorization: this.Token };
    return this.http.post(
      environment.baseUrl_8085 +
        `smartvalues/eru/getverifycode`,payload, {headers: headers}
    );
  }
  generateEmailOTP(payload: any) {
    const headers = { authorization: this.Token };
    return this.http.post(
      environment.baseUrl_8085 +
        `smartvalues/eru/getverifycode`+ '?silent=true',payload, {headers: headers}
    );
  }
}
