<div class="popup-section">
  <form [formGroup]="changeInvestmentForm" (ngSubmit)="changeInvestment($event)">
    <div class="popup-header">
      <h3>Change Investment</h3>
    </div>
    <div class="popup-body">
      <div class="row">
        <div class="col-md-6 mb-20">
          <div>
            <p class="f-12 text-grey mb-05">Investment Amount</p>
          </div>

          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              type="number"
              autocomplete="off"
              min="0"
              name="investment_amount"
              formControlName="investment_amount" />
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-20">
          <div>
            <p class="f-12 text-grey mb-05">Change Date</p>
          </div>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              [matDatepicker]="picker"
              placeholder=""
              formControlName="change_date"
              (dateChange)="dateChangeHandler($event)" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="pb-20 mt-40">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
          <ng-container matColumnDef="investment_amount">
            <th mat-header-cell *matHeaderCellDef class="">Investment Amount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.investment_amount }}
            </td>
          </ng-container>

          <ng-container matColumnDef="effective_from">
            <th mat-header-cell *matHeaderCellDef>Investment From</th>
            <td mat-cell *matCellDef="let element">
              {{ element.effective_from | date: 'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="effective_to">
            <th mat-header-cell *matHeaderCellDef>Investment To</th>
            <td mat-cell *matCellDef="let element">
              {{ element.effective_to | date: 'mediumDate' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="table_row-value" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>

    <div class="popup-footer">
      <div class="btn-group">
        <span class="error">{{ errorMessage }}</span>
        <button mat-button class="theme-btn border-btn" type="button" (click)="onCancelClick()">
          Cancel
        </button>
        <button mat-button class="theme-btn primary-btn ml-20" type="submit">
          Change Investment
        </button>
      </div>
    </div>
  </form>
</div>
