import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NotificationService } from 'src/app/services/notification.services';
import { environment } from 'src/environments/environment';

export interface AgreementList {
  agreements: Agreement[];
}

export interface Agreement {
  agreement_file_name: string;
  agreement_id: string;
  investment_amount: number;
  pouch_name: null | string;
  product_name: string;
  registration_date: Date | string;
  rowIndex: number;
  subscriber_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class MoreService {
  constructor(
    private http: HttpClient,
    private nt: NotificationService
  ) {}

  fetchPromoCodes(body?: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/fetch_promo_codes',
      { ...body }
    );
  }
  createPromoCode(body: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/create_promo_code',
      body
    );
  }
  editPromoCode(body: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/edit_promo_code',
      body
    );
  }
  getReferrers(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_referrers',
      payload
    );
  }
  getReferrerUsers(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_referrer_users',
      payload
    );
  }
  editReferrers(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/edit_referrer',
      payload
    );
  }
  editReferrersUser(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/edit_referrer_user',
      payload
    );
  }
  createReferrers(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/create_referrer',
      payload
    );
  }
  createReferrersUser(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/func/register_referrer/',
      payload
    );
  }

  getAdminUsers(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_admin_users',
      payload
    );
  }
  createAdminUser(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/register_admin/', payload);
  }
  editAdminUser(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/edit_admin_user',
      payload
    );
  }
  getLink(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/route/get_referrer_link/',
      payload
    );
  }
  email_sb_link(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/email_sb_link/', payload);
  }

  public fetch_Agreement_list(payload: any) {
    return this.http
      .post<
        [{ agreements: Array<Agreement> }]
      >(environment.baseUrl + `store/smartvalues/myquery/execute/view_agreements`, payload)
      .pipe(
        map((response) => {
          if (JSON.stringify(response) === '[{}]') {
            console.error('empty data found...');
            return [];
          } else if (response[0].agreements.some((obj) => Object.keys(obj).length === 0)) {
            console.error('empty data found...');
            return [];
          }
          return response[0].agreements;
        }),
        catchError((error) => {
          this.handleError(error);
          return throwError(() => error);
        })
      );
  }

  fetch_Agreement_Doc(payload: any) {
    return this.http
      .post(environment.baseUrl_8082 + `files/smartvalues/ag_docs/download`, payload, {
        responseType: 'blob'
      })
      .pipe(
        map((response) => {
          if (!response) {
            console.error('empty data found...');
            return;
          }
          return response;
        }),
        catchError((error) => {
          this.handleError(error);
          return throwError(() => error);
        })
      );
  }

  // Show  Error Notification

  handleError(error: HttpErrorResponse) {
    if (error.status >= 400) {
      this.nt.showError(error.status.toString(), error.error);
    }
  }
  getpouchSubscriberSubscription(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_pouch_subscriber_subscriptions',
      payload
    );
  }
  fetch_stock_bonus(body: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/fetch_stock_bonus',
      body
    );
  }
  fetch_stock_split(body: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/fetch_stock_split',
      body
    );
  }
  getStocks(body: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_stocks',
      body
    );
  }

  insertBonus(body: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/insert_stock_bonus', body);
  }
  insertSplit(body: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/insert_stock_split', body);
  }
  deleteSplit(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'store/smartvalues/myquery/execute/delete_stock_split',
      body
    );
  }
  deleteBonus(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'store/smartvalues/myquery/execute/delete_stock_bonus',
      body
    );
  }
}
