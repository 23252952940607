import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { Elements } from '../../../common/interfaces/table';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private GET_KPIS = `store/smartvalues/myquery/execute/get_kpis`;
  // private GET_POUCH_LIST = `store/smartvalues/myquery/execute/get_pouchlist`;
  // private GET_SUBSCRIBER_LIST = `store/smartvalues/myquery/execute/get_subscriberlist`;
  private GET_POUCH_REBALANCE_LIST = `store/smartvalues/myquery/execute/get_pouch_rebalance_list`;
  private GET_TOP_GAINERS_LOSEERS = `store/smartvalues/myquery/execute/get_top_gainers_losers`;

  constructor(private http: HttpClient) {}

  public getKPIs() {
    return this.http.post(environment.baseUrl + this.GET_KPIS, {});
  }

  // public getPouchList(data : any){
  //   return this.http.post(environment.baseUrl + this.GET_POUCH_LIST, data)
  // }

  // public getSubscriberList(data : any){
  //   return this.http.post(environment.baseUrl + this.GET_SUBSCRIBER_LIST, data)
  // }

  public getPouchRebalanceList(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_REBALANCE_LIST, data);
  }

  getTopGainersLosers() {
    return this.http.post(environment.baseUrl + this.GET_TOP_GAINERS_LOSEERS, {});
  }
}
