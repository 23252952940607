import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as PublicActions from './public.action';
import * as PublicSelectors from './public.selectors';

@Injectable()
export class PublicFacadeService {
  constructor(private store: Store) {}
  getUploadDateState$ = this.store.select(PublicSelectors.getUploadDateFromStore);
  setUploadDateToStore() {
    if (localStorage.getItem('stock_price_uploaded_date')) {
      return;
    }
    this.store.dispatch(PublicActions.setUploadDate());
  }

  LoadPouchListfromState$ = this.store.select(PublicSelectors.getPouchListFromStore);
  getPouchListfromStore(date: any) {
    this.store.dispatch(PublicActions.loadPouches({ date }));
  }
  LoadAnnualizedfromState$ = this.store.select(PublicSelectors.getAnnualizedFromStore);
  getAnnualizedfromStore(date: any) {
    this.store.dispatch(PublicActions.loadAnnualizedReturns({ date }));
  }
  addNewPouchToStore(data: any) {
    this.store.dispatch(PublicActions.addNewPouchToState({ data }));
  }
}
