<div class="container px-4">
  <div class="p-4">
    <div class="text-center">
      <img src="../../../../../../assets/images/icons/avatar.svg" alt="" />
    </div>
   <ng-container *ngIf="!paymentLoader">
    <div class="text-center text pt-4">
      Loading. It will take sometime. <br />
      How about grabing a cup of coffee.
    </div>
   </ng-container>
   <ng-container *ngIf="paymentLoader">
    <div class="text-center text pt-4">
      Order Processing <br />
    Please do not click back button or refresh the page
    </div>
   </ng-container>
  </div>
</div>
