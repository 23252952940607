import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';
import { SharedService } from 'src/app/common/services/shared.service';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { NotificationService } from 'src/app/services/notification.services';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-update-transaction-popup',
  templateUrl: './update-transaction-popup.component.html',
  styleUrls: ['./update-transaction-popup.component.scss']
})
export class UpdateTransactionPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateTransactionPopupComponent>,
    public pouchService: PouchService,
    public sharedService: SharedService,
    public notifyService: NotificationService
  ) {}
  txn_date: any;
  price: number;
  qty: number;
  ngOnInit(): void {
    
    this.txn_date = this.data.proposed_date;
    this.price = 0;
    this.qty = this.data.stock_qty;
  }
  btndisable = false;
  qtyError = '';
  priceError = '';
  showError:boolean = false
  errorMessage:string = ''
  close(val: any) {
    if (val) {
      this.showError = false
      this.errorMessage = ''
      this.btndisable = true;
      if (this.price > 0 && this.qty > 0) {
        this.qtyError = '';
        this.priceError = '';
        this.btndisable = true;
        let uuid = uuidv4();
        let tradeDate = moment(new Date(this.txn_date)).format('YYYY-MM-DD');
        let payload = {
          pouch_id: this.data.pouch_id,
          txn_id: uuid,
          subscriber_id: this.data.subscriber_id,
          from_date: tradeDate,
          to_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
          trade_id: this.data.id,
          docspsts: {
            pouch_id: this.data.pouch_id,
            subscriber_id: this.data.subscriber_id,
            txn_id: uuid,
            order_stock_status: 'COMPLETE',
            order_id: uuid,
            stock_code: this.data.stock_code,
            stock_rate: this.price,
            trade_date: tradeDate,
            stock_qty: this.qty,
            stock_qty_filled: this.qty,
            txn_type: this.data.txn_type
          }
        };
        this.pouchService.fetchValidStockPrice(payload).subscribe(
          (res: any) => {
            
            this.dialogRef.close(true);

            this.notifyService.showSuccess('', 'Update Success');
            this.btndisable = false;
          },
          (e: any) => {
            // this.notifyService.showError('', e.error.error.message);
            this.showError = true
            this.errorMessage = e.error.error
            this.btndisable=false
          }
        );
        // this.pouchService.updateManualTxn(payload).subscribe(
        //   (res: any) => {
        //     this.dialogRef.close(true);

        //     this.notifyService.showSuccess('', 'Update Success');
        //     this.btndisable=false
        //   },
        //   (e: any) => {
        //     this.notifyService.showError('', e.error.error.message);
        //     this.btndisable=false
        //   }
        // );
      } else {
        
        if (!(this.price > 0)) {
          this.priceError = 'Price is invalid should be greater than 0.';
        }
        if (!(this.qty > 0)) {
          this.qtyError = 'Quantity is invalid should be greater than 0.';
        }
        this.btndisable = false;
      }
    } else {
      this.dialogRef.close();
      this.btndisable = false;
    }
  }
}
