import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currentTradeValue',
  pure:false
})
export class CurrentTradeValuePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): number {
    const total = value.reduce((acc: any, cur: any) => {
    return acc +(cur.close_price* cur.stock_qty)
     },0).toFixed(2)
    return total;
  }

}
