import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nonZeroTrades',
  pure:false
})
export class NonZeroTradesPipe implements PipeTransform {

  transform(value: any,isReview:boolean): Array<any> {
    return value.filter((e: any) => {
      if (isReview) {
        return  e.stock_qty !== 0
      }
      else {
        return e
     }
    });
  }

}
