import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invalid',
  templateUrl: './invalid.component.html',
  styleUrls: ['./invalid.component.scss']
})
export class InvalidComponent implements OnInit {
  showAborted = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InvalidComponent>
  ) {}

  ngOnInit(): void {
    
  }
  close(val: any) {
    this.dialogRef.close(val);
  }
  subscribe(val?: any) {
    this.dialogRef.close('reIntiate');
  }
}
