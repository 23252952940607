import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs/internal/Subject';

import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PreloaderComponent } from 'src/app/preloader/preloader.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(
    public authService: AuthService,
    public dialog: MatDialog
  ) {}
  // dialogRef: any;

  onSubscriberAdd: Subject<any> = new Subject();
  showLoader$ = new Subject<boolean>();
  userInfo = JSON.parse(localStorage.getItem('user') || '{}');
  uploadDate = localStorage.getItem('stock_price_uploaded_date')?.split('T')[0];
  onUserUpdate = this.authService.onUserUpdate;
  dialogPending: any = [];
  checkSubscriptionActive() {
    let user = JSON.parse(localStorage.getItem('user') || '{}').attributes;
    if (user.kyc_date !== '' && user.risk_profile !== 'PENDING') {
      return true;
    } else {
      return false;
    }
  }
  showLoader() {
    let dialogRef = this.dialog.open(PreloaderComponent, {
      disableClose: true
    });
    this.dialogPending.push(dialogRef);
  }
  hideLoader() {
    for (let dialogRef of this.dialogPending) {
      dialogRef.close();
    }
    this.dialogPending = [];
  }

}
