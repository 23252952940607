import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/modules/auth/services/auth.service';

import { environment } from '../../../../../../environments/environment';
import { NotificationService } from '../../../../../services/notification.services';
import { OnBoardingService } from '../../../services/onboarding.service';
import { DigiLockerInfoPopupComponent } from './digi-locker-info-popup/digi-locker-info-popup.component';
import { DigiLockerInfoSuccessPopupComponent } from './digi-locker-info-success-popup/digi-locker-info-success-popup.component';

@Component({
  selector: 'app-fileUploads',
  templateUrl: './fileUploads.component.html',
  styleUrls: ['./fileUploads.component.scss']
})
export class FileUploadsComponent implements OnInit, DoCheck {
  disableSaveBtn = false;
  @Input() templateData: any;
  @Input() screenWidth: any;
  @Input() from: any;
  @Input() type: any;
  @Input() pan: any;
  @Input() aadhar: any;
  @Input() aadhar_b: any;
  @Output() navigationHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() completionHandler: EventEmitter<any> = new EventEmitter<any>();
  @Input() userName: any;
  @Input() buttonShow: boolean;
  isMobile: boolean;
  showupload: boolean = true;
  showPan: boolean = false;
  showAadhar: boolean = false;
  showAadhar_b: boolean = false;
  enableButtton: boolean;
  hideButton: boolean = false;
  uploadFileProcess: any = {
    pan: {
      panFileName: '',
      showProgressForPan: false,
      progressValueForPan: 0,
      isPanUploaded: false
    },
    aadhar: {
      aadharFileName: '',
      showProgressForAadhar: false,
      progressValueForAadhar: 0,
      isAadharUploaded: false
    },
    aadhar_b: {
      aadhar_bFileName: '',
      showProgressForAadhar_b: false,
      progressValueForAadhar_b: 0,
      isAadhar_bUploaded: false
    }
  };
  subscriberTemplate = this.OnBoardingService.kycDocs;
  showErrorColor: boolean = false;
  getValueChange: any;
  @ViewChild('ngxPanDropzone') ngxPanDropzone: ElementRef;
  @ViewChild('ngxAadharDropzone') ngxAadharDropzone: ElementRef;
  @ViewChild('ngxAadhar_bDropzone') ngxAadhar_bDropzone: ElementRef;
  AadharUploaded: boolean;
  Aadhar_bUploaded: boolean;
  panUploded: boolean;
  constructor(
    private authService: AuthService,
    private _router: Router,
    private router: ActivatedRoute,
    private http: HttpClient,
    private OnBoardingService: OnBoardingService,
    private notifyService: NotificationService,
    private dialog: MatDialog
  ) {}
  uploadPan: boolean = true;
  uploadAadhar: boolean = true;
  uploadAadhar_b: boolean = true;
  updatePan() {
    // this.ngxPanDropzone.nativeElement.click();
    this.uploadPan = false;
  }
  updateAadhar() {
    // this.ngxAadharDropzone.nativeElement.click();
    this.uploadAadhar = false;
  }
  updateAadhar_b() {
    // this.ngxAadharDropzone.nativeElement.click();
    this.uploadAadhar_b = false;
  }
  showResponsePopupDigi(params: any) {
    const dialog2 = this.dialog.open(DigiLockerInfoPopupComponent, {
      data: {
        res: params,
        txn_id: sessionStorage.getItem('txn_id')
      }
    });
    dialog2.afterClosed().subscribe({
      next: (res) => {
        if (res == 'NO') {
          const sucessDialog = this.dialog.open(DigiLockerInfoSuccessPopupComponent);
          sucessDialog.afterClosed().subscribe((res) => {
            let userInfo = JSON.parse(localStorage.getItem('user') || '{}');
            userInfo.attributes.kyc_date = new Date(this.setExpDate()).toISOString().split('T')[0];
            localStorage.setItem('user', JSON.stringify(userInfo));

            if (sessionStorage.getItem('userType') === 'smartbeta') {
              this._router.navigateByUrl('/agreement/smartbeta');
            } else {
              this._router.navigateByUrl('/dashboard');
            }

            // this.navigationHandler.emit({ type: 'next' });
          });
        } else if (res === 'PAN') {
          this.showupload = false;
          this.showPan = true;
          this.showAadhar = false;
          this.showAadhar_b = false;
          this.hideButton = true;
        } else if (res === 'AADHAR') {
          this.showupload = false;
          this.showPan = false;
          this.showAadhar = true;
          this.hideButton = true;
        } else if (res === 'AADHAR_B') {
          this.showupload = false;
          this.showPan = false;
          this.showAadhar = false;
          this.showAadhar_b = true;
          this.hideButton = true;
        } else if (res === 'BOTH' || res === 'OTHER') {
          this.showupload = false;
          this.showPan = true;
          this.showAadhar = true;
          this.showAadhar_b = true;
          this.hideButton = true;
        }
        this.getValueChange = res;
      }
    });
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        const { state, code } = params;
        this.showResponsePopupDigi(params);
      }
    });
    if (this.type == 'subscriber') {
      let user = JSON.parse(localStorage.getItem('user') || '{}').attributes.name;
      this.userName = `${user.first} ${user.last}`;
    }
    if (this.pan) {
      this.uploadFileProcess.pan.isPanUploaded = true;
    }
    if (this.aadhar) {
      this.uploadFileProcess.aadhar.isAadharUploaded = true;
    }
    if (this.aadhar_b) {
      this.uploadFileProcess.aadhar_b.isAadhar_bUploaded = true;
    }
  }
  ngDoCheck(): void {
    if (this.screenWidth <= 991) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  changeuploard() {
    this.showupload = false;
    this.showPan = true;
    this.showAadhar = true;
    this.showAadhar_b = true;
    this.hideButton = true;
    this.buttonShow = false;
  }

  @Output() fileSaved: EventEmitter<any> = new EventEmitter<any>();
  showSaveBtn: boolean = false;
  completeQuestionare(element?: any) {
    element.disabled = true;
    this.showSaveBtn = true;
    this.navigationHandler.emit({ type: 'next' });

    if (this.from === 'profile') {
      if (this.type == 'subscriber') {
        let user = JSON.parse(localStorage.getItem('user') || '{}').attributes.name;
        let KYC_docsArray: any = this.subscriberTemplate['docs'];
        KYC_docsArray[0]['folderpath'] = `${user.first} ${user.last}`;
        KYC_docsArray[1]['folderpath'] = `${user.first} ${user.last}`;
        KYC_docsArray[2]['folderpath'] = `${user.first} ${user.last}`;

        this.OnBoardingService.saveKyc(this.OnBoardingService.kycDocs).subscribe(
          (res: any) => {
            this.fileSaved.emit(true);
            element.disabled = false;
            this.showSaveBtn = false;
            this.authService
              .getTokens(
                localStorage.getItem('refreshToken') || '',
                localStorage.getItem('id') || ''
              )
              .subscribe();
          },
          () => {
            element.disabled = false;
            this.showSaveBtn = false;
          }
        );
      }
    }

    // this.templateData.attempted = true;
    // if (this.subscriberTemplate.subscriber_email) {
    //   this.createSubscriberRiskProfile();
    // } else {
    //   this.notifyService.showError('ERROR : Enter a valid Email', '');
    // }
  }

  uploadFile(file: any, fileName: string, docType: string) {
    const form = new FormData();

    if (docType === 'AADHAR') {
      form.append('files', file, fileName);
      form.append('doctype', `AADHAR:${fileName}`);
    }
    if (docType === 'AADHAR_B') {
      form.append('files', file, fileName);
      form.append('doctype', `AADHAR_B:${fileName}`);
    }
    if (docType === 'PAN') {
      form.append('files', file, fileName);
      form.append('doctype', `PAN:${fileName}`);
    }
    form.append('folderpath', this.userName);
    // form.append('keyPairName', 'kyc_dev');

    this.http
      .post(environment.baseUrl_8082 + 'files/smartvalues/kyc_docs/upload', form, {
        reportProgress: true,
        observe: 'events'
      })

      .subscribe(
        (events: HttpEvent<any>) => {
          if (events.type === HttpEventType.UploadProgress) {
            if (events.total) {
              if (docType === 'AADHAR') {
                this.uploadFileProcess.aadhar.progressValueForAadhar = Math.round(
                  (events.loaded / events.total) * 100
                );
                this.uploadFileProcess.aadhar.isAadharUploaded = true;
                this.AadharUploaded = this.uploadFileProcess.aadhar.isAadharUploaded;
              }
              if (docType === 'AADHAR_B') {
                this.uploadFileProcess.aadhar_b.progressValueForAadhar_b = Math.round(
                  (events.loaded / events.total) * 100
                );
                this.uploadFileProcess.aadhar_b.isAadhar_bUploaded = true;
                this.Aadhar_bUploaded = this.uploadFileProcess.aadhar_b.isAadhar_bUploaded;
              }
              if (docType === 'PAN') {
                this.uploadFileProcess.pan.progressValueForPan = Math.round(
                  (events.loaded / events.total) * 100
                );
                this.uploadFileProcess.pan.isPanUploaded = true;
                this.panUploded = this.uploadFileProcess.pan.isPanUploaded;
              }
              this.changeSavaButtonEnable();
            }
          }
          if (events.type === HttpEventType.Response) {
            let KYC_docsArray: any = this.subscriberTemplate['docs'];

            if (docType === 'PAN') {
              KYC_docsArray[0]['doc_id'] = events.body.files[fileName];
              KYC_docsArray[0]['filename'] = fileName;
              KYC_docsArray[0]['folderpath'] = this.userName;
            }
            if (docType === 'AADHAR') {
              KYC_docsArray[1]['doc_id'] = events.body.files[fileName];
              KYC_docsArray[1]['filename'] = fileName;
              KYC_docsArray[1]['folderpath'] = this.userName;
            }
            if (docType === 'AADHAR_B') {
              KYC_docsArray[2]['doc_id'] = events.body.files[fileName];
              KYC_docsArray[2]['filename'] = fileName;
              KYC_docsArray[2]['folderpath'] = this.userName;
            }
          }
        },
        (err: any) => {
          this.notifyService.showError('ERROR : File upload error', '');

          if (docType === 'AADHAR') {
            this.clearAdharFile({ target: { id: fileName } });
          } else if (docType === 'PAN') {
            this.clearPanFile({ target: { id: fileName } });
          } else if (docType === 'AADHAR_B') {
            this.clearAdhar_bFile({ target: { id: fileName } });
          }
        }
      );
  }
  changeSavaButtonEnable() {
    if (this.getValueChange === 'PAN') {
      this.enableButtton = this.uploadFileProcess.pan.isPanUploaded;
    } else if (this.getValueChange === 'AADHAR') {
      this.enableButtton = this.uploadFileProcess.aadhar.isAadharUploaded;
    } else if (this.getValueChange === 'AADHAR_B') {
      this.enableButtton = this.uploadFileProcess.aadhar_b.isAadhar_bUploaded;
    } else if (this.getValueChange === 'BOTH' || this.getValueChange === 'OTHER') {
      this.enableButtton =
        this.uploadFileProcess.pan.isPanUploaded &&
        this.uploadFileProcess.aadhar.isAadharUploaded &&
        this.uploadFileProcess.aadhar_b.isAadhar_bUploaded;
    }
  }
  createSubscriberRiskProfile() {}

  back() {
    this.navigationHandler.emit({ type: 'back' });
  }

  clearAdharFile(event?: any) {
    let KYC_docsArray = this.subscriberTemplate['docs'];
    let findDoc = this.subscriberTemplate['docs'].find(
      (doc: any) => doc.filename === event.target.id
    );

    // KYC_docsArray.splice(KYC_docsArray.indexOf(findDoc), 1);
    KYC_docsArray[KYC_docsArray.indexOf(findDoc)] = {
      doc_type: 'AADHAR',
      doc_id: '',
      filename: '',
      folderpath: ''
    };
    this.uploadFileProcess.aadhar.showProgressForAadhar = false;
    this.uploadFileProcess.aadhar.isAadharUploaded = false;
    this.AadharUploaded = this.uploadFileProcess.aadhar.isAadharUploaded;
  }
  clearAdhar_bFile(event?: any) {
    let KYC_docsArray = this.subscriberTemplate['docs'];
    let findDoc = this.subscriberTemplate['docs'].find(
      (doc: any) => doc.filename === event.target.id
    );

    // KYC_docsArray.splice(KYC_docsArray.indexOf(findDoc), 1);
    KYC_docsArray[KYC_docsArray.indexOf(findDoc)] = {
      doc_type: 'AADHAR_B',
      doc_id: '',
      filename: '',
      folderpath: ''
    };
    this.uploadFileProcess.aadhar_b.showProgressForAadhar_b = false;
    this.uploadFileProcess.aadhar_b.isAadhar_bUploaded = false;
    this.Aadhar_bUploaded = this.uploadFileProcess.aadhar_b.isAadhar_bUploaded;
  }
  clearPanFile(event?: any) {
    let KYC_docsArray = this.subscriberTemplate['docs'];
    let findDoc = this.subscriberTemplate['docs'].find(
      (doc: any) => doc.filename === event.target.id
    );
    // KYC_docsArray.splice(KYC_docsArray.indexOf(findDoc), 1);
    KYC_docsArray[KYC_docsArray.indexOf(findDoc)] = {
      doc_type: 'PAN',
      doc_id: '',
      filename: '',
      folderpath: ''
    };

    this.uploadFileProcess.pan.showProgressForPan = false;
    this.uploadFileProcess.pan.isPanUploaded = false;
    this.panUploded = this.uploadFileProcess.pan.isPanUploaded;
  }
  onSelect(event: any) {
    if (event.source.id === 'AADHAR') {
      let file = event.addedFiles[0];
      this.uploadFileProcess.aadhar.progressValueForAadhar = 0;
      // for(let file of event.addedFiles){
      this.uploadFile(file, file.name, event.source.id);
      this.uploadFileProcess.aadhar.showProgressForAadhar = true;
      this.uploadFileProcess.aadhar.aadharFileName = file.name;

      // }
    }
    if (event.source.id === 'AADHAR_B') {
      let file = event.addedFiles[0];
      this.uploadFileProcess.aadhar_b.progressValueForAadhar_b = 0;
      // for(let file of event.addedFiles){
      this.uploadFile(file, file.name, event.source.id);
      this.uploadFileProcess.aadhar_b.showProgressForAadhar_b = true;
      this.uploadFileProcess.aadhar_b.aadhar_bFileName = file.name;

      // }
    }

    if (event.source.id === 'PAN') {
      let file = event.addedFiles[0];
      // for(let file of event.addedFiles){
      this.uploadFileProcess.pan.progressValueForPan = 0;
      this.uploadFile(file, file.name, event.source.id);
      this.uploadFileProcess.pan.showProgressForPan = true;
      this.uploadFileProcess.pan.panFileName = file.name;
    }
    // }
  }
  getFromDigi(_event: any) {
    _event.preventDefault();
    let data: any;

    this.OnBoardingService.intiateDigi().subscribe({
      next: (res: any) => {
        data = res;
        window.location.href = `${data.redirect_url}`;
        sessionStorage.setItem('txn_id', data.txn_id);
      }
    });
  }

  setExpDate() {
    var dt = new Date();
    return dt.setFullYear(dt.getFullYear() + 1);
  }
}
