<div #containerRef>
  <div
    class="table-con pb-20 mt-40"
    #containerRef2
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="100"
    [infiniteScrollContainer]="containerRef2"
    [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" class="mt-25">
      <ng-container matColumnDef="registration_date">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Registration Date</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.registration_date | date: 'dd MMM yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="investment_amount">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Investment Amount</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.investment_amount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="agreement_id">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Agreement ID</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.agreement_id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="product_name">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Product Name</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.product_name === 'smartbeta' ? element.product_name : element.pouch_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="view_icon">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"></th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-icon (click)="openPDF(element.agreement_file_name)" style="cursor: pointer"
            >visibility</mat-icon
          >
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [attr.colspan]="AgreementList.length">
          No Agreements Found.
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="AgreementList; sticky: true"></tr>
      <tr class="table_row-value" mat-row *matRowDef="let row; columns: AgreementList"></tr>
    </table>
  </div>
</div>
