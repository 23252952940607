import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-warning-kyc-rp-popup',
  templateUrl: './warning-kyc-rp-popup.component.html',
  styleUrls: ['./warning-kyc-rp-popup.component.scss']
})
export class WarningKycRpPopupComponent implements OnInit {
  rp: boolean;
  rp_exp: boolean;
  kyc: boolean;
  kyc_exp: boolean;
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<WarningKycRpPopupComponent>
  ) {}

  ngOnInit(): void {
    this.checkNotification();
  }
  checkNotification() {
    let userInfo = JSON.parse(localStorage.getItem('user') || '{}').attributes;

    if (userInfo?.risk_profile === 'PENDING' || userInfo?.risk_profile === '') {
      this.rp = true;
    } else {
      this.rp = false;
      this.rp_exp = this.checkExpiry(new Date(userInfo?.risk_profile_date));
    }
    if (userInfo?.kyc_date === '') {
      this.kyc = true;
    } else {
      this.kyc = false;
      this.kyc_exp = this.checkExpiry(new Date(userInfo?.kyc_date));
    }
  }

  checkExpiry(Cdate: any) {
    let quoteExpiry = new Date(Cdate.setFullYear(Cdate.getFullYear() + 1));
    let now = new Date();

    if (now.getTime() > quoteExpiry.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  go(v: any) {
    if (v === 'rp') {
      this.router.navigateByUrl('/onboarding/questionaire');
      this.dialogRef.close();
    }
    if (v === 'kyc') {
      this.router.navigateByUrl('/onboarding/kyc_upload');
      this.dialogRef.close();
    }
  }
  close(val: any) {
    this.dialogRef.close(true);
  }
}
