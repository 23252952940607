<div style="padding: 20px 20px 0; display: flex; justify-content: end">
  <img class="pointer" src="../../../../assets/images/icons/close.svg" (click)="close()" />
</div>
<div style="width: 100%; height: 85%; justify-content: center; padding: 20px">
  <iframe
    height="100%"
    width="100%"
    [src]="videoLink"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
</div>
