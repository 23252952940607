<div class="row pouch-details_banner" *ngIf="tabIndex !== 2">
  <div class="pouch-details_inner">
    <ul class="row">
      <li class="left col-sm-2 col-3 col-md-1">
        <img [src]="banner.pouch_icon" alt="" />
      </li>
      <li class="left col-sm-10 col-9 col-md-3">
        <div>
          <div>
            <span class="f-18 fw-600">{{ banner.pouch_name }}</span>
          </div>
          <!-- <div class="description_wrap">
            <span class="f-14 fw-400 text_h">{{
              banner.pouch_description || "-"
            }}</span>
          </div> -->
        </div>
      </li>
      <ng-container *ngIf="tabIndex === 0">
        <li class="col-sm-6 col-6 col-md-2">
          <div>
            <div><span class="f-12 fw-400 text_h">Universe Name</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{ banner.universe_name || '-' }}</span>
            </div>
          </div>
        </li>
        <li class="col-sm-6 col-6 col-md-2">
          <div>
            <div><span class="f-12 fw-400 text_h">Review Frequency</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{ banner.pouch_review_freq || '-' }}</span>
            </div>
          </div>
        </li>

        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Start Date</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{
                (banner.pouch_start_date | date: 'mediumDate') || '-'
              }}</span>
            </div>
          </div>
        </li>
        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Last Review</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{
                (banner.pouch_last_review_date | date: 'mediumDate') || '-'
              }}</span>
            </div>
          </div>
        </li>
        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Next Review</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{
                (banner.pouch_next_review_date | date: 'mediumDate') || '-'
              }}</span>
            </div>
          </div>
        </li>
        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Risk Profile</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{ banner.pouch_riskProfile || '-' }}</span>
            </div>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="tabIndex === 1">
        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Min Investment</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{ banner.min_investment || 100000 }}</span>
            </div>
          </div>
        </li>
        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Subscription Fees</span></div>
            <div>
              <span class="f-14 fw-400 --black3"
                >{{ banner.subsription_fee.amount * 12 | number: '0.2' || '-' }} / yr</span
              >
            </div>
          </div>
        </li>

        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Total Stocks</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{ banner.total_stocks || 0 }}</span>
            </div>
          </div>
        </li>

        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Annualised Return</span></div>
            <div>
              <span class="f-14 fw-400 --black3"
                >{{ banner.annualizized_returns || 0 | number: '0.2' }}%</span
              >
            </div>
          </div>
        </li>
        <li class="col-sm-6 col-6 col-md-1">
          <div>
            <div><span class="f-12 fw-400 text_h">Risk Profile</span></div>
            <div>
              <span class="f-14 fw-400 --black3">{{ banner.pouch_riskProfile || '-' }}</span>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="row bg-grey m-0">
    <div class="col-12 f-14 fw-400 text_h">
      {{ banner.pouch_description || '-' }}
    </div>
    <p class="fw-400 text_h mt-3">
      <strong>RATIONALE:</strong> Portfolio of stocks selected on the basis of the momentum profile
      of the universe of stocks.
    </p>
  </div>
  <!-- <p *ngIf="pouchDetail.stop_sub && pouchDetail.subscribed=='Y'" class=" fw-400 text_h mt-3" style="color: red!important; font-size: 13px;">
    <strong>Disclaimer:</strong> This pouch has been discontinued and we are no longer accepting renewals for the same.
  </p> -->
</div>
