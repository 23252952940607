import { Component, OnInit, HostListener, DoCheck } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

import { PlayVideoPopupComponent } from 'src/app/modules/shared/play-video-popup/play-video-popup.component';

import { PublicService } from '../../services/public.service';
import { PublicFacadeService } from '../../state/public.facade.services';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, DoCheck {
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: { timing: 1500, initialDelay: 1500 },
    loop: true,
    touch: true,
    velocity: 0.9
  };
  link = 'landing';
  isLeftClick = false;
  isRightClick = false;
  uploadDate: any;
  screenWidth: any;
  isMobile: boolean = false;
  isPouch: boolean = false;
  publicPouchList: any;
  annualizedReturns: any;
  benchMarkValues: any;
  constructor(
    private router: Router,
    private publicService: PublicService,
    private publicFacadeService: PublicFacadeService,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer
  ) {
    this.onWindowResize();
    if (this.router.url === '/public/terms-and-conditions') {
      this.link = 'terms';
    } else if (this.router.url === '/public/privacy-policy') {
      this.link = 'policy';
    } else {
      this.link = 'landing';
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }
  fetchBenchmarkIndexes() {
    this.publicService.fetchPublicBenchMarkReturns().subscribe({
      next: (response: any) => {
        this.benchMarkValues = response.Results;

      }
    });
  }
  openVideo() {
    this.dialog.open(PlayVideoPopupComponent, {
      width: '800px',
      minHeight: '460px',
      height: '100%',
      maxHeight: '460px',
      panelClass: 'videoPopupContainer'
    });
  }

  videoLinks = [
    {
      link: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/nKFIeMw1Eqs'
      ),
      discription: 'Registration'
    },

    {
      link: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/iBLkEldjdgU'
      ),
      discription: 'Pouch Subscription'
    },
    {
      link: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/GiSYIj4Jomw'
      ),
      discription: 'Smart Stocks'
    }
  ];

  ngOnInit(): void {
    if (this.link === 'landing') {
      localStorage.clear();
    }

    // this.publicFacadeService.setUploadDateToStore();
    // this.publicFacadeService.getUploadDateState$.subscribe((date: any) => {
    //   localStorage.setItem('stock_price_uploaded_date', date);
    //   this.uploadDate = localStorage
    //     .getItem('stock_price_uploaded_date')
    //     ?.split('T')[0];
    //   if (date) {
    //     // this.loadPouchDetails();
    //     // this.fetchBenchmarkIndexes();
    //   }

    // });
    //
  }

  ngDoCheck(): void {
    if (this.screenWidth <= 769) {
      this.isMobile = true;
      this.isPouch = true;
    } else {
      this.isMobile = false;
    }
  }

  loadPouchDetails() {
    if (this.uploadDate) {
      // this.publicFacadeService.getAnnualizedfromStore({
      //   current_date: this.uploadDate,
      // });
      this.publicFacadeService.getPouchListfromStore({
        current_date: this.uploadDate,
        limit: 100,
        sort: -18
      });

      // this.publicFacadeService.LoadPouchListfromState$.subscribe(
      //   (list: any) => {
      //     this.publicPouchList = list?[...list]:[];

      //
      //   }
      // );
      this.publicFacadeService.LoadAnnualizedfromState$.subscribe((returns: any) => {
        this.annualizedReturns = returns;
      });
    }
  }
  // getAnnualizedReturns() {
  //   this.publicService
  //     .fetchIndexAnnualizedReturns(this.uploadDate)
  //     .subscribe((res: any) => {
  //       this.annualizedReturns = res[0].Results;
  //     });
  // }

  getAnnualizedValue(element: any) {
    let returns = this.benchMarkValues?.find((ele: any) => {
      return ele.pouch_id == element.id;
    });
    return (Number(returns?.index_returns || 0) * 100).toFixed(2);
  }
  viewPouchListAll() {
    this.router.navigateByUrl('/public/pouchlist');
  }

  slide() {
    this.isLeftClick = true;
  }

  slide1() {
    this.isLeftClick = false;
  }
  navigateToPerformance(id: any) {
    this.router.navigateByUrl(`public/pouchlist-details/${id}`);
  }
  clickOnRegister() {
    //  this.publicServices.isPublic = true;
    this.router.navigate(['/auth/register']);
  }
  transformTwoDigit(val: any) {
    return (Number(val) * 100).toFixed(2);
  }

  getRound(value: any) {
    return Math.round(value) || 0;
  }
  isNegative(value: any) {
    if (value < 0) {
      return true;
    } else {
      return false;
    }
  }
  convertPositive(val: any) {
    return Math.abs(val);
  }
}
