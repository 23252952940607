import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs/tab-group';
import { Router, ActivatedRoute } from '@angular/router';

import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';

@Component({
  selector: 'app-create-pouch',
  templateUrl: './create-pouch.component.html',
  styleUrls: ['./create-pouch.component.scss']
})
export class CreatePouchComponent implements OnInit {
  selected: number = 0;
  pouchId: any;
  isEdit: any;
  isDisabled: boolean;
  createPouch: any = 'createPouch';
  startDate: any;
  minInvestment: number = 100000;
  since = new Date(localStorage.getItem('stock_price_uploaded_date') || '');
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isEdit = this.route.snapshot.paramMap.get('pouchId') ? true : false;
    this.pouchId = this.route.snapshot.paramMap.get('pouchId');
    if (this.pouchId) {
      this.isDisabled = true;
      this.selected = 3;
    } else {
      this.isDisabled = false;
    }
  }

  tabChanged(event: any): void {
    this.selected = event;
  }

  changeTab(index: any) {
    
    this.pouchId = index.pouchId;
    this.startDate = index.startDate;
    this.minInvestment = index.min_investment || 100000;
    this.selected = index.tabId;
  }
  // openDialog() {
  //   const dialogRef = this.dialog.open(CommonDialogComponent);
  // }
}
