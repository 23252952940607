import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  AfterViewInit,
  ViewChildren,
  QueryList
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { SharedService } from 'src/app/common/services/shared.service';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { NotificationService } from 'src/app/services/notification.services';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { environment } from 'src/environments/environment';

import { HowCreatedComponent } from '../how-created/how-created.component';
import { ReviewHistoryComponent } from '../review-history/review-history.component';
import { UpdateTransactionPopupComponent } from '../update-transaction-popup/update-transaction-popup.component';
import { ConfirmDeletePopupComponent } from './confirm-delete-popup/confirm-delete-popup.component';

@Component({
  selector: 'app-pouch-composition',
  templateUrl: './pouch-composition.component.html',
  styleUrls: ['./pouch-composition.component.scss']
})
export class PouchCompositionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() radioSelected: any;
  @Input() agreementStatus: any={};
  @Input() showAmountOnSubscriber: boolean;
  @Input() subscriptionStatus:any;
  @Input() subList: any = [];
  @Input() pouchExpired: any = false;
  public myMath = Math;
  totalReturns: any = 0;
  minimum_investment: number = 0;
  isHowCreated: boolean = false;
  isReviewHistory: boolean = false;
  isVisible: any;
  isSelected: boolean;
  showPending = false;
  environment = environment;
  showingSince: any[] = [
    { name: 'Last Review', value: 'last_review' },
    { name: 'Review', value: 'review' }
  ];

  uploadedDate: any;
  @Input() since: any;
  sliderIntial = false;
  sliderDisabled = true;
  sellStocks: any[] = [];
  holdStocks: any[] = [];
  totalSoldPL: any = 0;
  totalHoldPL: any = 0;
  totalHoldPLAmnt: any = 0;
  totalSoldPLAmnt: any = 0;
  pouchReviewFreq: any;
  fundReturns: any = 0;
  userInfo: any;
  @Input() parent: any;
  @Input() userType: any;
  @Input() public: any;
  @Input() pouchId: any;
  @Input() pouchDetail: any;
  @Output() changeTabEvent = new EventEmitter();
  @Output() setMinInvestment = new EventEmitter();
  @Output() bannerChangeEvent = new EventEmitter();
  @Output() radioChange = new EventEmitter();
  sortedDateArray: never[];
  selectedSubscriber: any;
  subscriberList: any = [];
  stock_price_date: any;
  pendingStocks: any = [];
  @Output() holdStockLength = new EventEmitter();
  displayedColumns: any[] = [
    'buy_date',
    'buy_rate',
    'sell_date',
    'sell_rate',
    'cr_wt',
    'profit-loss_amnt',
    'profit-loss_perc'
  ];
  displayedColumns1: any[] = [
    'sr',
    'stock_name',
    'stock_wt',
    'buy_date',
    'buy_rate',
    'sell_date',
    'sell_rate',
    'cr_wt',
    // 'price_change_realized',
    'profit-loss_amnt',
    'profit-loss_perc',
    'action'
  ];
  displayedColumns1_old: any[] = [
    'sr',
    'stock_name',
    'stock_wt',
    'buy_date',
    'buy_rate',
    'sell_date',
    'sell_rate',
    // 'price_change_realized',
    'profit-loss_amnt',
    'profit-loss_perc',
    'action'
  ];
  displayedColumns_old: any[] = [
    'buy_date',
    'buy_rate',
    'sell_date',
    'sell_rate',
    'profit-loss_amnt',
    'profit-loss_perc'
  ];

  displayedColumns3: any[] = [
    'sr',
    'stock_name',
    'quantity',
    'Proposed_Date',
    'transaction_type',
    'transaction_status',
    'action'
  ];

  public graphData: any[] = [];
  chartpouchDetails: any[] = [this.sellStocks, this.holdStocks];
  userData: any;
  user_Subscribed_notInvested: boolean = false;
  currentInvestment: number = 0;
  @ViewChildren('holdStocksTable') holdStocksTable: QueryList<any>;
  radioSelectedSubscriber: any = 0;
  constructor(
    public dialog: MatDialog,
    private pouchService: PouchService,
    private subscriberService: SubsciberService,
    private sharedService: SharedService,
    private notifyService: NotificationService
  ) {
    let user: any = localStorage.getItem('user');
    this.userInfo = JSON.parse(user)?.attributes.role;
  }
  ngAfterViewInit(): void {}
  HoldStock(e: any) {
    this.subscriberService.holdStockLength = e;
  }
  showHowCreated() {
    // this.isHowCreated = !this.dialog.open(HowCreatedComponent);
    const dialogRef = this.dialog.open(HowCreatedComponent);
  }

  showReviewHistory() {
    // this.isHowCreated = !this.dialog.open(HowCreatedComponent);
    const dialogRef = this.dialog.open(ReviewHistoryComponent);
  }
  ngOnChanges(current: any) {


    let change = current['radioSelected'];
    if (change?.currentValue == 0) {
      this.displayedColumns1_old = [
        'sr',
        'stock_name',
        'stock_wt',
        'buy_date',
        'buy_rate',
        'sell_date',
        'sell_rate',
        // 'price_change_realized',
        'profit-loss_amnt',
        'profit-loss_perc',
        'action'
      ];
      this.displayedColumns1 = [
        'sr',
        'stock_name',
        'stock_wt',
        'buy_date',
        'buy_rate',
        'sell_date',
        'sell_rate',
        'cr_wt',
        // 'price_change_realized',
        'profit-loss_amnt',
        'profit-loss_perc',
        'action'
      ];
    } else {
      this.displayedColumns1_old = [
        'sr',
        'stock_name',
        'stock_wt',
        'buy_date',
        'buy_rate',
        'sell_date',
        'sell_rate',
        // 'price_change_realized',
        'profit-loss_amnt',
        'profit-loss_perc'
      ];
      this.displayedColumns1 = [
        'sr',
        'stock_name',
        'stock_wt',
        'buy_date',
        'buy_rate',
        'sell_date',
        'sell_rate',
        'cr_wt',
        // 'price_change_realized',
        'profit-loss_amnt',
        'profit-loss_perc'
      ];
    }

    if (current['parent']?.currentValue === '"createPouch"') {
      this.radioSelected = 1;
      this.displayedColumns1_old = [
        'sr',
        'stock_name',
        'stock_wt',
        'buy_date',
        'buy_rate',
        'sell_date',
        'sell_rate',
        // 'price_change_realized',
        'profit-loss_amnt',
        'profit-loss_perc'
      ];
      this.displayedColumns1 = [
        'sr',
        'stock_name',
        'stock_wt',
        'buy_date',
        'buy_rate',
        'sell_date',
        'sell_rate',
        'cr_wt',
        // 'price_change_realized',
        'profit-loss_amnt',
        'profit-loss_perc'
      ];
    }
    this.holdStockLength.emit(this.holdStocks.length);
  }
  domainName:string
  ngOnInit(): void {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    this.domainName = subdomain

    this.userData = JSON.parse(localStorage.getItem('user') || '{}');
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    // this.since = new Date(this.uploadedDate).toISOString().split('T')[0];
    let data = {
      pouch_id: this.pouchId,
      stock_price_date: moment(new Date(this.since)).format('YYYY-MM-DD')
    };
    // if (
    //   this.userType === 'admin' ||
    //   this.userType === 'ria' ||
    //   this.userType === 'partner'
    // ) {
    //   if (this.pouchId) {
    //     this.getPouchreturns(data);
    //   }
    // }

    if (this.userType === 'subscriber') {
      if (this.radioSelected === 0) {
        this.getSubscriberReturns();
      } else {
        if (this.pouchId) {
          this.getPouchreturns(data);
        }
      }
    } else {
      if (this.radioSelected === 0) {
        this.selectedSubscriber = this.pouchService.selectedSubscriber;
        if (this.pouchId) {
          // this.getPouchreturns(data);
          this.getSubscriberReturns();
        }
      } else {
        if (this.pouchId) {
          this.getPouchreturns(data);
        }
      }
    }
    if (this.pouchDetail) {
      this.pouchReviewFreq = `${this.pouchDetail.review_freq} (${this.pouchDetail.review_freq_day})`;
    }
    //

    this.subList.forEach((item: any) => {
      this.radioSelectedSubscriber = item.active_flag === 'ACTIVE' ? 0 : 1;
    });
  }
  getPouchreturns(data: any) {
    this.totalHoldPL = 0;
    this.totalSoldPL = 0;
    this.totalReturns = 0;
    this.totalHoldPLAmnt = 0;
    this.totalSoldPLAmnt = 0;
    this.fundReturns = 0;
    this.currentInvestment = 0;
    this.subscriberList = [];
    const pouchName = this.pouchDetail ? this.pouchDetail.heading_text : 'Pouch';
    // this.subscriberList.unshift({ name: pouchName });
    this.selectedSubscriber = pouchName;
    this.pouchService.getPouchStocksReturns(data).subscribe((res: any) => {
      let resArr: any = res[0].Results[0].return_data.pouch_stocks_returns;

      this.arrangeData(resArr);
      this.totalHoldPL = (
        res[0].Results[0].return_data?.pouch_returns[0].unrealized_return_perc * 100
      )?.toFixed(2);
      this.totalSoldPL = (
        res[0].Results[0].return_data?.pouch_returns[0].realized_return_perc * 100
      )?.toFixed(2);
      this.totalHoldPLAmnt =
        res[0].Results[0].return_data?.pouch_returns[0].unrealized_returns?.toFixed(2);
      this.totalSoldPLAmnt = (
        res[0].Results[0].return_data?.pouch_returns[0].realized_returns || 0
      )?.toFixed(2);
      this.fundReturns = (
        res[0].Results[0].return_data?.pouch_returns[0].total_return_perc * 100
      )?.toFixed(2);
      this.totalReturns =
        res[0].Results[0].return_data?.pouch_returns[0]?.total_returns?.toFixed(2);
      this.currentInvestment = res[0].Results[0].return_data?.pouch_returns[0]?.current_investment;
      this.graphData = resArr;
      this.minimum_investment = res[0].Results[0].return_data.pouch_returns[0]?.min_amt;
    });
  }
  getSubscriberReturns(dialog?: any) {
    this.totalHoldPL = 0;
    this.totalHoldPLAmnt = 0;
    this.totalSoldPLAmnt = 0;
    this.totalReturns = 0;
    this.totalSoldPL = 0;
    this.fundReturns = 0;
    this.currentInvestment = 0;
    let subscriber: any;
    if (this.userType === 'subscriber') {
      subscriber = this.userData.id;
    } else {
      if (this.pouchService.selectedSubscriber) {
        subscriber = this.pouchService.selectedSubscriber;
      } else {
        let subscribersearch = this.subList.find(
          (x: any) => x.subscriber_id == this.selectedSubscriber
        );
        subscriber = subscribersearch?.id;
      }
    }
    if (this.userType !== 'subscriber') {
      if (!subscriber) {
        subscriber = this.subList?.[0]?.subscriber_id;
      }
    }
    let data = {
      pouch_id: Number(this.pouchId),
      subscriber_id: subscriber,
      stock_price_date: moment(new Date(this.since)).format('YYYY-MM-DD')
    };
    let subscriberPayload: any = {
      limit: 10,
      stock_price_date: moment(new Date(this.since)).format('YYYY-MM-DD'),
      pouch_id: this.pouchDetail ? this.pouchDetail.id : Number(this.pouchId),
      subscriber_id: subscriber
    };
    this.pouchService.getPouchSubscriberStocksReturns(data).subscribe(
      (res: any) => {
        let resArr = res[0].pouch_subscriber_stocks_returns;
        this.calculateMinimumInvestment(resArr);

        this.arrangeDataSubscriber(resArr);

        this.pendingStocks = new MatTableDataSource(res[1]?.pouch_subscriber_trades || []);
        if (dialog) {
          dialog.close();
        }
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );
    this.subscriberService.getPouchSubscriberReturns(subscriberPayload).subscribe(
      (res: any) => {
        if (res && res[0].pouch_subscribers) {
          this.subscriberList = [];
          res[0].pouch_subscribers.map((element: any) => {
            if (element['is_active'] == true) {
              this.subscriberList.push({
                id: element['subscriber_id'],
                name: `${element['subscriber_first_name']} ${element['subscriber__last_name']}`
              });
            }
          });
        }
        const pouchName = this.pouchDetail ? this.pouchDetail.heading_text : 'Pouch';
        // this.subscriberList.unshift({ name: pouchName });
        this.selectedSubscriber = this.pouchService.selectedSubscriber
          ? this.pouchService.selectedSubscriber
          : this.subscriberList[0]?.id;
        this.totalHoldPL = (
          res?.[0]?.pouch_subscriber_returns?.[0]?.unrealized_return_perc * 100
        )?.toFixed(2);
        this.totalHoldPLAmnt =
          res?.[0]?.pouch_subscriber_returns?.[0]?.unrealized_returns?.toFixed(2);
        this.totalSoldPLAmnt =
          res?.[0]?.pouch_subscriber_returns?.[0]?.realized_returns?.toFixed(2);
        this.totalSoldPL = (
          res?.[0]?.pouch_subscriber_returns?.[0]?.realized_return_perc * 100
        )?.toFixed(2);
        this.fundReturns = (
          res?.[0]?.pouch_subscriber_returns?.[0]?.total_return_perc * 100
        )?.toFixed(2);

        this.currentInvestment = res?.[0]?.pouch_subscriber_returns?.[0]?.current_investment;
        try{
          const investmentAmount = res?.[0]?.pouch_subscriber_returns?.[0]?.final_investment_amount || 0;
          const totalReturns = res?.[0]?.pouch_subscriber_returns?.[0]?.total_returns || 0;
          if(Object.keys(res[0]).length ==0){
            throw 'go error'
          }
          this.totalReturns = (investmentAmount + totalReturns).toFixed(2);
        }catch (e){
  try{
    if(!this.subscriptionStatus?.[0]){
      throw 'go error'
    }
    this.totalReturns =Number(this.subscriptionStatus?.[0]?.investment_amount)?.toFixed(2)
  }catch (e){
    this.totalReturns='0.00';
  }

        }




      },
      (e) => {
        this.notifyService.showError('', e.error?.error?.message);
      }
    );

    // if (
    //   this.uploadedDate.split('T')[0] !=
    //   new Date(this.since).toISOString().split('T')[0]
    // ) {
    //   this.since.setDate(new Date(dateSince).getDate() - 1);
    // }
  }

  fetchData(data: any) {}

  arrangeData(resArr: any) {
    this.sellStocks = [];
    this.holdStocks = [];
    this.totalSoldPL = 0;
    this.totalHoldPL = 0;
    this.sortedDateArray = [];

    const newHoldArray = resArr?.sort((prev: any, next: any) => {
      return new Date(prev.buy_date).getTime() - new Date(next.buy_date).getTime();
    });
    const newSellArray = resArr?.sort((prev: any, next: any) => {
      return new Date(prev.sell_date).getTime() - new Date(next.sell_date).getTime();
    });

    newHoldArray?.map((element: any) => {
      if (element['stock_wt'] > 0) {
        this.holdStocks.push({
          buy_date: element['buy_date'],
          min_amt: element['min_amt'],
          min_qty: element['min_qty'],
          buy_rate: element['buy_rate'] ? element['buy_rate']?.toFixed(2) : 0,
          cagr: element['cagr'],
          close_price: element['close_price'] ? element['close_price']?.toFixed(2) : 0,
          investment_amount: element['investment_amount'],
          no_of_days: element['no_of_days'],
          pouch_id: element['pouch_id'],
          pouch_stocks_return_id: element['pouch_stocks_return_id'],
          price_change_realized: (element['price_change_realized'] * 100)?.toFixed(2),
          price_change_unrealized: (element['price_change_unrealized'] * 100)?.toFixed(2),
          realized_returns: element['realized_returns'],
          sell_date: element['sell_date'],
          sell_rate: element['sell_rate'],
          stock_code: element['stock_code'],
          stock_name: element['stock_name'],
          stock_series: element['stock_series'],
          stock_wt: Math.round(element['stock_wt'] * 100),
          unrealized_returns: element['unrealized_returns'],
          current_wt: this.getWeightagePouch(element, newHoldArray),
          sp_date: element['sp_date']
        });
      }
    });

    newSellArray?.map((element: any) => {
      if (element['stock_wt'] < 0) {
        this.sellStocks.push({
          buy_date: element['buy_date'],
          min_amt: element['min_amt'],
          min_qty: element['min_qty'],
          buy_rate: element['buy_rate'] ? element['buy_rate']?.toFixed(2) : 0,
          cagr: element['cagr'],
          close_price: element['close_price'] ? element['close_price']?.toFixed(2) : 0,
          investment_amount: element['investment_amount'],
          no_of_days: element['no_of_days'],
          pouch_id: element['pouch_id'],
          pouch_stocks_return_id: element['pouch_stocks_return_id'],
          price_change_realized: (element['price_change_realized'] * 100)?.toFixed(2),
          price_change_unrealized: (element['price_change_unrealized'] * 100)?.toFixed(2),
          realized_returns: element['realized_returns'],
          sell_date: element['sell_date'],
          sell_rate: element['sell_rate'] ? element['sell_rate']?.toFixed(2) : 0,
          stock_code: element['stock_code'],
          stock_name: element['stock_name'],
          stock_series: element['stock_series'],
          stock_wt: Math.round(element['stock_wt'] * 100),
          unrealized_returns: element['unrealized_returns'],
          sp_date: element['sp_date']
        });
      }
    });
  }
  arrangeDataSubscriber(resArr: any) {
    this.sellStocks = [];
    this.holdStocks = [];
    this.sortedDateArray = [];

    const newHoldArray = resArr?.sort((prev: any, next: any) => {
      return new Date(prev.buy_date).getTime() - new Date(next.buy_date).getTime();
    });

    const newSellArray = resArr?.sort((prev: any, next: any) => {
      return new Date(prev.sell_date).getTime() - new Date(next.sell_date).getTime();
    });

    newHoldArray?.map((element: any) => {
      if (element['stock_qty'] > 0) {
        this.holdStocks.push({
          buy_date: element['buy_date'],
          buy_rate: element['buy_rate'] ? element['buy_rate']?.toFixed(2) : 0,
          cagr: element['cagr'],
          close_price: element['close_price'] ? element['close_price']?.toFixed(2) : 0,
          investment_amount: element['investment_amount'],
          no_of_days: element['no_of_days'],
          pouch_id: element['pouch_id'],
          pouch_stocks_return_id: element['pouch_stocks_return_id'],
          price_change_realized: (element['price_change_realized'] * 100)?.toFixed(2),
          price_change_unrealized: (element['price_change_unrealized'] * 100)?.toFixed(2),
          realized_returns: element['realized_returns'],
          sell_date: element['sell_date'],
          fact_date: element['fact_date'],
          sell_rate: element['sell_rate'],
          stock_code: element['stock_code'],
          stock_name: element['stock_name'],
          stock_series: element['stock_series'],
          stock_wt: Math.round(element['stock_wt'] * 100),
          stock_qty: element['stock_qty'],
          min_amt: element['min_amt'],
          min_qty: element['min_qty'],
          unrealized_returns: element['unrealized_returns'],
          unrealized_returns_amnt: element['unrealized_returns'],
          current_wt: this.getWeightageSub(element, newHoldArray),
          id: element['id'],
          subscriber_id: element['subscriber_id'],
          txn_id: element['txn_id'],
          partial_sale: element['partial_sale'],
          rk: element['rk']
        });
      }
    });

    newSellArray?.map((element: any) => {
      if (element['stock_qty'] < 0) {
        this.sellStocks.push({
          buy_date: element['buy_date'],
          buy_rate: element['buy_rate'] ? element['buy_rate']?.toFixed(2) : 0,
          cagr: element['cagr'],
          close_price: element['close_price'] ? element['close_price']?.toFixed(2) : 0,
          investment_amount: element['investment_amount'],
          no_of_days: element['no_of_days'],
          pouch_id: element['pouch_id'],
          pouch_stocks_return_id: element['pouch_stocks_return_id'],
          price_change_realized: (element['price_change_realized'] * 100)?.toFixed(2),
          price_change_unrealized: (element['price_change_unrealized'] * 100)?.toFixed(2),
          realized_returns: element['realized_returns'],
          sell_date: element['sell_date'],
          sell_rate: element['sell_rate'] ? element['sell_rate']?.toFixed(2) : 0,
          stock_code: element['stock_code'],
          stock_name: element['stock_name'],
          stock_series: element['stock_series'],
          stock_wt: Math.round(element['stock_wt'] * 100),
          stock_qty: element['stock_qty'],
          min_amt: element['min_amt'],
          min_qty: element['min_qty'],
          unrealized_returns: element['unrealized_returns'],
          id: element['id'],
          subscriber_id: element['subscriber_id'],
          txn_id: element['txn_id'],
          partial_sale: element['partial_sale'],
          fact_date: element['fact_date'],
          rk: element['rk']
        });
      }
    });

    // if (this.sellStocks && this.sellStocks.length > 0) {
    //   this.sellStocks = this.sellStocks.sort((x: any) => x.buy_date);
    //   this.totalSoldPL = this.sellStocks.reduce(function (prev: any, cur: any) {
    //     return prev + Number(cur.realized_returns);
    //   }, 0);
    //   this.totalSoldPL =
    //     (this.totalSoldPL / Number(this.pouchDetail.min_investment_number)) *
    //     100;
    //   this.totalSoldPL = this.totalSoldPL?.toFixed(2);
    // }
    // if (this.holdStocks && this.holdStocks.length > 0) {
    //   this.holdStocks = this.holdStocks.sort((x: any) => x.buy_date);
    //   this.totalHoldPL = this.holdStocks.reduce(function (prev: any, cur: any) {
    //     return prev + Number(cur.unrealized_returns);
    //   }, 0);
    //   this.totalHoldPL =
    //     (this.totalHoldPL / Number(this.pouchDetail.min_investment_number)) *
    //     100;
    //   this.totalHoldPL = this.totalHoldPL?.toFixed(2);
    // }

    // if (this.pouchDetail) {
    //   this.fundReturns = (
    //     Number(this.totalSoldPL) + Number(this.totalHoldPL)
    //   )?.toFixed(2);
    // }
  }

  sinceDateChangeHandler(event: any) {
    this.since = moment(new Date(event.value)).format('YYYY-MM-DD');
    if (this.userType !== 'subscriber') {
      if (this.pouchId) {
        let dateSince = event.value;
        // dateSince = dateSince.setDate(dateSince.getDate() + 1);
        // this.stock_price_date = new Date(
        //   event.value.getFullYear(),
        //   event.value.getMonth(),
        //   event.value.getDate(),
        //   event.value.getHours(),
        //   event.value.getMinutes() - event.value.getTimezoneOffset()
        // )
        //   .toISOString()
        //   .split('T')[0];
        this.stock_price_date = moment(new Date(event.value)).format('YYYY-MM-DD');
        let data = {
          pouch_id: this.pouchId,
          stock_price_date: this.stock_price_date
        };
        if (this.radioSelected === 0) {
          this.getSubscriberReturns();
        } else {
          this.getPouchreturns(data);
        }
        // this.getPouchreturns(data);

        // this.since.setDate(new Date(dateSince).getDate() - 1);
      }
    }
    if (this.userType === 'subscriber') {
      if (this.radioSelected === 0) {
        this.since = event.value;
        this.getSubscriberReturns();
      }
      if (this.radioSelected === 1) {
        // let dateSince = event.value;
        // dateSince = dateSince.setDate(dateSince.getDate() + 1);
        this.stock_price_date = moment(new Date(event.value)).format('YYYY-MM-DD');
        let data = {
          pouch_id: this.pouchId,
          stock_price_date: this.stock_price_date
        };
        this.getPouchreturns(data);

        // this.since.setDate(new Date(dateSince).getDate() - 1);
      }
    }
    //this.since = this.since.setDate(this.since.getDate() - 1)
  }

  fetchPouchStocksReturns() {
    let data = {
      pouch_id: this.pouchId,
      stock_price_date: this.uploadedDate.split('T')[0]
    };
    this.pouchService.getPouchStocksReturns(data).subscribe((res: any) => {});
  }

  transformtValue(value: any) {
    return Math.abs(value);
  }

  transformtPercentValue(value: any) {
    return Math.abs(value)?.toFixed(2);
  }

  formatToCommaSeperated(value: any) {
    return value.toLocaleString('en-IN', {
      useGrouping: true
    });
  }

  onChangeSelection() {
    let pouchName = this.pouchDetail ? this.pouchDetail.heading_text : 'Pouch';
    if (this.selectedSubscriber == pouchName) {
      if (this.pouchId) {
        let data = {
          pouch_id: this.pouchId,
          stock_price_date: new Date(
            this.since.getFullYear(),
            this.since.getMonth(),
            this.since.getDate(),
            this.since.getHours(),
            this.since.getMinutes() - this.since.getTimezoneOffset()
          )
        };
        this.getPouchreturns(data);
      }
    } else {
      this.getSubscriberReturns();
    }
  }

  onNextClick(event: any) {
    this.changeTabEvent.emit({
      tabId: '3',
      pouchId: this.pouchId,
      min_investment: this.minimum_investment
    });
  }

  calculateMinimumInvestment(returns: any) {
    let sumArr = [];
    if (returns) {
      for (let data of returns) {
        if (data.min_amt) {
          sumArr.push(data.min_amt);
        }
      }
    }

    this.minimum_investment = Number(sumArr.reduce((a, b) => a + b, 0));
    if (['admin', 'ria', 'partner'].includes(this.userData.attributes.role)) {
      this.setMinInvestment.emit(this.minimum_investment?.toFixed(2));
    }
  }
  updateTransaction(id: any) {
    const dialog = this.dialog.open(UpdateTransactionPopupComponent, {
      width: '949px',
      height: ' 307px',
      data: id
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.fetchPouchStocksReturns();
        this.getSubscriberReturns();
      }
    });
  }

  valueChange(e: any) {
    if (e.value === 0) {
      this.getSubscriberReturns();
    } else {

      // let dateSince = new Date(
      //   this.since.getFullYear(),
      //   this.since.getMonth(),
      //   this.since.getDate(),
      //   this.since.getHours(),
      //   this.since.getMinutes() - this.since.getTimezoneOffset()
      // );
      // let dateSince = moment(this.since)

      // if (
      //   this.uploadedDate.split('T')[0] !=
      //   new Date(this.since).toISOString().split('T')[0]
      // ) {
      //   dateSince = dateSince.setDate(dateSince.getDate() + 1);
      // }

      let data = {
        pouch_id: Number(this.pouchId),

        stock_price_date: moment(this.since).format('YYYY-MM-DD')
      };

      this.getPouchreturns(data);
      // if (
      //   this.uploadedDate.split('T')[0] !=
      //   new Date(this.since).toISOString().split('T')[0]
      // ) {
      //   this.since.setDate(new Date(dateSince).getDate() - 1);
      // }
    }
  }

  callback(status: any) {
    if (status === 'success') {
    }
  }
  buttonDisable() {
    return true;
  }
  fetchIndiviualSub() {
    this.pouchService.selectedSubscriber = this.selectedSubscriber;
    this.getSubscriberReturns();
  }
  ngOndestroy(): void {
    this.pouchService.selectedSubscriber = '';
  }
  getWeightagePouch(ele: any, allData: any) {
    let newArr = allData.filter((ele: any) => ele['stock_wt'] > 0);
    let total = 0;
    for (let n of newArr) {
      total =
        (1 + (n['price_change_unrealized'] * 100) / 100) *
          ((100000 * (n['stock_wt'] * 100)) / 100) +
        total;
    }
    return (
      (((1 + (ele['price_change_unrealized'] * 100) / 100) *
        ((100000 * (ele['stock_wt'] * 100)) / 100)) /
        total) *
      100
    )?.toFixed(2);
  }
  getWeightageSub(ele: any, allData: any) {
    let newArr = allData.filter((ele: any) => ele['stock_qty'] > 0);
    let total = 0;
    for (let n of newArr) {
      total = n['stock_qty'] * 100 * n['close_price'] + total;
    }

    return (((ele['stock_qty'] * 100 * ele['close_price']) / total) * 100)?.toFixed(2);
  }

  deleteStock(e: any, type: any) {
    // "pouch_id": "1030",
    // "txn_id": "46298a45-5b75-4813-b202-64bc3761b791",
    // "subscriber_id": "a9f853bf-2113-4b17-9304-72135f2ff12f",
    // "stock_price_date":"2023-05-17",
    // "id":605,
    // "stock_code":"DBREALTY",
    // "stock_series":"EQ",
    // "stock_qty":-50
    let popup = this.dialog.open(ConfirmDeletePopupComponent);
    popup.afterClosed().subscribe((res: any) => {
      if (res === 'yes') {
        let user: any = localStorage.getItem('user');
        let userInfo = JSON.parse(user)?.attributes.role;
        let subId = '';

        if (userInfo === 'subscriber') {
          subId = JSON.parse(user).id;
        } else {
          subId = e.subscriber_id;
        }
        if (type == 'hold') {
          let payload = {
            pouch_id: e['pouch_id'],
            txn_id: e['txn_id'],
            stock_price_date: e['fact_date']?.split('T')[0],
            id: e['id'],
            stock_code: e['stock_code'],
            stock_series: e['stock_series'],
            stock_qty: Math.abs(Number(e['stock_qty'])),
            subscriber_id: subId
          };
          this.deleteTxn(payload);

        }
        if (type == 'sell') {
          let payload: any = {
            pouch_id: e['pouch_id'],
            txn_id: e['txn_id'],
            stock_price_date: e['fact_date']?.split('T')[0],
            id: e['id'],
            stock_code: e['stock_code'],
            stock_series: e['stock_series'],
            stock_qty: -Math.abs(Number(e['stock_qty'])),
            subscriber_id: subId
          };


          this.deleteTxn(payload);
        }
      }
    });
  }
  deleteTxn(payload: any) {

    this.pouchService.deleteTxn(payload).subscribe({
      next: (res) => {

        this.getSubscriberReturns();
      }
    });
  }
  refreshPendingTable() {
    let subscriber: any;
    if (this.userType === 'subscriber') {
      subscriber = this.userData.id;
    } else {
      if (this.pouchService.selectedSubscriber) {
        subscriber = this.pouchService.selectedSubscriber;
      } else {
        let subscribersearch = this.subList.find(
          (x: any) => x.subscriber_id == this.selectedSubscriber
        );
        subscriber = subscribersearch?.id;
      }
    }
    if (this.userType !== 'subscriber') {
      if (!subscriber) {
        subscriber = this.subList[0].subscriber_id;
      }
    }
    let data = {
      pouch_id: Number(this.pouchId),
      subscriber_id: subscriber,
      stock_price_date: moment(new Date(this.since)).format('YYYY-MM-DD')
    };
    this.pouchService.getPouchSubscriberStocksReturns(data).subscribe(
      (res: any) => {
        let resArr = res[0].pouch_subscriber_stocks_returns;
        this.calculateMinimumInvestment(resArr);
        this.arrangeDataSubscriber(resArr);

        this.pendingStocks = new MatTableDataSource(res[1]?.pouch_subscriber_trades || []);
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );
  }
  valueChangeSubscriber(event: any) {
    if (this.radioSelectedSubscriber !== undefined) {
      this.radioChange.emit(this.radioSelectedSubscriber === 1 ? 'N' : 'Y');
    }
  }
  checkStatus(){
    let subscriber: any;
    if (this.userType === 'subscriber') {
      subscriber = this.userData.id;
    } else {
      if (this.pouchService.selectedSubscriber) {
        subscriber = this.pouchService.selectedSubscriber;
      } else {
        let subscribersearch = this.subList.find(
          (x: any) => x.subscriber_id == this.selectedSubscriber
        );
        subscriber = subscribersearch?.id;
      }
    }
    if (this.userType !== 'subscriber') {
      if (!subscriber) {
        subscriber = this.subList[0].subscriber_id;
      }
    }
    let body ={
      "pouch_id":Number(this.pouchId),
      "subscriber_id":subscriber,
      from_date: new Date(
        new Date(
          localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
        ).setDate(
          new Date(
            localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
          ).getDate() - 7
        )
      )
        .toISOString()
        .split('T')[0],
      to_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
    }
    if (this.domainName == 'wealthy') {
      this.pouchService.getOrderDetails(body).subscribe((res: any) => {
        this.getSubscriberReturns()
         this.refreshPendingTable()
      }, (e) => {
           this.notifyService.showError('', e.error.error.message);
      });
    }
    if (this.domainName == 'bajajfinservsecurities') {
      this.pouchService.getOrderDetailsBajaj(body).subscribe((res: any) => {
        this.getSubscriberReturns()
         this.refreshPendingTable()
      }, (e) => {
           this.notifyService.showError('', e.error.error.message);
      });
    }

  }
  partnerList=['wealthy','bajajfinservsecurities']
}
