<!-- <div class="opt-wrapper">
    <div class="f-18 fw-600 text-center">
        Please enter the 4 digit OTP we have sent on your<br /> mobile and email.        
    </div>
    <div>
        <p>Mobile OTP</p>
        <div class="text-center d-flex">
            <ng-otp-input (onInputChange)="onMobileOtpChange($event)"  [config]="{length:4}"></ng-otp-input> 
            <p>Resend</p>
        </div>        
    </div>
    <div class="mt-30">
        <p>Email OTP</p>
        <div class="text-center">
            <ng-otp-input  (onInputChange)="onEmailOtpChange($event)"[config]="{length:4}"></ng-otp-input> 
            <p>Resend</p>
        </div>        
    </div>
</div> -->

<div class="opt-wrapper">
  <div style="padding-left: 719px;"></div>
  <!-- <div class="f-18 fw-600 text-center heading">
    Please enter the 4 digit OTP we have sent on your mobile and email.
  </div> -->
  <!-- need to remove when email api is completed -->
  <div class="f-16 fw-600 text-center heading">
    Please enter the 6 digit OTP we have sent on your {{showBothOtp?'mobile and email':this.verifyEmail?'mobile':'email'}}.
  </div>
  <!-- need to remove when email api is completed -->
  <div class="row" style="justify-content: center;">
    <div class="mobileOtp mt-32 col-md-6" *ngIf="showBothOtp?true: !(verifyMobile)">
      <p>Mobile OTP</p>
      <div class="text-center" *ngIf="!verifyMobile">
        <ng-otp-input
          #ngMobileOtpInput
          (onInputChange)="onMobileOtpChange($event)"
          [config]="config"></ng-otp-input>
        <!-- <a
          (click)="resendOTP()"
          class="resendLink"
          [ngStyle]="{ visibility: showResendLink ? 'visible' : 'hidden' }"
          >Resend</a
        > -->
      </div>
      <mat-error class="text-center mt-3" [hidden]="!mobileError"> Try Again </mat-error>
      <div class="btn-footer">
        <button (click)="submit('mobile')" mat-flat-button class="btn-properties">{{verifyMobile?'Verified':'Verify'}}</button>
      </div>
    </div>
    <div class="emailOtp mt-32 col-md-6" *ngIf="showBothOtp?true:!(verifyEmail)">
      <p>Email OTP</p>
      <div class="text-center" *ngIf="!verifyEmail">
        <ng-otp-input
          #ngEmailOtpInput
          (onInputChange)="onEmailOtpChange($event)"
          [config]="config"
        ></ng-otp-input>
        <!-- <p class="resendLink" [ngStyle]="{ visibility: showResendLink ? 'visible' : 'hidden' }">Resend</p> -->
      </div>
      <mat-error class="text-center mt-3" [hidden]="!emailError"> Try Again </mat-error>
      <div class="btn-footer">
        <button (click)="submit('email')" mat-flat-button class="btn-properties">{{verifyEmail?'Verified':'Verify'}}</button>
      </div>
    </div>
    
  </div>
   
  
  <!-- <p class="errorText text-center mt-2">Try Again</p> -->
  
</div>
