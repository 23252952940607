import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UniverseService {
  private GET_UNIVERSE_LIST = `store/smartvalues/myquery/execute/get_universe_list`;
  private GET_SECTOR_LIST = `store/smartvalues/myquery/execute/get_sector_list`;
  private CREATE_UNIVERSE = 'store/smartvalues/myquery/execute/create_universe';
  private EDIT_UNIVERSE = 'store/smartvalues/myquery/execute/edit_universe';

  constructor(private http: HttpClient) {}

  getUniverseList(body: any = {}) {
    return this.http.post(environment.baseUrl + this.GET_UNIVERSE_LIST, body);
  }

  getSectorList() {
    return this.http.post(environment.baseUrl + this.GET_SECTOR_LIST, {});
  }

  createUniverse(data: any) {
    return this.http.post(environment.baseUrl + this.CREATE_UNIVERSE, data);
  }

  editUniverse(data: any) {
    return this.http.post(environment.baseUrl + this.EDIT_UNIVERSE, data);
  }
  delete_universe(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/delete_universe',
      payload
    );
  }
}
