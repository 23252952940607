import { I } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs/internal/Observable';

import { Load } from 'src/app/modules/investment-pouches/store/actions/pouch.action';
import { PouchState } from 'src/app/modules/investment-pouches/store/pouch.state';

import { PublicService } from '../../services/public.service';
import { PublicFacadeService } from '../../state/public.facade.services';

@Component({
  selector: 'app-pouchlist',
  templateUrl: './pouchlist.component.html',
  styleUrls: ['./pouchlist.component.scss']
})
export class PouchlistComponent implements OnInit {
  @Input() userInfo: any;
  isUserLoggedin: boolean = false;
  pouches: any;

  isPublic: any;
  showFilter: boolean = false;
  uploadDate: any;
  defaultBody: any;
  searchValue: any = '';
  selectedStrategies: any;
  selectedRiskProfiles: any;
  objData: any;
  annualizedReturns: any;
  publicPouchList: any;
  publicPouchListCopy: any;
  selectedAmountOperator: any;
  selectedCAGROperator: any;
  filterCount: number = 0;
  uploadedDate: any;
  pouchFilterForm: FormGroup;
  getState: Observable<any>;
  selectedSort: any = 'returns';
  risk_profiles: any = [
    {
      name: 'Low',
      value: 'LOW'
    },
    {
      name: 'Medium',
      value: 'MEDIUM'
    },
    {
      name: 'High',
      value: 'HIGH'
    }
  ];

  investment_strategies: any = [
    {
      name: 'Sector',
      value: 'SECTOR'
    },
    {
      name: 'Theme',
      value: 'THEME'
    },
    {
      name: 'Value',
      value: 'VALUE'
    },
    {
      name: 'Technology',
      value: 'TECHNOLOGY'
    }
  ];

  operators: any = [
    {
      name: '<',
      value: '$lt'
    },
    {
      name: '<=',
      value: '$lte'
    },
    {
      name: '=',
      value: '$eq'
    },
    {
      name: '>',
      value: '$gt'
    },
    {
      name: '>=',
      value: '$gte'
    }
  ];

  sortData: any = [
    {
      name: 'Annualized Returns',
      value: -13
    },
    {
      name: 'Min Investments',
      value: 13
    },
    {
      name: 'No of stocks',
      value: -2
    },
    {
      name: 'Risk Profile',
      value: 2
    }
  ];
  showGhost: boolean;
  constructor(
    private router: Router,
    private publicService: PublicService,
    private store: Store<PouchState>,
    private publicFacadeService: PublicFacadeService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isPublic = this.publicService.isPublic;
    this.createPouchFilterForm();

    // this.publicFacadeService.setUploadDateToStore();
    this.publicFacadeService.getUploadDateState$.subscribe((date: any) => {
      localStorage.setItem('stock_price_uploaded_date', date);
      this.uploadDate = localStorage.getItem('stock_price_uploaded_date')?.split('T')[0];
      this.loadPouchDetails();
    });
  }
  loadPouchDetails() {
    if (this.uploadDate) {
      this.publicFacadeService.getAnnualizedfromStore({
        current_date: this.uploadDate
      });
      this.publicFacadeService.LoadAnnualizedfromState$.subscribe((returns: any) => {
        this.annualizedReturns = returns;
      });
    }
  }

  createPouchFilterForm() {
    this.pouchFilterForm = new FormGroup({
      amountOperatorInvestment: new FormControl('', [Validators.required]),
      amountOperatorAnnualized: new FormControl('', [Validators.required]),
      min_investment: new FormControl('', [Validators.required]),
      annualized_returns: new FormControl('', [Validators.required]),
      riskProfiles: new FormControl([], [Validators.required])
    });
  }
  clickHandler(event: any) {
    this.router.navigateByUrl(`public/pouchlist-details/${event.id}`);
  }

  public selectionChange(event: any, type: string) {
    switch (type) {
      case 'invstment_strategy':
        this.selectedStrategies = event.value;
        break;
      case 'risk_profile':
        this.selectedRiskProfiles = event.value;
        break;
    }
  }

  applyFilters() {
    if (this.pouchFilterForm.valid) {
      let body: any = {
        sort: this.selectedSort,
        current_date: this.uploadDate,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id
      };
      if (this.searchValue.length >= 3) {
        body.pouch_name = this.searchValue;
      }

      let minimum_investment: any = {};
      minimum_investment[this.pouchFilterForm.value.amountOperatorInvestment] =
        this.pouchFilterForm.value.min_investment;
      body.minimum_investment = minimum_investment;

      let annualized_returns: any = {};
      annualized_returns[this.pouchFilterForm.value.amountOperatorAnnualized] =
        this.pouchFilterForm.value.annualized_returns;
      body.annualized_returns = annualized_returns;

      if (this.pouchFilterForm.value.riskProfiles.length > 0) {
        body.risk_profile = this.pouchFilterForm.value.riskProfiles;
      }
      this.store.dispatch(new Load(body));
      // this.publicFacadeService.getPouchListfromStore({ ...body });
      this.showFilter = !this.showFilter;
    }
  }

  cancelFilters() {
    this.createPouchFilterForm();
    this.filterCount = 0;
    // this.store.dispatch(new Load(this.defaultBody));
    this.showFilter = !this.showFilter;
  }
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  searchHandler(event: any) {
    if (this.searchValue.length >= 3 || this.searchValue != '') {
      let body: any = {
        sort: 2,
        current_date: this.uploadDate,
        pouch_name: this.searchValue,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id
      };

      if (
        this.pouchFilterForm.value.amountOperatorInvestment &&
        this.pouchFilterForm.value.min_investment
      ) {
        let minimum_investment: any = {};
        minimum_investment[this.pouchFilterForm.value.amountOperatorInvestment] =
          this.pouchFilterForm.value.min_investment;
        body.minimum_investment = minimum_investment;
      }
      if (
        this.pouchFilterForm.value.amountOperatorAnnualized &&
        this.pouchFilterForm.value.annualized_returns
      ) {
        let annualized_returns: any = {};
        annualized_returns[this.pouchFilterForm.value.amountOperatorAnnualized] =
          this.pouchFilterForm.value.annualized_returns;
        body.annualized_returns = annualized_returns;
      }

      if (this.pouchFilterForm.value.riskProfiles.length > 0) {
        body.risk_profile = this.pouchFilterForm.value.riskProfiles;
      }
      // this.publicFacadeService.getPouchListfromStore({ ...body });
      this.store.dispatch(new Load(body));

      // need to remove once sort value is given
      /*   this.publicPouchList = this.publicPouchListCopy.filter((ele: any) =>
        ele.heading_text.includes(this.searchValue)
      ); */

      //
    }
    if (this.searchValue === '') {
      this.store.dispatch(new Load(this.defaultBody));
    }
  }

  sortOnChange(e: any) {}
  isshowFilter() {
    this.showFilter = !this.showFilter;
  }
}
