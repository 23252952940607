import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

const selectPublicState = createFeatureSelector<any>('PUBLIC');

export const getUploadDateFromStore = createSelector(selectPublicState, (state: any) => {
  return state.date;
});

export const getPouchListFromStore = createSelector(selectPublicState, (state: any) => {
  return state.pouchList;
});

export const getAnnualizedFromStore = createSelector(selectPublicState, (state: any) => {
  return state.annualizedReturns;
});
