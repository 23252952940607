<ng-container *ngIf="pouches">
  <div
    #containerRef
    class="table-con pb-20 mt-40"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="100"
    [infiniteScrollContainer]="containerRef"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()">
    <div
      class="d-flex pd15-0 border-top add-shadow pouch-table-row"
      *ngFor="let item of filterPouches(pouches)">
      <div class="pouch-table-data row">
        <div class="col-12 col-sm-12 col-md-4" (click)="goPerformance(item)">
          <div class="icon">
            <img [src]="item.src" />
          </div>
          <div class="pouch-table-card title-content">
            <span class="f-16">{{ item.heading_text }}</span>
            <span class="f-12">{{ item.description }}</span>
            <span class="" style="font-size: 10px; color: #999999" *ngIf="item.subscribed === 'Y'">
              <div class="d-flex align-items-center">
                <img
                  style="width: 20.65px; height: 12px"
                  src="../../../../assets/images/icons/Group.png"
                  alt="" />
                <span style="margin-left: 5px">
                  Subscribed on {{ item.subscription_date | date: 'MMM d, y' }}
                </span>
              </div>
            </span>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-8">
          <div class="detailsStyle">
            <ng-container>
              <div class="pouch-table-card" (click)="goPerformance(item)">
                <span>Total Stocks</span>
                <span class="f-16">{{ item.total_stocks }}</span>
              </div>
              <div *ngIf="!item.is_public" class="pouch-table-card" (click)="goPerformance(item)">
                <div *ngIf="userType === 'admin' || userType === 'ria' || userType === 'partner'">
                  <span>Total Subscribers</span>

                  <div class="d-flex">
                    <span class="f-16 d-flex"
                      >{{ item.total_subscribers }} &nbsp;<span *ngIf="item.inactive_subscriber">
                        ({{ item.inactive_subscriber }})</span
                      ></span
                    >
                  </div>
                </div>
              </div>
              <div class="pouch-table-card" (click)="goPerformance(item)">
                <span>Min Investment</span>
                <span class="f-16">{{ item.min_amt || 0 | number: '1.0-0' }}</span>
              </div>
              <div *ngIf="item.is_public" class="pouch-table-card" (click)="goPerformance(item)">
                <span>Annualised Return</span>
                <span class="f-16"
                  >{{ getAnnualizedValue(item?.benchmark_index) || 0 | number: '1.2-2' }}%</span
                >
              </div>
              <div class="pouch-table-card" (click)="goPerformance(item)">
                <span>Risk Profile</span>
                <span class="f-16">{{ item.risk_profile }}</span>
              </div>
            </ng-container>

            <div
              class="action ml-3"
              *ngIf="userType === 'admin' || userType === 'ria' || userType === 'partner'">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
                class="btn-style">
                <span
                  >Action<img
                    _ngcontent-qsw-c89=""
                    src="assets/images/icons/down-arrow.svg"
                    alt=""
                    srcset=""
                    style="padding-left: 45px"
                /></span>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <span (click)="mapSubscriberpopup(item)">Map Subscribers</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item *ngIf="showEdit(item)">
                  <span (click)="editPouch(item)">Edit Pouch</span>
                </button>
                <mat-divider></mat-divider>
                <!-- <button mat-menu-item>
                  <span>Liquidate Pouch</span>
                </button> -->
                <button mat-menu-item *ngIf="showDelete(item)" (click)="deletePouch(item)">
                  <span>Delete Pouch</span>
                </button>
                <button mat-menu-item (click)="gotoPouchVisibility(item)">
                  <span>Pouch Visibility</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Ghost -->
    <div *ngIf="tabelGhost">
      <div *ngFor="let i of [1, 2, 3, 4, 5]">
        <div class="d-flex pd15-0 border-top add-shadow pouch-table-row">
          <div class="pouch-table-data row">
            <div class="col-12 col-sm-12 col-md-4 d-flex">
              <div class="icon ghost" style="height: 50px; width: 50px"></div>
              <div class="pouch-table-card title-content">
                <span class="f-16 ghost" style="width: 45%; height: 20px"></span>
                <span class="f-12 ghost" style="width: 30%; height: 20px; margin-top: 10px"></span>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-8">
              <div class="detailsStyle">
                <ng-container>
                  <div class="pouch-table-card">
                    <span style="width: 70%; height: 20px" class="ghost"></span>
                    <span
                      class="f-16 ghost"
                      style="width: 30%; height: 20px; margin-top: 10px"></span>
                  </div>
                  <div class="pouch-table-card">
                    <div>
                      <span style="width: 80%; height: 20px" class="ghost"></span>
                      <span
                        class="f-16 ghost"
                        style="width: 30%; height: 20px; margin-top: 10px"></span>
                    </div>
                  </div>
                  <div class="pouch-table-card">
                    <span style="width: 80%; height: 20px" class="ghost"></span>
                    <span
                      class="f-16 ghost"
                      style="width: 50%; height: 20px; margin-top: 10px"></span>
                  </div>
                  <div class="pouch-table-card">
                    <span style="width: 80%; height: 20px" class="ghost"></span>
                    <span
                      class="f-16 ghost"
                      style="width: 50%; height: 20px; margin-top: 10px"></span>
                  </div>
                </ng-container>
                <div class="action ml-3 ghost" style="width: 20%; height: 43px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</ng-container>
<!--  Ghost -->
<ng-container *ngIf="loadingGhost && !(Object.keys(pouches || {}).length > 0)">
  <div #containerRef class="table-con pb-20 mt-40" *ngFor="let i of [1, 2, 3, 4]">
    <div class="d-flex pd15-0 border-top add-shadow pouch-table-row">
      <div class="pouch-table-data row">
        <div class="col-12 col-sm-12 col-md-4 d-flex">
          <div class="icon ghost" style="height: 50px; width: 50px"></div>
          <div class="pouch-table-card title-content">
            <span class="f-16 ghost" style="width: 45%; height: 20px"></span>
            <span class="f-12 ghost" style="width: 30%; height: 20px; margin-top: 10px"></span>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-8">
          <div class="detailsStyle">
            <ng-container>
              <div class="pouch-table-card">
                <span style="width: 70%; height: 20px" class="ghost"></span>
                <span class="f-16 ghost" style="width: 30%; height: 20px; margin-top: 10px"></span>
              </div>
              <div class="pouch-table-card">
                <div>
                  <span style="width: 80%; height: 20px" class="ghost"></span>
                  <span
                    class="f-16 ghost"
                    style="width: 30%; height: 20px; margin-top: 10px"></span>
                </div>
              </div>
              <div class="pouch-table-card">
                <span style="width: 80%; height: 20px" class="ghost"></span>
                <span class="f-16 ghost" style="width: 50%; height: 20px; margin-top: 10px"></span>
              </div>
              <div class="pouch-table-card">
                <span style="width: 80%; height: 20px" class="ghost"></span>
                <span class="f-16 ghost" style="width: 50%; height: 20px; margin-top: 10px"></span>
              </div>
            </ng-container>
            <div class="action ml-3 ghost" style="width: 20%; height: 43px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!--  -->
