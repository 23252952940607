import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as _moment from 'moment';
import { NotificationService } from 'src/app/services/notification.services';
import { WarningKycRpPopupComponent } from 'src/app/warning-kyc-rp-popup/warning-kyc-rp-popup.component';
import { environment } from 'src/environments/environment';

import { CreatePouchPopupComponent } from '../public/components/create-pouch-popup/create-pouch-popup.component';
import { PublicService } from '../public/services/public.service';
import { PublicFacadeService } from './../public/state/public.facade.services';
import { PaymentLoaderComponent } from './payment-loader/payment-loader.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnChanges {
  title = 'angular-material-tab-router';
  navLinks: any[];
  userInfo: any;
  @Input() role: any;
  @Output() closeMenu = new EventEmitter();
  isPublic: any = false;
  activeLinkIndex = -1;
  environment: string;
  constructor(
    private router: Router,
    public fs: PublicFacadeService,
    public publicService: PublicService,
    private notifyService: NotificationService,
    private dialog: MatDialog
  ) {
    this.environment = environment.type;
  }
  ngOnInit(): void {
    let storage: any = localStorage.getItem('user');
    this.userInfo = JSON.parse(storage)?.attributes;
    let date = localStorage.getItem('stock_price_uploaded_date');
    let user = localStorage.getItem('user');
    if (!date && !user) {
      this.fs.setUploadDateToStore();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.role && changes.role.currentValue != changes.role.previousValue) {
      if (this.role === 'subscriber') {
        this.navLinks = [
          {
            label: 'Dashboard',
            link: '/dashboard',
            index: 0
          },
          {
            label: 'Investment Pouches',
            link: '/investment',
            index: 1
          },
          {
            label: 'Portfolio',
            link: '/portfolio',
            index: 2
          }
        ];
      }
      if (this.role === 'admin') {
        this.navLinks = [
          {
            label: 'Dashboard',
            link: '/dashboard',
            index: 0
          },
          {
            label: 'Investments',
            link: '/investment',
            index: 1
          },
          {
            label: 'Portfolio',
            link: '/portfolio',
            index: 2
          },
          {
            label: 'More',
            link: './more',
            index: 3
          }
          // {
          //   label: 'Reports',
          //   link: '/reports',
          //   index: 2,
          // },
          // {
          //   label: 'More',
          //   link: './more',
          //   index: 3,
          // },
        ];
      }
      if (this.role === 'ria' || this.role === 'partner') {
        this.navLinks = [
          {
            label: 'Dashboard',
            link: '/dashboard',
            index: 0
          },
          {
            label: 'Investments',
            link: '/investment',
            index: 1
          },

          {
            label: 'Portfolio',
            link: '/portfolio',
            index: 2
          },
          {
            label: 'More',
            link: './more',
            index: 3
          },
        ];
      }
      this.router.events.subscribe((res) => {
        this.activeLinkIndex = this.navLinks.indexOf(
          this.navLinks.find((tab) => tab.link === '.' + this.router.url)
        );
      });
    }
  }
  clickingOnRoute() {
    this.closeMenu.emit('close menu emitted');
  }
  showNavOption(label: any) {
    return true;
    // if(environment.type ==='PRODUCTION'){
    //   if (this.role === 'subscriber' && label === 'Portfolio') {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }else{
    //   return true
    // }
    //
  }
  openSmartstock() {
    if (this.checkSubscriptionActive()) {
      this.createButtonClick();
    } else {
      const dialog: any = this.dialog.open(WarningKycRpPopupComponent, {
        data: {
          message: 'Error in upload KYC.. Please upload',
          title: 'Error',
          status: 'Fail'
        },
        height: '18rem',
        width: '50rem'
      });
    }
  }

  createButtonClick() {
    const userStorage = this.getUserFromLocalStorage();
    if (!userStorage) return;

    // Parse the date with _moment, providing a default if necessary
    const expiredDate = _moment(userStorage.attributes.createpouchexpiry || '1970-01-01');
    const currentDate = _moment(); // This will give the current date and time

    // Check if expiredDate is before the end of the current day
    const isExpired = currentDate.isAfter(expiredDate.endOf('day'));

    this.userInfo = userStorage.attributes;

    if (this.userInfo.role !== 'subscriber') {
      this.router.navigateByUrl('/investment/create-pouch');
      return;
    }

    this.publicService.postStockRun({}).subscribe((res: any) => {
      const allowRun = res[0]?.Results[0]?.allow_run;
      if (!allowRun) {
        this.showExhaustedPopup();
        return;
      }
      if (this.userInfo.createpouch && !isExpired) {
        // If createpouch is true and it's not expired
        this.router.navigateByUrl('/pouch-creation');
        return;
      } else {
        this.handleSubscriptionRelatedLogic(userStorage.id, expiredDate.format('YYYY-MM-DD'));
      }
    });
  }
  getUserFromLocalStorage() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  showExhaustedPopup() {
    this.completedAgrement = 'exhausted';
    this.dialog.open(CreatePouchPopupComponent, {
      data: {
        agreementSign: this.completedAgrement
      }
    });
  }

  handleSubscriptionRelatedLogic(userId: string, expiredDate: any) {
    this.publicService
      .postViewAgreements({
        subscriber_id: userId,
        product_name: 'create_pouch'
      })
      .subscribe((res: any) => {
        if (Object.keys(res[0]).length > 0) {
          this.handleExistingAgreement(res, expiredDate);
        } else {
          this.promptForSubscription();
        }
      });
  }

  handleExistingAgreement(res: any, expiredDate: any) {
    const agreement = res[0]?.agreements?.[0];
    this.completedAgrement = agreement ? 'Yes' : 'No';
    if (agreement) {
      this.setSessionStorageForPouchCreation(agreement);
      this.decideNavigationBasedOnExpiry(agreement, expiredDate);
    } else {
      this.promptForSubscription();
    }
  }

  setSessionStorageForPouchCreation(agreement: any) {
    const pouchCreation = {
      subscription_amount: agreement.subscription_amount_final,
      subscription_freq: agreement.subscription_freq,
      sv_feature_name: agreement.product_name,
      agreement_id: agreement.agreement_id,
      subscriber_id: agreement.subscriber_id,
      sv_feature_id: agreement.sv_feature_id
    };

    sessionStorage.setItem('pouchCreation', JSON.stringify(pouchCreation));
  }

  decideNavigationBasedOnExpiry(agreement: any, expiredDate: any) {
    let data: any = [];
    this.publicService.getSubscriptionList().subscribe(
      (r: any) => {
        data = r[0].subscriptions;
      },
      (e: any) => {
        this.notifyService.showError('', e.error.error.message);
      },
      () => {
        // const expiredDateTime = new Date(expiredDate).getTime();
        // const currentDateTime = new Date().getTime();
        const currentDate = new Date();
        const currentDateTime = currentDate.getTime();
        const expiredDateObject = new Date(expiredDate + 'T23:59:59+05:30'); // Set to end of the day in your local time zone
        const expiredDateTime = expiredDateObject.getTime();

        if (this.userInfo.createpouch && currentDateTime > expiredDateTime) {
          this.openDialogWithGivenData({
            data: data,
            from: 'button',
            agreementSign: 'createPouchExpired',
            registerDate: agreement.registration_date,
            expiredDates: this.userInfo.createpouchexpiry
          });
        } else {
          this.openDialogWithGivenData({
            data: data,
            from: 'button',
            agreementSign: this.completedAgrement,
            registerDate: agreement.registration_date,
            expiredDates: this.userInfo.createpouchexpiry
          });
        }
      }
    );
  }

  promptForSubscription() {
    let data: any = [];
    this.publicService.getSubscriptionList().subscribe(
      (r: any) => {
        data = r[0].subscriptions;
      },
      (e: any) => {
        this.notifyService.showError('', e.error.error.message);
      },
      () => {
        this.openDialogWithGivenData({
          data: data,
          from: 'button',
          agreementSign: this.completedAgrement,
          registerDate: ''
        });
      }
    );
  }

  openDialogWithGivenData(dialogData: any) {


    const dialog = this.dialog.open(CreatePouchPopupComponent, {
      data: dialogData
    });

    dialog.afterClosed().subscribe((val: string) => {
      switch (val) {
        case 'subscribe':
          this.router.navigateByUrl('/agreement');
          break;
        case 'try':
          this.router.navigateByUrl('/pouch-creation');
          break;
        case 'subscriber':
          this.router.navigateByUrl('/agreement-subscribe');
          break;
        case 'makePayment':
          this.makePayment();
          break;
        case 'renewNow':
          this.makePayment();
          break;
        default:
        // this.router.navigateByUrl('/investment');
      }
    });
  }

  formatDate(date: any) {
    const d = date ? new Date(date) : new Date('1970-01-01');
    d.setHours(23, 59, 59, 59);
    // This will return an ISO string matching your local time.
    let dates: any = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes() - d.getTimezoneOffset()
    )
      .toISOString()
      .split('T')[0];
    return dates;
  }
  completedAgrement: any;
  makePayment() {
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    let data = {
      docs: {
        subscriber_id: d.subscriber_id,
        investment_amount: 0,
        registration_date: new Date().toISOString().split('T')[0],
        product_name: 'create_pouch',
        agreement_id: d.agreement_id,
        subscription_type: '',
        subscription_perc: 0,
        subscription_amount: d.subscription_amount,
        subscription_amount_final: d.subscription_amount,
        subscription_freq: d.subscription_freq,
        promo_code: '',
        discount_amount: 0,
        stoploss: 0,
        sv_feature_id: d.sv_feature_id
      }
    };
    this.publicService
      .intiatePaymentGatewaysvf({
        subscriber_id: d.subscriber_id,
        redirect_url: `${window.location.host}/investment`,
        subscription_freq: d.subscription_freq,
        sv_feature_name: 'create_pouch',
        promo_code: ''
      })
      .subscribe((res: any) => {
        if (res) {
          this.paymentData = res;
          this.redirectToPayment(res);
        }
      });
    // this.subService.postSaveSubscriberInvestment(data).subscribe((res: any) => {
    //   if (res) {
    //     this.publicService
    //       .intiatePaymentGatewaysvf({
    //         subscriber_id: d.subscriber_id,
    //         redirect_url: `${window.location.host}/pouch-creation`,
    //         subscription_freq: d.subscription_freq,
    //         sv_feature_name: 'create_pouch',
    //         promo_code: '',
    //       })
    //       .subscribe((res: any) => {
    //         if (res) {
    //           this.paymentData = res;
    //           this.redirectToPayment(res);
    //         }
    //       });
    //   }
    // });
  }

  paymentUrl: string = '';
  paymentData: any = {
    access_code: '',
    encRequest: '',
    order_id: ''
  };
  redirectToPayment(data: any) {
    this.dialog.open(PaymentLoaderComponent, {
      data: data,
      panelClass: 'custom-modalbox'
    });
  }
  checkSubscriptionActive() {
    let user = JSON.parse(localStorage.getItem('user') || '{}').attributes;
    if (user.kyc_date !== '' && user.risk_profile !== 'PENDING') {
      return true;
    } else {
      return false;
    }
  }
}
