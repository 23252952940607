import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-time-popup',
  templateUrl: './time-popup.component.html',
  styleUrls: ['./time-popup.component.scss']
})
export class TimePopupComponent implements OnInit {
  count: any;
  timer: any;
  constructor(
    public dialogRef: MatDialogRef<TimePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    data.cancelButtonName = data.cancelButtonName ? data.cancelButtonName : 'Cancel';
  }
  ngOnInit() {
    
    this.timer = setTimeout(() => {
      
      localStorage.clear();
      this.router.navigateByUrl('/auth/login');
      this.dialogRef.close({ clickedCancel: false });
    }, this.count * 1000);
  }

  cancelClickHandle(event: any) {
    // this.dialogRef.close();
    clearTimeout(this.timer);
    this.dialogRef.close({ clickedCancel: true });
  }
}
