import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-success-popup',
  templateUrl: './contact-success-popup.component.html',
  styleUrls: ['./contact-success-popup.component.scss']
})
export class ContactSuccessPopupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
