import { Action } from '@ngrx/store';

export enum DashboardActionTypes {
  LOAD = '[Dashboard] Load',
  ADDEDIT = '[Dashboard] AddEdit',
  ADDEDIT_SUCCESS = '[Dashboard] AddEdit Success',
  ADDEDIT_FAILURE = '[Dashboard] AddEdit Failure'
}

export class Load implements Action {
  readonly type = DashboardActionTypes.LOAD;
  constructor(public payload: any) {}
}

export class AddEdit implements Action {
  readonly type = DashboardActionTypes.ADDEDIT;
  constructor(public payload: any) {}
}

export class AddEditSuccess implements Action {
  readonly type = DashboardActionTypes.ADDEDIT_SUCCESS;
  constructor(public payload: any) {}
}

export class AddEditFailure implements Action {
  readonly type = DashboardActionTypes.ADDEDIT_FAILURE;
  constructor(public payload: any) {}
}

export type All = Load | AddEdit | AddEditSuccess | AddEditFailure;
