<ng-container>
  <div class="subscriber-component ui-update">
    <div class="container">
      <div class="container-lg white-bg public-bg-wrapper mt-40 mb-60 pd-40">
        <ng-container>
          <app-banner-pouch-detail
            *ngIf="pouchDetail"
            [tabIndex]="tabIndex"
            [pouchDetail]="pouchDetail">
          </app-banner-pouch-detail>
        </ng-container>
        <div class="tab-container mt-20">
          <div class="d-flex row sm-column-reverse">
            <div class="col-xl-10 col-md-9" style="padding-right: 50px">
              <mat-tab-group [selectedIndex]="tabIndex">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <p>Performance</p>
                  </ng-template>
                  <app-performance
                    [since]="since"
                    [radioSelected]="1"
                    [userType]="'public'"
                    [pouchDetail]="pouchDetail"
                    [pouchId]="pouchDetail.id"
                    [startDate]="pouchDetail.start_date">
                  </app-performance>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    <P>Pouch Composition</P>
                  </ng-template>
                  <ng-template matTabContent>
                    <div>
                      <div class="blur">
                        <app-pouch-composition
                          [userType]="'public'"
                          [pouchDetail]="pouches"
                          [pouchId]="pouchId">
                        </app-pouch-composition>
                      </div>

                      <div class="popup">
                        <div style="margin-top: 40px; margin-bottom: 20px">
                          <img src="../../../../../assets/images/icons/fi-rr-lock.svg" alt="" />
                        </div>
                        <p>
                          <span class="f-blue" (click)="clickOnLogin()" style="cursor: pointer"
                            >Login</span
                          >
                          to subscribe and invest in this pouch
                        </p>
                        <p>
                          Dont have an acount?
                          <span class="f-blue" (click)="clickOnRegister()" style="cursor: pointer"
                            >Register</span
                          >
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="col-xl-2 col-md-3 text-center">
              <ng-container>
                <div style="margin-top: 42px; margin-left: 50px">
                  <app-banner-subscription-details *ngIf="pouchDetail" [pouchDetail]="pouchDetail">
                  </app-banner-subscription-details>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
