import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-play-video-popup',
  templateUrl: './play-video-popup.component.html',
  styleUrls: ['./play-video-popup.component.scss']
})
export class PlayVideoPopupComponent {
  videoLink: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private MatDialog: MatDialogRef<PlayVideoPopupComponent>
  ) {
    this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.videoLink);
  }

  ngOnInit(): void {}
  close() {
    this.MatDialog.close();
  }
}
