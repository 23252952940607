import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PublicService } from '../services/public.service';
import * as PublicActions from '../state/public.action';

@Injectable()
export class PublicEffect {
  constructor(
    private actions$: Actions,
    private publicService: PublicService
  ) {}
  LoadPublicUploadDateFromStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublicActions.setUploadDate),
      switchMap((action) => {
        return this.publicService.fetchUploadDate().pipe(
          switchMap((response: any) => {
            localStorage.setItem(
              'stock_price_uploaded_date',
              response[0].upload_date[0].last_uploaded_on
            );
            return [
              PublicActions.setUploadDateSuccess({
                date: response[0].upload_date[0].last_uploaded_on
              })
            ];
          }),
          catchError((error) =>
            of(PublicActions.setUploadDateFailure({ statusCode: error.status }))
          )
        );
      })
    );
  });

  LoadPublicPouchListFromStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublicActions.loadPouches),
      switchMap((action) => {
        return this.publicService.fetchPublicPouches(action.date).pipe(
          switchMap((response: any) => {
            return [
              PublicActions.loadPouchesSuccess({
                pouchList: response.pouches
              })
            ];
          }),
          catchError((error) => of(PublicActions.loadPouchesFailure({ statusCode: error.status })))
        );
      })
    );
  });

  LoadPublicAnnualizedReturnsFromStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PublicActions.loadAnnualizedReturns),
      switchMap((action) => {
        return this.publicService.fetchIndexAnnualizedReturns(action.date).pipe(
          switchMap((response: any) => {
            return [
              PublicActions.loadAnnualizedReturnsSuccess({
                annualizedReturns: response[0].Results
              })
            ];
          }),
          catchError((error) =>
            of(
              PublicActions.loadAnnualizedReturnsFailure({
                statusCode: error.status
              })
            )
          )
        );
      })
    );
  });
}
