<div class="agreement-wrapper mt-3">
  <div class="container-lg white-bg3 public-bg-wrapper mt-85 mb-60">
    <div class="container">
      <div class="c20 doc-content">
        <p class="c21"><span class="c19">Privacy Policy</span></p>
        <p class="c12">
          <span class="c11"
            >We, Lamron Analysts Pvt Ltd., with our registered office at, 109/40B, Hazra Road,
            Kolkata &ndash; 700 026, India (hereinafter shall be referred to as &quot;</span
          ><span class="c2">Lamron</span><span class="c11">&quot; or &quot;</span
          ><span class="c2">Us</span><span class="c11">&quot; or &quot;</span
          ><span class="c2">We</span><span class="c11">&quot; or &ldquo;</span
          ><span class="c2">Our</span
          ><span class="c0"
            >&rdquo;) are the owner and operator of the Smartvalues Platform
            (smartvalues.co.in).</span
          >
        </p>
        <p class="c12">
          <span class="c0"
            >THIS POLICY DESCRIBES OUR POLICIES AND PROCEDURES FOR COLLECTION, USE, STORAGE,
            PROCESSING, DISCLOSURE AND PROTECTION OF ANY INFORMATION, INCLUDING, BUT NOT LIMITED TO,
            BUSINESS OR PERSONAL INFORMATION PROVIDED BY YOU AS A USER (&ldquo;YOU/R&rdquo;) WHILE
            USING THE PLATFORM.</span
          >
        </p>
        <p class="c12">
          <span class="c0"
            >USER SHALL MEAN ANY PERSON/ PERSONS, WHO VISITS, USES, DEALS WITH AND/ OR TRANSACTS
            THROUGH THE WEBSITE / APP OR AVAILS OUR SERVICES.</span
          >
        </p>
        <p class="c12">
          <span class="c0"
            >BY VISITNG/ ACCESSING THE PLATFORM, THROUGH THE WEBSITE OR THE APP AND VOLUNTARILY
            PROVIDING US WITH INFORMATION (PERSONAL AND / OR NON- PERSONAL), YOU ARE CONSENTING TO
            OUR USE OF IT IN ACCORDANCE WITH THIS POLICY.</span
          >
        </p>
        <p class="c12">
          <span class="c0"
            >THIS POLICY DOES NOT APPLY TO THIRD-PARTY WEBSITES / APPLICATIONS THAT ARE CONNECTED
            VIA LINKS TO THE PLATFORM. IF YOU DO NOT AGREE WITH THIS POLICY, PLEASE DO NOT PROCEED
            FURTHER TO USE / ACCESS THIS PLATFORM.</span
          >
        </p>
        <p class="c12">
          <span class="c0"
            >THIS POLICY IS A PART OF THE PLATFORM&nbsp;AND ALL CAPITALIZED TERMS UNDER THIS POLICY
            THAT HAVE NOT BEEN SPECIFICALLY DEFINED HEREIN SHALL HAVE THE MEANING AS ASCRIBED TO IT
            UNDER THE PLATFORM TERMS &amp; CONDITIONS.</span
          >
        </p>
        <p class="c12">
          <span class="c0"
            >WE MAY UPDATE THIS POLICY FROM TIME TO TIME. WHILE WE WILL ALWAYS ENDEVOUR TO NOTIFY
            YOU, YOU MUST PERIODICALLY REVIEW THE POLICY FOR THE LATEST INFORMATION ON OUR PRIVACY
            PRACTICES.</span
          >
        </p>
        <ol class="c3 lst-kix_list_1-0 start" start="1">
          <li class="c10 li-bullet-0">
            <span class="c1">COLLECTION OF INFORMATION</span>
          </li>
        </ol>
        <p class="c7">
          <span class="c0"
            >You may scroll through our Website and generally view the contents of the Website
            without providing us any personal information. In such a case, We will place a Cookie on
            Your computer that will help us identity You, in case You decide to visit us
            again.&nbsp;During Your use of the Platform, whether the App or the Website, We will
            collect certain NON-PERSONAL AND PERSONAL information from You.<br
          /></span>
        </p>
        <ol class="c3 lst-kix_list_1-1 start" start="1">
          <li class="c14 li-bullet-0">
            <span class="c2">The Non-Personal Information:</span
            ><span class="c0"
              >&nbsp;When You visit our Website / App, we typically collect the IP address of the
              device you use to connect to the internet, information such as what browser, browser
              version, operating system you have, the website you came from or the advertisement you
              viewed or clicked on. We also collect non-personal information on our Website / App
              and from third parties to provide an online experience that matches Your
              requirements.</span
            >
          </li>
          <li class="c14 li-bullet-0">
            <span class="c2">The Personal Information:</span
            ><span class="c0"
              >&nbsp;Is collected by Us after You explicitly provide us consent or is shared by You
              with Us pursuant to a clear disclosure - such as name, address, phone number, and
              email address.</span
            >
          </li>
        </ol>
        <p class="c18">
          <span class="c0"><br />We will collect your personal information when you:</span>
        </p>
        <p class="c16"><span class="c0"></span></p>
        <ol class="c3 lst-kix_list_1-2 start" start="1">
          <li class="c4 li-bullet-0">
            <span class="c0">Register on our Website or App; and/or</span>
          </li>
        </ol>
        <p class="c16"><span class="c0"></span></p>
        <ol class="c3 lst-kix_list_1-2" start="2">
          <li class="c4 li-bullet-0">
            <span class="c0">Subscribing to one of our services through our Website / App.</span>
          </li>
        </ol>
        <p class="c6">
          <span class="c0"><br /></span>
        </p>
        <p class="c8">
          <span class="c15">Information That You Provide To Us:</span>
        </p>
        <ol class="c3 lst-kix_list_4-1 start" start="1">
          <li class="c5 li-bullet-0">
            <span class="c0">Your name, address and contact information</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >Your PAN and Aadhar Number as part of &ldquo;Know Your Customer&rdquo; (KYC) process
              as required by SEBI, at your sole discretion</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >Few investment related questions that you may answer for us to ascertain your Risk
              Profile and recommend our services accordingly.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >Information of trades executed by you based on our recommendation either using our
              Platform or executed outside our Platform and manually updating the trade information
              by you on our Platform.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >Other information that You provide when You write directly to Us (including by
              e-mail) or provide Us over telephone or while filling out any applications on the
              Platform.</span
            >
          </li>
        </ol>
        <p class="c6">
          <span class="c0"><br /></span>
        </p>
        <p class="c8">
          <span class="c15">Information We Collect From Your Device Or A Third Party:</span>
        </p>
        <p class="c7">
          <span class="c11"
            >We collect information from the device when you download and install the App
            and&nbsp;</span
          ><span class="c2"
            >explicitly seek permissions from YOU to get the required information from the
            device</span
          ><span class="c0"
            >. The information we collect from your device includes the unique ID i.e. IMEI number,
            information on operating system, SDK version, camera, storage, and location. We collect
            information about your device to provide automatic updates and additional security so
            that your account is not used in other people&rsquo;s devices. In addition, the
            information provides us valuable feedback on your identity as a device holder as well as
            your device behaviour, thereby allowing us to improve our services and provide an
            enhanced customized user experience to you.</span
          >
        </p>
        <p class="c7">
          <span class="c0"
            ><br />The key information collected from the device is further detailed below</span
          >
        </p>
        <ol class="c3 lst-kix_list_5-1 start" start="1">
          <li class="c5 li-bullet-0">
            <span class="c2">MOBILE NUMBER AND EMAIL ADDRESS</span>
          </li>
        </ol>
        <p class="c13">
          <span class="c0"
            >When you register with us, we collect your mobile number and email address and validate
            the some via OTP to uniquely authenticate and identify you. This helps us to ensure that
            no unauthorised device or person is acting on your behalf.</span
          >
        </p>
        <ol class="c3 lst-kix_list_5-1" start="2">
          <li class="c5 li-bullet-0">
            <span class="c2">DEVICE INFORMATION</span>
          </li>
        </ol>
        <p class="c13">
          <span class="c0"
            >Our app collects and monitors information about your device including your hardware
            model, operating system information, unique device identifiers like IMEI or serial
            numbers, to offer you customized services and also to uniquely identify the devices to
            ensure that unauthorized devices are not able to act on your behalf and to mitigate the
            fraud.</span
          >
        </p>
        <ol class="c3 lst-kix_list_5-1" start="3">
          <li class="c5 li-bullet-0">
            <span class="c2">LOCATION</span
            ><span class="c0"
              ><br />Our App collects and monitors the information about the location of your device
              to provide timely notifications about availability of services in your location.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c2">CAMERA</span
            ><span class="c0"
              ><br />Our App requires camera access to easily scan and capture your profile
              photograph, KYC and income related documents and provide better user experience by
              auto-filling the relevant document data.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c2">STORAGE</span
            ><span class="c0"
              ><br />Our App requires storage permission so that your KYC related documents can be
              securely downloaded and saved on to your phone. You can than easily upload the right
              KYC related documents to avail our services and thereby enhancing the user
              experience.</span
            >
          </li>
        </ol>
        <p class="c6">
          <span class="c0"><br /></span>
        </p>
        <p class="c8"><span class="c15">Other Information:</span></p>
        <ol class="c3 lst-kix_list_6-1 start" start="1">
          <li class="c5 li-bullet-0">
            <span class="c0"
              >Any other information that Lamron is required to collect as per specific mandate from
              any regulator or as a requirement under applicable law;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >Information from You electronically - like the pages viewed, how You navigate through
              the Website / Platform and interact with webpages, etc.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c2">Cookies:</span
            ><span class="c0"
              >&nbsp;Cookies are small data files that a Website / App stores on Your computer. We
              will use cookies on our Website / App similar to other leading websites / apps. Use of
              this information helps Us identify You in order to make our Platform more user
              friendly. We might also use this information to display advertising from third party
              companies. Most browsers will permit You to decline cookies but if You choose to do
              this it might affect service on some parts of Our Website.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >We might also use other technology to track how You interact with Platform and employ
              some third-party agencies to use the data on Our behalf.</span
            >
          </li>
        </ol>
        <p class="c7">
          <span class="c0"
            ><br />The information We collect about You will depend on the products and services We
            offer or You avail, on an ongoing basis. The information collected from You will be used
            in order to effectively provide Services to You. If You do not allow Us to collect all
            the information We request on the Platform, We may not be able to deliver all of these
            services effectively.</span
          >
        </p>
        <p class="c6">
          <span class="c0"><br /></span>
        </p>
        <ol class="c3 lst-kix_list_1-0" start="2">
          <li class="c10 li-bullet-0">
            <span class="c1">PURPOSE, USE AND DISCLOSURE OF INFORMATION</span>
          </li>
        </ol>
        <p class="c7">
          <span class="c0"
            >We will use and retain Your information for such periods as necessary to provide You
            the services on our Platform, to comply with our legal obligations, to resolve disputes,
            and enforce our agreements with you.</span
          >
        </p>
        <p class="c7">
          <span class="c0">&nbsp;<br /></span>
        </p>
        <p class="c8"><span class="c15">Use Of Information:</span></p>
        <p class="c7">
          <span class="c0"
            >We use data collected to support our Product. Lamron, as a platform assists You to
            select the appropriate investment product, and technically help you accomplish
            transaction and trade with your preferred broker.<br />We may use the information
            provided by You in the following ways:</span
          >
        </p>
        <p class="c6"><span class="c0"></span></p>
        <ol class="c3 lst-kix_list_7-1 start" start="1">
          <li class="c5 li-bullet-0">
            <span class="c0">to establish identity and verify the same;</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0">monitor, improve and administer our Platform;</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0">design and offer customized products and services;</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >analyze how the Platform is used, diagnose service or technical problems and maintain
              security;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >send communications notifications, information regarding the products or services
              requested by You or process queries and applications that You have made on the
              Platform;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >manage Our relationship with You and inform You about other products or services We
              think You might find of some use;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >conduct data analysis in order to improve the Services provided to the User;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >use the User information in order to comply with country laws and regulations;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >to conduct KYC based on the information shared by the User for the provision of
              Services;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >use the User information in other ways permitted by law to enable You to take
              financial services from our lending partners.</span
            >
          </li>
        </ol>
        <p class="c6">
          <span class="c0"><br /></span>
        </p>
        <p class="c8"><span class="c15">Disclosure Of Information:</span></p>
        <p class="c7">
          <span class="c0"
            >We may share Your information with third parties including your preferred brokers for
            facilitation of investment transaction to a User. We may also share Your information
            with third parties under a confidentiality agreement for provision of services which
            inter alia restricts such third parties from further disclosing the information unless
            such disclosure is for the purpose as detailed under that confidentiality
            agreement.</span
          >
        </p>
        <p class="c7">
          <span class="c0"
            ><br />We will share Your information with third parties only in such manner as
            described below:</span
          >
        </p>
        <p class="c6"><span class="c0"></span></p>
        <ol class="c3 lst-kix_list_8-1 start" start="1">
          <li class="c5 li-bullet-0">
            <span class="c0"
              >We may share Your information with our third-party partners in order to conduct data
              analysis in order to serve You better and provide Services on our Platform;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >We may disclose Your information, without prior notice, if We are under a duty to do
              so in order to comply with any legal obligation or an order from the government and/or
              a statutory authority, or in order to enforce or apply Our terms of use or assign such
              information in the course of corporate divestitures, mergers, or to protect the
              rights, property, or safety of Us, Our users, or others. This includes exchanging
              information with other companies and organizations for fulfilling our services to
              you.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >We may also share the data / information with our technology service providers to
              perform know your customer checks as per SEBI guidelines;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c0"
              >We and our affiliates may share Your information with another business entity should
              we (or our assets) merge with, or be acquired by that business entity, or
              re-organization, amalgamation, restructuring of business for continuity of
              business.</span
            >
          </li>
        </ol>
        <p class="c6">
          <span class="c0"><br /><br /></span>
        </p>
        <ol class="c3 lst-kix_list_1-0" start="3">
          <li class="c10 li-bullet-0">
            <span class="c1">STORAGE OF INFORMATION</span>
          </li>
        </ol>
        <p class="c7">
          <span class="c0"
            >You further understand and agree that subscribing to an investment product does not
            automatically rescind the consents you have provided to us under the Privacy Policy nor
            does deletion of your account with the Platform. Any information provided to us is
            retained by Us until You have an account / active relationship with Us.&nbsp;We store
            and process Your personal information on secure AWS cloud servers and other cloud
            service providers within India. Some of the safeguards We use are firewalls and
            encryption using SSL, and information access authorization controls. We use reasonable
            safeguards to preserve the integrity and security of Your information against loss,
            theft, unauthorized access, disclosure, reproduction, use or amendment. To achieve the
            same, We use reasonable security practices and procedures as mandated under applicable
            laws for the protection of Your information. Information You provide to Us may be stored
            on Our secure servers located within India.&nbsp;However, You understand and accept that
            there&rsquo;s no guarantee that data transmission over the Internet will be completely
            secure and that any information that You transmit to Us is at Your own risk. We assume
            no liability for any disclosure of information due to errors in transmission,
            unauthorized third party access to our Platform and data bases or other acts of third
            parties, or acts or omissions beyond Our reasonable control and You shall not be
            entitled to hold Lamron responsible for any breach of security.</span
          >
        </p>
        <p class="c6">
          <span class="c0"><br /><br /></span>
        </p>
        <ol class="c3 lst-kix_list_1-0" start="4">
          <li class="c10 li-bullet-0">
            <span class="c1">THIRD PARTY WEBSITES</span>
          </li>
        </ol>
        <p class="c7">
          <span class="c0"
            >With this Policy We&rsquo;re only addressing the disclosure and use of data collected
            by Us. If You visit any websites through the links on the Lamron Platform, please ensure
            You go through the privacy policies of each of those websites. Their data collection
            practices and their policies might be different from this Policy and Lamron does not
            have control over any of their policies neither does it have any liability in this
            regard.</span
          >
        </p>
        <p class="c6">
          <span class="c0"><br /><br /></span>
        </p>
        <ol class="c3 lst-kix_list_1-0" start="5">
          <li class="c10 li-bullet-0"><span class="c1">PHISHING</span></li>
        </ol>
        <p class="c7">
          <span class="c11">&quot;</span><span class="c2">Phishing</span
          ><span class="c0"
            >&quot; usually occurs when users of a website are induced by an individual/entity into
            divulging sensitive personal data by using fraudulent websites and/ or e-mail addresses.
            In the event You provide information to a website or respond to an e-mail which does not
            belong to Us or is not connected with Us in any manner, You will be a victim to
            Phishing. We do not send e-mails requesting a user for payment information, user name or
            passwords. However, We may verify the user name, password etc. provided by You from time
            to time.</span
          >
        </p>
        <p class="c6">
          <span class="c0"><br /><br /></span>
        </p>
        <ol class="c3 lst-kix_list_1-0" start="6">
          <li class="c10 li-bullet-0">
            <span class="c1">SEVERABILITY AND EXCLUSION</span>
          </li>
        </ol>
        <p class="c7">
          <span class="c0"
            >We have taken every effort to ensure that this Policy adheres with the applicable laws.
            The invalidity or unenforceability of any part of this Policy shall not prejudice or
            affect the validity or enforceability of the remainder of this Policy. This Policy does
            not apply to any information other than the information collected by Lamron through the
            Platform. This Policy shall be inapplicable to any unsolicited information You provide
            Us through the Platform or through any other means. This includes, but is not limited
            to, information posted in any public areas of the Website / App. All unsolicited
            information shall be deemed to be non-confidential and the Company shall be free to use
            and/ or disclose such unsolicited information without any limitations.</span
          >
        </p>
        <p class="c6">
          <span class="c0"><br /><br /></span>
        </p>
        <ol class="c3 lst-kix_list_1-0" start="7">
          <li class="c10 li-bullet-0"><span class="c1">NO WAIVER</span></li>
        </ol>
        <p class="c7">
          <span class="c0"
            >The rights and remedies available under this Policy may be exercised as often as
            necessary and are cumulative and not exclusive of rights or remedies provided by law. It
            may be waived only in writing. Delay in exercising or non-exercise of any such right or
            remedy does not constitute a waiver of that right or remedy, or any other right or
            remedy.</span
          >
        </p>
        <p class="c6">
          <span class="c0"><br /><br /></span>
        </p>
        <ol class="c3 lst-kix_list_1-0" start="8">
          <li class="c10 li-bullet-0">
            <span class="c1">GREVIANCE OFFICER</span>
          </li>
        </ol>
        <p class="c7">
          <span class="c0"
            >If You require any information or clarification regarding the use of Your personal
            information or this Privacy Policy, You can contact us at
            compliance&#64;lamronanalysts.com<br /><br />Lamron is a company based in India. By
            browsing/using the Platform, You hereby acknowledge that Lamron is not responsible or
            liable in any manner to comply with any local laws of Your territory except India.</span
          >
        </p>
        <p class="c17"><span class="c9"></span></p>
        <p class="c12">
          <span class="c0">LAST UPDATED ON 15th February 2023</span>
        </p>
        <p class="c17"><span class="c9"></span></p>
      </div>
    </div>
  </div>
</div>
