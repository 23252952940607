<div class="container px-4">
  <div class="p-4">
    <div class="text-center">
      <img src="../../../../../../assets/images/icons/avatar.svg" alt="" />
    </div>
    <div class="text-center text pt-4">
      Generating timeframe. It will take sometime. <br />
      How about grabing a cup of coffee.
    </div>
  </div>
</div>
