import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent {
  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.paymentLoader=this.data
   }
  paymentLoader=false
}
