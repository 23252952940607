<div class="container-fluid">
  <div class="px-2">
    <mat-list style="font-size: 18px; padding-bottom: 9px; margin: 0%">
      {{ data.universe | uppercase }}
      <span style="float: right; cursor: pointer" (click)="closeDialog()">X</span>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-list>
      <!-- The NIFTY 50 is a well diversified 50 stock index and it 
            <br>
            represent important sectors of the economy
        <br>
        <br> -->
      Stocks : {{ data.universeStocks[0].stocks.length }}</mat-list
    >
    <mat-divider></mat-divider>
    <mat-list *ngFor="let stock of data.universeStocks[0].stocks">{{ stock.stock_name }}</mat-list>
    <mat-divider></mat-divider>
  </div>
</div>
