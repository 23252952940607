<div class="black-bg white footer">
  <div class="d-flex f-container">
    <div class="f-14 fw-400 copyright">
      <p>copyright © 2020-21 SmartValues</p>
      <div>
        <!-- <ul style="font-size: 13px!important;">
        <li><a href="#">About us</a></li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a (click)="openContactDialog()">Contact Us</a>
        </li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a (click)="goTo('policy')">Privacy Policy</a>
        </li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a (click)="goTo('terms')">Terms & Conditions</a>
        </li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a target="_blank" href="https://lamronanalysts.com/investor-charter/">Investor Charter </a>
        </li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a target="_blank" href=" https://lamronanalysts.com/investor-complaints/">Investor Complaints </a>
        </li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a target="_blank" href="https://www.sebi.gov.in/legal/master-circulars/aug-2023/online-resolution-of-disputes-in-the-indian-securities-market_75220.html"> SEBI Circular </a>
        </li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a target="_blank" href=" https://smartodr.in/login">SMARTODR Portal</a>
        </li>
        <li style="cursor: pointer;margin-top: 10px;" class="pl-15">
          <a target="_blank" href=" https://scores.gov.in/scores/Welcome.html"> SEBI Scores </a>
        </li>

        
      </ul>      -->
      </div>
    </div>
    <!-- <div class=" social-icons">
    <ul>
      <li><img src="../../../../../assets/images/icons/facebook.png" /></li>
      <li><img src="../../../../../assets/images/icons/twitter.png" /></li>
      <li><img src="../../../../../assets/images/icons/linkedin.png" /></li>
      <li><img src="../../../../../assets/images/icons/youtube.png" /></li>
    </ul>
  </div>  -->
  </div>
  <div class="row footer-link-padding">
    <div class="col-xl-9 col-lg-12 footerLink">
      <ul style="font-size: 13px !important" class="text-center">
        <li><a href="#">About us</a></li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a (click)="openContactDialog()">Contact Us</a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a (click)="goTo('policy')">Privacy Policy</a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a (click)="goTo('terms')">Terms & Conditions</a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a target="_blank" href="https://lamronanalysts.com/investor-charter/"
            >Investor Charter
          </a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a target="_blank" href=" https://lamronanalysts.com/investor-complaints/"
            >Investor Complaints
          </a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a
            target="_blank"
            href="https://www.sebi.gov.in/legal/master-circulars/aug-2023/online-resolution-of-disputes-in-the-indian-securities-market_75220.html">
            SEBI Circular
          </a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a target="_blank" href=" https://smartodr.in/login">SMARTODR Portal</a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a target="_blank" href=" https://scores.gov.in/scores/Welcome.html"> SEBI Scores </a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a target="_blank" href="https://lamronanalysts.com/ia-audit-status/">
            IA Audit Status
          </a>
        </li>
        <li style="cursor: pointer; margin-top: 10px" class="pl-15">
          <a target="_blank" href="https://lamronanalysts.com/ckyc-registry-awareness">
            CKYC Registry Awareness</a
          >
        </li>
      </ul>
    </div>
    <div class="col-xl-3 col-lg-12 Footer-social-icons text-center">
      <ul>
        <li><img src="../../../../../assets/images/icons/facebook.png" /></li>
        <li><img src="../../../../../assets/images/icons/twitter.png" /></li>
        <li><img src="../../../../../assets/images/icons/linkedin.png" /></li>
        <li><img src="../../../../../assets/images/icons/youtube.png" /></li>
      </ul>
    </div>
  </div>
  <div class="versionStyle">V {{ currentApplicationVersion }}</div>
</div>
