import { createFeatureSelector } from '@ngrx/store';

import * as universe from './reducers/universe.reducer';

export interface UniverseState {
  entityState: universe.State;
}

export const reducers = {
  universe: universe.reducer
};

export const selectUniverseState = createFeatureSelector<UniverseState>('universe');
