import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { StockPopupComponent } from '../more/components/stock-popup/stock-popup.component';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss']
})
export class MoreComponent implements OnInit {
  role:string=''
  constructor(private router: Router,private authService:AuthService) {

  }

  ngOnInit(): void {}
  ngDoCheck() {
    try {
     this.role= this.authService.getParsedToken().identity.attributes.role
     } catch (e) {
     this.role=''
    }
  }
  goStock() {
    this.router.navigateByUrl('more/stock');
  }
  universe() {
    this.router.navigateByUrl('more/universe');
  }
  subscriber() {
    this.router.navigateByUrl('more/subscriber');
  }
  holiday() {
    this.router.navigateByUrl('more/holiday');
  }
  promocode() {
    this.router.navigateByUrl('more/promocode');
  }
  referral() {
    this.router.navigateByUrl('more/referral');
  }
  adminUser() {
    this.router.navigateByUrl('more/admin-user');
  }
  refUserList() {
    this.router.navigateByUrl('more/referrer-user-list');
  }
  pouchpayments() {
    this.router.navigateByUrl('more/pouch-payments');
  }
  bonusSplit() {
    this.router.navigateByUrl('more/bonus-split');
  }
}
